﻿
/*
 * Style Overrides for datatables pagination element
*/


// default style overrides
// default styles are commented out

.dataTables_wrapper .dataTables_paginate {
  //float: right;
  //text-align: right;
  //padding-top: 0.25em;
}

@media print {
    .dataTables_wrapper .dataTables_paginate,
    .dataTables_wrapper .dataTables_length {
        display: none !important;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  //box-sizing: border-box;
  //display: inline-block;
  //min-width: 1.5em;
  height: 40px;
  width: 40px;
  //padding: 0.5em 1em;
  padding: 0;
  //margin-left: 2px;
  //text-align: center;
  //text-decoration: none !important;
  //cursor: pointer;
  //*cursor: hand;
  //color: #333 !important;
  color: #08a7e7 !important;
  //border: 1px solid transparent;
  //border-radius: 2px;
  border-radius: 0;
  font-size: .882353em;
  line-height: 40px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, 
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  //color: #333 !important;
  color: #fff !important;
  //border: 1px solid #979797;
  border: 1px solid transparent;
  //background-color: white;
  //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  /* Chrome,Safari4+ */
  //background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Chrome10+,Safari5.1+ */
  //background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* FF3.6+ */
  //background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* IE10+ */
  //background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  //background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  /* W3C */
  background: none;
  background-color: #006694;
  cursor: default;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  //cursor: default;
  //color: #666 !important;
  //border: 1px solid transparent;
  //background: transparent;
  //box-shadow: none;
  display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  //color: white !important;
  color: #078ac0 !important;
  //border: 1px solid #111;
  border: 1px solid transparent;
  //background-color: #585858;
  //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  //background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  //background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  //background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  //background: -o-linear-gradient(top, #585858 0%, #111 100%);
  //background: linear-gradient(to bottom, #585858 0%, #111 100%);
  background: none;
  background-color: transparent;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  //outline: none;
  //background: none;
  //background-color: transparent;
  color: #f2f2f2 !important;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
  //padding: 0 1em;
}





/* custom selectors */

.dataTables_wrapper .dataTables_paginate .paginate_button.next,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    border-width: 1px;
    border-radius: 0;
    border-color: #ccc;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
    border-width: 1px;
    border-color:#08a7e7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button svg {
    fill: currentColor;
    margin-top: -3px;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    vertical-align: middle;
}