﻿.CardBox {
  //border: 1px solid #ccc;
  border: none;
  //padding: 20px;
  padding: 0;
  position: relative;
}

.js-equalHeights .CardBox {
  height: 100%;
}

.CardBox:hover .CardBox-heading-link,
.CardBox:focus .CardBox-heading-link,
.CardBox:active .CardBox-heading-link {
  color: #08a7e7;
}

.CardBox-list:before,
.CardBox-list:after {
  content: " ";
  display: table;
}

.CardBox-list:after {
  clear: both;
}

@media screen and (min-width: 43.75em) {
  .CardBox-list--grid {
    margin-left: -20px;
  }

  .CardBox-list--grid .CardBox-item {
    float: left;
    padding-left: 20px;
    width: 50%;
  }

  .CardBox-list--grid .CardBox-item:nth-child(odd) {
    clear: left;
  }
}

@media screen and (min-width: 51.875em) and (max-width: 65.5625em) {
  .CardBox-list--grid {
    margin-left: 0;
  }

  .CardBox-list--grid .CardBox-item {
    float: none;
    padding-left: 0;
    width: 100%;
  }
}

.CardBox-list.is-expanded .CardBox-item {
  margin-bottom: 20px;
  max-height: none;
  opacity: 1;
  visibility: visible;
  -webkit-transition: margin 0s linear 0s, max-height 0s linear 0s, visibility 0s linear 0s, opacity 500ms;
  transition: margin 0s linear 0s, max-height 0s linear 0s, visibility 0s linear 0s, opacity 500ms;
}

.CardBox-list--truncated .CardBox-item {
  margin-bottom: 0;
  max-height: 0;
  opacity: 0;
  -webkit-transition: none;
  transition: none;
  visibility: hidden;
}

.no-js .CardBox-list--truncated .CardBox-item,
.CardBox-list--truncated .CardBox-item.is-visible {
  margin-bottom: 20px;
  max-height: none;
  opacity: 1;
  visibility: visible;
  -webkit-transition: margin 0s linear 0s, max-height 0s linear 0s, visibility 0s linear 0s, opacity 500ms;
  transition: margin 0s linear 0s, max-height 0s linear 0s, visibility 0s linear 0s, opacity 500ms;
}

.CardBox-item {
  margin-bottom: 20px;
  overflow: hidden;
}

.CardBox-label {
  font-size: 0.823529rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 1em;
}

.CardBox-heading {
  font-size: 1.058824rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.3em;
  margin-top: 0;
}

.CardBox-heading:last-child {
  margin-bottom: 0;
}

.CardBox-heading-link {
  font-size: 0.941176em;
  line-height: 1.375;
}

.CardBox-heading-link,
.CardBox-heading-link:link {
  color: #454545;
}

.CardBox-heading-link:visited {
  color: #454545;
}

.CardBox-heading-link:hover,
.CardBox-heading-link:focus,
.CardBox-heading-link:active {
  color: #12b5f7;
}

.CardBox-contact {
  font-size: 0.882353em;
}

.CardBox-contact b {
  font-weight: 600;
}

.CardBox-address {
  margin-bottom: 1em;
}

.CardBox-subheading {
  font-size: 0.933333em;
  font-weight: 700;
  text-transform: uppercase;
}