﻿
/**
    Extend CFF breadcrumb styling to bootstrap breadcrumb format
    We need to use bootstrap format because we're using MvcBreadCrumbs at https://github.com/thelarz/MvcBreadCrumbs
    If this tool is removed and the dom format for breadcrumbs matches cff.org, this code can be removed
 */

.Breadcrumbs {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #777;
    display: none;
    font-size: .764706em;
    margin-bottom: 15px;
}
.Breadcrumbs:after,
.Breadcrumbs:before {
    content: " ";
    display: table;
}
.Breadcrumbs:after {
    clear: both;
}
@media screen and (min-width: 30em) {
    .Breadcrumbs {
        display: block;
    }
}

.Breadcrumbs+.TitleBlock {
    margin-top: 10px;
}
@media screen and (min-width: 30em) {
    .Breadcrumbs+.TitleBlock {
        margin-top: 0;
    }
}

.Breadcrumbs-item,
.Breadcrumbs li {
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 5px;
}
/*.breadcrumb li:last-child { // hide the last breadcrumb that displays the current page.
    display: none;
}*/
.Breadcrumbs-item:after,
.Breadcrumbs li:after {
    color: rgba(102, 102, 102, .6);
    content: '\002F';
    font-size: .923077em;
    padding-left: .333333em;
}
.Breadcrumbs li.active:after{
    content: '';
    padding-left: 0;
}

.Breadcrumbs-link,
.Breadcrumbs-link:link,
.Breadcrumbs-link:visited,
.Breadcrumbs li a,
.Breadcrumbs li a:link,
.Breadcrumbs li a:visited {
    color: #777;
}

.FinderBillboard {
    .Breadcrumbs-item:after,
    .Breadcrumbs li:after,
    .Breadcrumbs li a,
    .Breadcrumbs li a:link,
    .Breadcrumbs li a:visited {
        color: #FFF;
    }
}

.Breadcrumbs-link:active,
.Breadcrumbs-link:focus,
.Breadcrumbs-link:hover,
.Breadcrumbs li a:active,
.Breadcrumbs li a:focus,
.Breadcrumbs li a:hover {
    color: #12b5f7;
}

.FinderBillboard .Breadcrumbs a,
.FinderBillboard .Breadcrumbs a:active,
.FinderBillboard .Breadcrumbs a:focus,
.FinderBillboard .Breadcrumbs a:hover,
.FinderBillboard .Breadcrumbs a:link,
.FinderBillboard .Breadcrumbs a:visited {
    color: #fff
}