﻿@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/open-sans/open-sans-v10-latin-regular.eot);
    src: url(../fonts/open-sans/open-sans-v10-latin-regular.eot?#iefix) format("embedded-opentype"),url(../fonts/open-sans/open-sans-v10-latin-regular.woff2) format("woff2"),url(../fonts/open-sans/open-sans-v10-latin-regular.woff) format("woff"),url(../fonts/open-sans/open-sans-v10-latin-regular.ttf) format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url(../fonts/open-sans/open-sans-v10-latin-italic.eot);
    src: url(../fonts/open-sans/open-sans-v10-latin-italic.eot?#iefix) format("embedded-opentype"),url(../fonts/open-sans/open-sans-v10-latin-italic.woff2) format("woff2"),url(../fonts/open-sans/open-sans-v10-latin-italic.woff) format("woff"),url(../fonts/open-sans/open-sans-v10-latin-italic.ttf) format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/open-sans/open-sans-v10-latin-600.eot);
    src: url(../fonts/open-sans/open-sans-v10-latin-600.eot?#iefix) format("embedded-opentype"),url(../fonts/open-sans/open-sans-v10-latin-600.woff2) format("woff2"),url(../fonts/open-sans/open-sans-v10-latin-600.woff) format("woff"),url(../fonts/open-sans/open-sans-v10-latin-600.ttf) format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url(../fonts/open-sans/open-sans-v10-latin-600italic.eot);
    src: url(../fonts/open-sans/open-sans-v10-latin-600italic.eot?#iefix) format("embedded-opentype"),url(../fonts/open-sans/open-sans-v10-latin-600italic.woff2) format("woff2"),url(../fonts/open-sans/open-sans-v10-latin-600italic.woff) format("woff"),url(../fonts/open-sans/open-sans-v10-latin-600italic.ttf) format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/open-sans/open-sans-v10-latin-700.eot);
    src: url(../fonts/open-sans/open-sans-v10-latin-700.eot?#iefix) format("embedded-opentype"),url(../fonts/open-sans/open-sans-v10-latin-700.woff2) format("woff2"),url(../fonts/open-sans/open-sans-v10-latin-700.woff) format("woff"),url(../fonts/open-sans/open-sans-v10-latin-700.ttf) format("truetype");
}

@font-face {
    font-family: 'Rock Salt';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/rock-salt/rock-salt-v6-latin-regular.eot);
    src: url(../fonts/rock-salt/rock-salt-v6-latin-regular.eot?#iefix) format("embedded-opentype"),url(../fonts/rock-salt/rock-salt-v6-latin-regular.woff2) format("woff2"),url(../fonts/rock-salt/rock-salt-v6-latin-regular.woff) format("woff"),url(../fonts/rock-salt/rock-salt-v6-latin-regular.ttf) format("truetype");
}

@font-face {
    font-family: "Avenir Next W01";
    src: url(../fonts/avenir/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix);
    src: url(../fonts/avenir/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix) format("eot"),url(../fonts/avenir/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2) format("woff2"),url(../fonts/avenir/1e9892c0-6927-4412-9874-1b82801ba47a.woff) format("woff"),url(../fonts/avenir/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next W01";
    src: url(../fonts/avenir/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix);
    src: url(../fonts/avenir/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix) format("eot"),url(../fonts/avenir/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2) format("woff2"),url(../fonts/avenir/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff) format("woff"),url(../fonts/avenir/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next W01";
    src: url(../fonts/avenir/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix);
    src: url(../fonts/avenir/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix) format("eot"),url(../fonts/avenir/14c73713-e4df-4dba-933b-057feeac8dd1.woff2) format("woff2"),url(../fonts/avenir/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff) format("woff"),url(../fonts/avenir/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}

    audio:not([controls]) {
        display: none;
        height: 0;
    }

[hidden], template {
    display: none;
}

a {
    background-color: transparent;
}

    a:active, a:hover {
        outline: 0;
    }

abbr[title] {
    border-bottom: 1px dotted;
}

b, strong {
    font-weight: 700;
}

dfn {
    font-style: italic;
}

/*h1 {
    font-size: 2em;
    margin: .67em 0;
}*/

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

hr {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code, kbd, pre, samp {
    font-family: monospace,monospace;
    font-size: 1em;
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button, select {
    text-transform: none;
}

button, html input[type=button], input[type=submit], input[type=reset] {
    -webkit-appearance: button;
    cursor: pointer;
}

    button[disabled], html input[disabled] {
        cursor: default;
    }

    button::-moz-focus-inner, input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

input {
    line-height: normal;
}

    input[type=checkbox], input[type=radio] {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
    }

    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
        height: auto;
    }

    input[type=search] {
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-appearance: none;
    }

        input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: 700;
}

::-moz-placeholder {
    opacity: 1;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td, th {
    padding: 0;
}

.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

    .slick-list:focus {
        outline: 0;
    }

    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
}

    .slick-track:after, .slick-track:before {
        content: "";
        display: table;
    }

    .slick-track:after {
        clear: both;
    }

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.u-absoluteFill {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.u-hideText {
    font-size: 0;
    overflow: hidden;
    text-indent: 110%;
    white-space: nowrap;
}

@media print {
    .SkipLinks, .u-printHide {
        display: none !important;
    }
}

.u-printOnly {
    display: none !important;
}

@media print {
    .u-printOnly {
        display: block !important;
    }
}

.u-resetInput {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
}

.u-resetButton {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
}

    .u-resetButton:active, .u-resetButton:focus, .u-resetButton:hover {
        background-color: transparent;
    }

.u-resetTextarea {
    border: none;
    outline: 0;
    overflow: auto;
    resize: none;
    vertical-align: top;
}

.SkipLinks ul, .u-resetList, ol, ul {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

.u-vh {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.focusable.u-vh:active, .focusable.u-vh:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.u-wordBreak {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.u-vertCenter {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.no-csstransforms .u-vertCenter {
    top: auto;
    position: static;
}

[class*=" icon-"], [class^=icon-] {
    display: inline-block;
}

.no-inlinesvg .icon-arrow-circle-blue-27 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -272px -115px;
    width: 27px;
    height: 27px;
}

.no-inlinesvg .icon-arrow-down-blue-12 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -199px -372px;
    width: 12px;
    height: 12px;
}

.no-inlinesvg .icon-arrow-down-gray-26 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -272px -152px;
    width: 26px;
    height: 26px;
}

.no-inlinesvg .icon-arrow-forward-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -137px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-arrow-forward-white-15 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -127px -372px;
    width: 15px;
    height: 15px;
}

.no-inlinesvg .icon-arrow-forward-white-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -166px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-arrow-right-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -343px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-arrow-right-white-10 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -208px;
    width: 10px;
    height: 10px;
}

.no-inlinesvg .icon-arrow-right-white-25 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -305px;
    width: 25px;
    height: 25px;
}

.no-inlinesvg .icon-arrow-right-white-40 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -322px -240px;
    width: 40px;
    height: 40px;
}

.no-inlinesvg .icon-arrow-up-white-8 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -56px;
    width: 8px;
    height: 18px;
}

.no-inlinesvg .icon-bullhorn-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -278px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-bullhorn {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -109px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-calendar-blue-16 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -26px -372px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-calendar-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -193px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-calendar {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -372px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-chev-down-blue-17 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -54px -340px;
    width: 17px;
    height: 22px;
}

.no-inlinesvg .icon-chev-left-blue-9 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -110px;
    width: 9px;
    height: 16px;
}

.no-inlinesvg .icon-chev-right-blue-16 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -329px -340px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-chev-right-blue-9 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -84px;
    width: 9px;
    height: 16px;
}

.no-inlinesvg .icon-chev-right-white-10 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -228px;
    width: 10px;
    height: 10px;
}

.no-inlinesvg .icon-chev-right-white-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -165px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-chev-thin-left {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -115px;
    width: 100px;
    height: 100px;
}

.no-inlinesvg .icon-chev-thin-right {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -212px 0;
    width: 100px;
    height: 100px;
}

.no-inlinesvg .icon-chev-up-blue-17 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -27px -340px;
    width: 17px;
    height: 22px;
}

.no-inlinesvg .icon-chev-up-white-17 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -340px;
    width: 17px;
    height: 22px;
}

.no-inlinesvg .icon-child-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -138px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-child {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -110px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-dollar-circle-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -322px -80px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-email-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -221px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-email-white-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -81px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-facebook-blue-15 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -102px -372px;
    width: 15px;
    height: 15px;
}

.no-inlinesvg .icon-facebook-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -249px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-facebook-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -313px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-facebook-white-15 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -52px -372px;
    width: 15px;
    height: 15px;
}

.no-inlinesvg .icon-facebook-white-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -222px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-facebook-white-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -283px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-file-gray-14 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -152px -372px;
    width: 14px;
    height: 14px;
}

.no-inlinesvg .icon-gavel-blue-16 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -303px -340px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-heart-blue-16 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -277px -340px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-heart {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -355px -340px;
    width: 16px;
    height: 16px;
}

.no-inlinesvg .icon-hospital-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -160px -225px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-instagram-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -163px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-instagram-white-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -133px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-location-pin-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -322px 0;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-logo-white-202 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 0;
    width: 202px;
    height: 105px;
}

.no-inlinesvg .icon-lungs-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -322px -160px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-menu {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -68px -305px;
    width: 25px;
    height: 21px;
}

.no-inlinesvg .icon-pencil-box-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -192px -115px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-period-blue-8 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -248px;
    width: 8px;
    height: 8px;
}

.no-inlinesvg .icon-policy-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -240px -225px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-print-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -193px -340px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-search-blue-23 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -35px -305px;
    width: 23px;
    height: 25px;
}

.no-inlinesvg .icon-search-gray-13 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -176px -372px;
    width: 13px;
    height: 14px;
}

.no-inlinesvg .icon-search-white-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -373px -305px;
    width: 18px;
    height: 19px;
}

.no-inlinesvg .icon-speech-bubbles-white-72 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -110px -115px;
    width: 72px;
    height: 72px;
}

.no-inlinesvg .icon-stethoscope-blue-18 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -250px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-stethoscope-long-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -225px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-stethoscope {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -194px -197px;
    width: 18px;
    height: 18px;
}

.no-inlinesvg .icon-test-tube-white-70 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -80px -225px;
    width: 70px;
    height: 70px;
}

.no-inlinesvg .icon-triangle-down-blue-8 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -184px;
    width: 8px;
    height: 14px;
}

.no-inlinesvg .icon-triangle-down-gray-8 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -160px;
    width: 8px;
    height: 14px;
}

.no-inlinesvg .icon-triangle-down-white-10 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -28px;
    width: 10px;
    height: 18px;
}

.no-inlinesvg .icon-triangle-up-blue-8 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -136px;
    width: 8px;
    height: 14px;
}

.no-inlinesvg .icon-triangle-up-white-10 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px 0;
    width: 10px;
    height: 18px;
}

.no-inlinesvg .icon-twitter-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -253px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-twitter-white-15 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -77px -372px;
    width: 15px;
    height: 15px;
}

.no-inlinesvg .icon-twitter-white-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -223px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-youtube-blue-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -103px -305px;
    width: 20px;
    height: 20px;
}

.no-inlinesvg .icon-youtube-white-20 {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -372px -240px;
    width: 20px;
    height: 20px;
}

.icon-fallback {
    display: none;
}

.no-inlinesvg .icon-fallback {
    display: inline-block;
}

[data-icon] svg {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.js-fadeShow {
    opacity: 1 !important;
    visibility: visible !important;
    -webkit-transition: visibility 0s linear 0s,opacity 250ms !important;
    transition: visibility 0s linear 0s,opacity 250ms !important;
}

.js-fadeHide {
    opacity: 0;
    -webkit-transition: visibility 0s linear 250ms,opacity 250ms !important;
    transition: visibility 0s linear 250ms,opacity 250ms !important;
    visibility: hidden !important;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.Main {
    padding-top: 58px;
}

@media screen and (max-width: 22.25em) {
    .Main {
        padding-top: 84px
    }
}
.no-nav .Main {
    padding-top: 10px;
}

@media screen and (min-width:60em) {
    .Main {
        padding-top: 0;
    }
}

@media print {
    .Main {
        padding-top: 0;
    }
}

.Main-wrap:after, .Main-wrap:before {
    content: " ";
    display: table;
}

.Main-wrap:after {
    clear: both;
}

.Main-wrap:first-child {
    margin-top: 25px;
}

@media screen and (min-width:62em) {
    .Main-wrap:first-child {
        margin-top: 40px;
    }
}

@media print {
    .Main-wrap:first-child {
        margin-top: 0;
    }
}

.Main-wrap--percentage:after, .Main-wrap--percentage:before {
    content: " ";
    display: table;
}

.Main-wrap--percentage:after {
    clear: both;
}

.Main-wrap--percentage:first-child {
    margin-top: 25px;
}

@media screen and (min-width:48em) {
    .Main-wrap--percentage:first-child {
        margin-top: 40px;
    }
}

@media print {
    .Main-wrap--percentage:first-child {
        margin-top: 0;
    }
}

@media screen and (min-width:48em) {
    .Main-wrap--percentage {
        width: 95%;
    }
}

@media screen and (min-width:62em) {
    .Main-wrap--percentage {
        width: 90%;
    }
}

.page-clinical-trials .Main-wrap--percentage, .page-explainer .Main-wrap--percentage {
    max-width: 1000px;
}

@media print {
    .Main-wrap--percentage {
        max-width: none !important;
    }
}

.u-row {
    margin-left: -40px;
}

[class*=u-row]:after, [class*=u-row]:before {
    content: " ";
    display: table;
}

[class*=u-row]:after {
    clear: both;
}

[class*=u-row].-noGutter {
    margin-left: 0;
}

[class*=u-col-] {
    width: 100%;
}

.u-row > [class*=u-col-] {
    float: left;
    padding-left: 40px;
}

.-noGutter > [class*=u-col-] {
    padding-left: 0;
}

.u-col-full {
    float: left;
    padding-left: 40px;
    width: 100%;
}

.u-col-half {
    float: left;
    padding-left: 40px;
    width: 50%;
}

.u-col-third {
    float: left;
    padding-left: 40px;
    width: 33.333333%;
}

.u-col-quarter {
    float: left;
    padding-left: 40px;
    width: 25%;
}

.u-col-centered, .u-row > .u-col-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width:20em) {
    .u-row--narrow {
        margin-left: -40px;
    }

    .u-col-full--narrow {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--narrow {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--narrow {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--narrow {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

@media screen and (min-width:30em) {
    .u-row--small {
        margin-left: -40px;
    }

    .u-col-full--small {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--small {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--small {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--small {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

@media screen and (min-width:40em) {
    .u-row--medium {
        margin-left: -40px;
    }

    .u-col-full--medium {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--medium {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--medium {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--medium {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

@media screen and (min-width:48em) {
    .u-row--med-wide {
        margin-left: -40px;
    }

    .u-col-full--med-wide {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--med-wide {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--med-wide {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--med-wide {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

@media screen and (min-width:60em) {
    .u-row--wide {
        margin-left: -40px;
    }

    .u-col-full--wide {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--wide {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--wide {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--wide {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

@media screen and (min-width:64em) {
    .u-row--x-wide {
        margin-left: -40px;
    }

    .u-col-full--x-wide {
        float: left;
        padding-left: 40px;
        width: 100%;
    }

    .u-col-half--x-wide {
        float: left;
        padding-left: 40px;
        width: 50%;
    }

    .u-col-third--x-wide {
        float: left;
        padding-left: 40px;
        width: 33.333333%;
    }

    .u-col-quarter--x-wide {
        float: left;
        padding-left: 40px;
        width: 25%;
    }
}

.l-sidebar .Main-columns {
    clear: both;
}

    .l-sidebar .Main-columns:after, .l-sidebar .Main-columns:before {
        content: " ";
        display: table;
    }

    .l-sidebar .Main-columns:after {
        clear: both;
    }

.l-sidebar .Main-primary:after, .l-sidebar .Main-primary:before {
    content: " ";
    display: table;
}

.l-sidebar .Main-primary:after {
    clear: both;
}

@media print {
    .l-sidebar .Main-secondary {
        display: none !important;
    }
}

.l-sidebar .Main-secondary:after, .l-sidebar .Main-secondary:before {
    content: " ";
    display: table;
}

.l-sidebar .Main-secondary:after {
    clear: both;
}

@media screen and (min-width:51.875em) {
    .l-sidebar .Main-primary {
        float: left;
        margin-right: -260px;
        padding-right: 300px;
        width: 100%;
    }

    .l-sidebar .Main-secondary {
        float: left;
        margin-top: 0;
        width: 260px;
    }

        .l-sidebar .Main-secondary.has-divider {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }
}

.u-wrapper {
    margin: 0 auto;
    max-width: 60.588235rem;
    padding-left: .882353rem;
    padding-right: .882353rem;
}

@media screen and (min-width:30em) {
    .u-wrapper {
        max-width: 61.176471rem;
        padding-left: 1.176471rem;
        padding-right: 1.176471rem;
    }
}

@media screen and (min-width:64em) {
    .u-wrapper {
        max-width: 62.352941rem;
        padding-left: 1.764706rem;
        padding-right: 1.764706rem;
    }
}

@media print {
    .u-wrapper {
        max-width: none;
    }
}

.Button, .Button--teal, .Button--transparent, .Button--white, .Button--yellow, button {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: .823529rem;
    letter-spacing: 1px;
    line-height: 1;
    padding: .882353rem 1.764706rem .823529rem;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all 150ms ease-out;
    transition: all 150ms ease-out;
    word-spacing: 2px;
}

.fonts-loaded .Button, .fonts-loaded .Button--teal, .fonts-loaded .Button--transparent, .fonts-loaded .Button--white, .fonts-loaded .Button--yellow, .fonts-loaded button {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Button--teal:disabled, .Button--transparent:disabled, .Button--white:disabled, .Button--yellow:disabled, .Button:disabled, .is-disabled.Button, .is-disabled.Button--teal, .is-disabled.Button--transparent, .is-disabled.Button--white, .is-disabled.Button--yellow, button.is-disabled, button:disabled {
    background-color: #d8d8d8 !important;
    color: rgba(51,51,51,.5) !important;
    cursor: not-allowed !important;
    text-shadow: none !important;
}

.Button, .Button:link, .Button:visited {
    background-color: #08a7e7;
    color: #fff;
    text-shadow: 1px 1px 1px #0683b6;
}

    .Button:active, .Button:focus, .Button:hover {
        background-color: #0795ce;
    }

    .Button svg {
        fill: currentColor;
        margin-top: -.214286em;
        vertical-align: text-top;
    }

.Button--narrow {
    padding-left: 25px;
    padding-right: 25px;
}

.Button--block {
    display: block;
    padding: 1.176471rem 1.470588rem 1.117647rem;
}

@media screen and (min-width:51.875em) {
    .Button--block {
        display: inline-block;
        letter-spacing: 0;
        padding: 1.058824rem 1.470588rem 1rem;
        word-spacing: 0;
    }
}

.Button--yellow, .Button--yellow:link, .Button--yellow:visited {
    background-color: #f0b800;
    color: #fff;
    text-shadow: 1px 1px 1px #bd9100;
}

    .Button--yellow:active, .Button--yellow:focus, .Button--yellow:hover {
        background-color: #d7a400;
    }

.Button--teal, .Button--teal:link, .Button--teal:visited {
    background-color: #04405b;
    color: #fff;
    text-shadow: 1px 1px 1px #021e2a;
}

    .Button--teal:active, .Button--teal:focus, .Button--teal:hover {
        background-color: #032f43;
    }

.Button--white {
    border: 1px solid #ccc;
}

    .Button--white, .Button--white:link, .Button--white:visited {
        background-color: #fff;
        color: #08a7e7;
        text-shadow: none;
    }

        .Button--white:active, .Button--white:focus, .Button--white:hover {
            background-color: #fafafa;
            border-color: #08a7e7;
        }

.Button--donate {
    font-size: 1rem;
    padding-bottom: 1.117647rem;
    padding-top: 1.176471rem;
}

.Button--transparent {
    border: 1px solid rgba(255,255,255,.5);
    text-shadow: 1px 1px 1px #313131;
}

    .Button--transparent, .Button--transparent:link, .Button--transparent:visited {
        background-color: transparent;
        color: #fff;
    }

        .Button--transparent:active, .Button--transparent:focus, .Button--transparent:hover {
            background-color: rgba(255,255,255,.15);
            border-color: #fff;
        }

button, button:link, button:visited {
    background-color: #08a7e7;
    color: #fff;
    text-shadow: 1px 1px 1px #0683b6;
}

    button:active, button:focus, button:hover {
        background-color: #0795ce;
    }

dl {
    margin-bottom: 1em;
    margin-top: 0;
}

dd {
    margin-left: 0;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    padding-left: 0;
    padding-right: 0;
}

input[type=checkbox], input[type=radio], label {
    cursor: pointer;
}

textarea {
    display: block;
    width: 100%;
}

[readonly] {
    cursor: text;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number], input[type=search], input[type=email], input[type=password], input[type=tel], input[type=text], input[type=url], textarea {
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: inherit;
    margin: 0;
    padding: .470588rem .705882rem;
    width: 100%;
    -webkit-appearance: none;
}

    input.is-invalid, select.is-invalid, textarea.is-invalid {
        border-color: #ed1c24;
    }

[data-validate=error] {
    color: #ed1c24;
}

    [data-validate=error].is-hidden {
        display: none !important;
    }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #666;
}

input::-moz-placeholder, textarea::-moz-placeholder {
    color: #666;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #666;
}

input::placeholder, textarea::placeholder {
    color: #666;
}

input:focus, textarea:focus {
    border-color: #08a7e7;
}

/*h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    font-weight: 400;
    margin: 0;
}*/

hr {
    background-color: #000;
    border: none;
    height: 1px;
    margin: 0;
}

img {
    height: auto;
    max-width: 100%;
}

.u-photoBorder {
    border: 7px solid #fff;
    -webkit-box-shadow: 3px 3px 1px 0 rgba(0,0,0,.15);
    box-shadow: 3px 3px 1px 0 rgba(0,0,0,.15);
}

a {
    -webkit-transition: color 150ms ease-in-out,background-color 150ms ease-in-out,border 150ms ease-in-out;
    transition: color 150ms ease-in-out,background-color 150ms ease-in-out,border 150ms ease-in-out;
}

    a, a:active, a:focus, a:hover, a:link, a:visited {
        text-decoration: none;
    }

a, a:link {
    color: #0d77b5;
}

a:visited {
    color: #0d77b5;
}

            a:active, a:focus, a:hover {
                color: #078ac0;
            }

            a[href^=tel] {
                color: inherit;
                text-decoration: none;
            }

.u-listDefault {
    list-style-type: inherit;
    margin-bottom: 1em;
    padding-left: 2.352941em;
}

    .u-listDefault ol, .u-listDefault ul {
        margin-bottom: 0;
    }

    .u-listDefault li {
        margin-top: .5em;
    }

        .u-listDefault li:first-child {
            margin-top: 0;
        }

        .u-listDefault li li:first-child {
            margin-top: .5em;
        }

.u-listFloat {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

    .u-listFloat:after, .u-listFloat:before {
        content: " ";
        display: table;
    }

    .u-listFloat:after {
        clear: both;
    }

    .u-listFloat > li {
        float: left;
    }

.u-listInlineCenter {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    text-align: center;
}

    .u-listInlineCenter > li {
        display: inline-block;
        text-align: left;
    }

.u-listDivided {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

    .u-listDivided:after, .u-listDivided:before {
        content: " ";
        display: table;
    }

    .u-listDivided:after {
        clear: both;
    }

    .u-listDivided > li {
        border-left-style: solid;
        border-left-width: 1px;
        float: left;
        margin-left: 1em;
        padding-left: 1em;
    }

        .u-listDivided > li:first-child {
            border-left: none;
            margin-left: 0;
            padding-left: 0;
        }

.u-listCommas {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

    .u-listCommas > li {
        display: inline;
    }

        .u-listCommas > li:after {
            content: ', ';
            display: inline-block;
        }

        .u-listCommas > li:last-child:after {
            display: none;
        }

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td, th {
    padding: 0;
}

p {
    margin: 0;
}

blockquote {
    margin: 0;
}

.u-text-intro {
    font-size: 1.058824rem;
}

@media screen and (min-width:20em) {
    .u-text-intro {
        font-size: 1.058824rem;
        line-height: 1.555;
    }
}

@media screen and (min-width:31em) {
    .u-text-intro {
        font-size: 1.117647rem;
        line-height: 1.57525;
    }
}

@media screen and (min-width:42em) {
    .u-text-intro {
        font-size: 1.176471rem;
        line-height: 1.5955;
    }
}

@media screen and (min-width:53em) {
    .u-text-intro {
        font-size: 1.235294rem;
        line-height: 1.61575;
    }
}

@media screen and (min-width:64em) {
    .u-text-intro {
        font-size: 1.294118rem;
        line-height: 1.636;
    }
}

.u-rocksalt1 {
    font-weight: 700;
    color: #08a7e7;
    font-size: 1.294118rem;
    line-height: 1.36;
}

.fonts-loaded .u-rocksalt1 {
    font-family: "Rock Salt","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:40em) {
    .u-rocksalt1 {
        font-size: 129.411765%;
    }
}

@media screen and (min-width:42em) {
    .u-rocksalt1 {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:44em) {
    .u-rocksalt1 {
        font-size: 152.941176%;
    }
}

@media screen and (min-width:46em) {
    .u-rocksalt1 {
        font-size: 164.705882%;
    }
}

@media screen and (min-width:48em) {
    .u-rocksalt1 {
        font-size: 176.470588%;
    }
}

.text1-style {
    font-weight: 700;
    color: #454545;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
}

.fonts-loaded .text1-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text1-style {
    text-rendering: auto;
}

.text2-style {
    font-weight: 700;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

.fonts-loaded .text2-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text2-style {
    text-rendering: auto;
}

.text3-style {
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}

.fonts-loaded .text3-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text3-style {
    text-rendering: auto;
}

.text4-style {
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

.fonts-loaded .text4-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text4-style {
    text-rendering: auto;
}

.text5-style {
    font-weight: 600;
    color: #454545;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

.fonts-loaded .text5-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text5-style {
    text-rendering: auto;
}

.text6-style {
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

.fonts-loaded .text6-style {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text6-style {
    text-rendering: auto;
}

.text1-size {
    font-size: 1.764706rem;
}

@media screen and (min-width:20em) {
    .text1-size {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:24.625em) {
    .text1-size {
        font-size: 2.058824rem;
    }
}

@media screen and (min-width:29.25em) {
    .text1-size {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:33.875em) {
    .text1-size {
        font-size: 2.411765rem;
    }
}

@media screen and (min-width:38.5em) {
    .text1-size {
        font-size: 2.588235rem;
    }
}

@media screen and (min-width:43.125em) {
    .text1-size {
        font-size: 2.764706rem;
    }
}

@media screen and (min-width:47.75em) {
    .text1-size {
        font-size: 2.941176rem;
    }
}

.text2-size {
    font-size: 1.176471rem;
}

@media screen and (min-width:20em) {
    .text2-size {
        font-size: 1.294118rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .text2-size {
        font-size: 1.411765rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .text2-size {
        font-size: 1.529412rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .text2-size {
        font-size: 1.647059rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .text2-size {
        font-size: 1.764706rem;
        line-height: 1.2;
    }
}

.text3-size {
    font-size: 1.058824rem;
}

@media screen and (min-width:20em) {
    .text3-size {
        font-size: 1.058824rem;
        line-height: 1.444;
    }
}

@media screen and (min-width:60em) {
    .text3-size {
        font-size: 1.176471rem;
        line-height: 1.3;
    }
}

.text4-size {
    font-size: .941176rem;
}

@media screen and (min-width:20em) {
    .text4-size {
        font-size: .941176rem;
    }
}

@media screen and (min-width:40em) {
    .text4-size {
        font-size: 1rem;
    }
}

.text5-size {
    font-size: .823529rem;
}

@media screen and (min-width:20em) {
    .text5-size {
        font-size: .823529rem;
    }
}

@media screen and (min-width:60em) {
    .text5-size {
        font-size: .882353rem;
    }
}

.text6-size {
    font-size: .705882rem;
}

@media screen and (min-width:20em) {
    .text6-size {
        font-size: .705882rem;
    }
}

@media screen and (min-width:60em) {
    .text6-size {
        font-size: .764706rem;
    }
}

.text1 {
    font-weight: 700;
    color: #454545;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
    font-size: 1.764706rem;
}

.fonts-loaded .text1 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text1 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text1 {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:24.625em) {
    .text1 {
        font-size: 2.058824rem;
    }
}

@media screen and (min-width:29.25em) {
    .text1 {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:33.875em) {
    .text1 {
        font-size: 2.411765rem;
    }
}

@media screen and (min-width:38.5em) {
    .text1 {
        font-size: 2.588235rem;
    }
}

@media screen and (min-width:43.125em) {
    .text1 {
        font-size: 2.764706rem;
    }
}

@media screen and (min-width:47.75em) {
    .text1 {
        font-size: 2.941176rem;
    }
}

.text2 {
    font-weight: 700;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    font-size: 1.176471rem;
}

.fonts-loaded .text2 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text2 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text2 {
        font-size: 1.294118rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .text2 {
        font-size: 1.411765rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .text2 {
        font-size: 1.529412rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .text2 {
        font-size: 1.647059rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .text2 {
        font-size: 1.764706rem;
        line-height: 1.2;
    }
}

.text3 {
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
    font-size: 1.058824rem;
}

.fonts-loaded .text3 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text3 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text3 {
        font-size: 1.058824rem;
        line-height: 1.444;
    }
}

@media screen and (min-width:60em) {
    .text3 {
        font-size: 1.176471rem;
        line-height: 1.3;
    }
}

.text4 {
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    font-size: .941176rem;
}

.fonts-loaded .text4 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text4 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text4 {
        font-size: .941176rem;
    }
}

@media screen and (min-width:40em) {
    .text4 {
        font-size: 1rem;
    }
}

.text5 {
    font-weight: 600;
    color: #454545;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
    font-size: .823529rem;
}

.fonts-loaded .text5 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text5 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text5 {
        font-size: .823529rem;
    }
}

@media screen and (min-width:60em) {
    .text5 {
        font-size: .882353rem;
    }
}

.text6 {
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
    font-size: .705882rem;
}

.fonts-loaded .text6 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .text6 {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .text6 {
        font-size: .705882rem;
    }
}

@media screen and (min-width:60em) {
    .text6 {
        font-size: .764706rem;
    }
}

.u-richtext {
    color: #454545;
    font-size: .882353rem;
    line-height: 1.467;
    margin-bottom: 1em;
}

@media screen and (min-width:40em) {
    .u-richtext {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.u-richtext:after, .u-richtext:before {
    content: " ";
    display: table;
}

.u-richtext:after {
    clear: both;
}

@media screen and (min-width:30em) {
    .u-richtext {
        font-size: .882353rem;
        line-height: 1.467;
    }
}

@media screen and (min-width:35em) {
    .u-richtext {
        font-size: .941176rem;
        line-height: 1.4985;
    }
}

@media screen and (min-width:40em) {
    .u-richtext {
        font-size: 1rem;
        line-height: 1.53;
    }
}

.u-richtext:last-child {
    margin-bottom: 0;
}

.u-richtext > :last-child {
    margin-bottom: 0;
}

.u-richtext > p:empty {
    display: none;
}

.u-richtext > h1, .u-richtext > h2, .u-richtext > h3, .u-richtext > h4, .u-richtext > h5, .u-richtext > h6 {
    margin-bottom: .588235rem;
    margin-top: 1.764706rem;
}

    .u-richtext > h1:first-child, .u-richtext > h2:first-child, .u-richtext > h3:first-child, .u-richtext > h4:first-child, .u-richtext > h5:first-child, .u-richtext > h6:first-child {
        margin-top: 0;
    }

.u-richtext > h1 {
    font-size: 1.764706rem;
    font-weight: 700;
    color: #454545;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext > h1 {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:24.625em) {
    .u-richtext > h1 {
        font-size: 2.058824rem;
    }
}

@media screen and (min-width:29.25em) {
    .u-richtext > h1 {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:33.875em) {
    .u-richtext > h1 {
        font-size: 2.411765rem;
    }
}

@media screen and (min-width:38.5em) {
    .u-richtext > h1 {
        font-size: 2.588235rem;
    }
}

@media screen and (min-width:43.125em) {
    .u-richtext > h1 {
        font-size: 2.764706rem;
    }
}

@media screen and (min-width:47.75em) {
    .u-richtext > h1 {
        font-size: 2.941176rem;
    }
}

.fonts-loaded .u-richtext > h1 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h1 {
    text-rendering: auto;
}

.u-richtext > h2 {
    font-size: 1.176471rem;
    font-weight: 700;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext > h2 {
        font-size: 1.294118rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .u-richtext > h2 {
        font-size: 1.411765rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .u-richtext > h2 {
        font-size: 1.529412rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .u-richtext > h2 {
        font-size: 1.647059rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .u-richtext > h2 {
        font-size: 1.764706rem;
        line-height: 1.2;
    }
}

.fonts-loaded .u-richtext > h2 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h2 {
    text-rendering: auto;
}

.u-richtext > h3 {
    font-size: 1.058824rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext > h3 {
        font-size: 1.058824rem;
        line-height: 1.444;
    }
}

@media screen and (min-width:60em) {
    .u-richtext > h3 {
        font-size: 1.176471rem;
        line-height: 1.3;
    }
}

.fonts-loaded .u-richtext > h3 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h3 {
    text-rendering: auto;
}

.u-richtext > h4 {
    font-size: .941176rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext > h4 {
        font-size: .941176rem;
    }
}

@media screen and (min-width:40em) {
    .u-richtext > h4 {
        font-size: 1rem;
    }
}

.fonts-loaded .u-richtext > h4 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h4 {
    text-rendering: auto;
}

.u-richtext > h5 {
    font-size: .823529rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@media screen and (min-width:20em) {
    .u-richtext > h5 {
        font-size: .823529rem;
    }
}

@media screen and (min-width:60em) {
    .u-richtext > h5 {
        font-size: .882353rem;
    }
}

.fonts-loaded .u-richtext > h5 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h5 {
    text-rendering: auto;
}

.u-richtext > h6 {
    font-size: .705882rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@media screen and (min-width:20em) {
    .u-richtext > h6 {
        font-size: .705882rem;
    }
}

@media screen and (min-width:60em) {
    .u-richtext > h6 {
        font-size: .764706rem;
    }
}

.fonts-loaded .u-richtext > h6 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext > h6 {
    text-rendering: auto;
}

.u-richtext > figure, .u-richtext > p {
    margin-bottom: 1em;
}

.u-richtext > blockquote {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-left: 4px solid #f39c23;
    color: #f39c23;
    font-size: 1.058824em;
    line-height: 1.333;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 20px;
}

.fonts-loaded .u-richtext > blockquote {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .u-richtext > blockquote {
        font-size: 105.882353%;
        line-height: 1.333;
    }
}

@media screen and (min-width:25em) {
    .u-richtext > blockquote {
        font-size: 117.647059%;
        line-height: 1.394;
    }
}

@media screen and (min-width:30em) {
    .u-richtext > blockquote {
        font-size: 129.411765%;
        line-height: 1.455;
    }
}

.u-richtext > blockquote.blue {
    border-left-color: #006694;
    color: #006694;
}

.u-richtext > table {
    border-bottom: 3px solid #ccc;
    margin: 2.352941rem 0;
    width: 100%;
}

    .u-richtext > table caption {
        margin-bottom: .882353rem;
    }

    .u-richtext > table tr:nth-child(2n+2) {
        background-color: #eee;
    }

    .u-richtext > table td {
        padding: .882353rem;
    }

    .u-richtext > table th {
        border-bottom: 3px solid #ccc;
        padding: .588235rem .882353rem;
        text-align: left;
    }

    .u-richtext > table tfoot td {
        border-top: 3px solid #ccc;
        font-weight: 700;
    }

    .u-richtext > table:first-child {
        margin-top: 0;
    }

.u-richtext > a, .u-richtext > a:link {
    color: #08a7e7;
}

    .u-richtext > a:visited {
        color: #006694;
    }

    .u-richtext > a:active, .u-richtext > a:focus, .u-richtext > a:hover {
        color: #078ac0;
    }

.u-richtext > dl a, .u-richtext > dl a:link, .u-richtext > ol a, .u-richtext > ol a:link, .u-richtext > p a, .u-richtext > p a:link, .u-richtext > table a, .u-richtext > table a:link, .u-richtext > ul a, .u-richtext > ul a:link {
    color: #08a7e7;
}

    .u-richtext > dl a:visited, .u-richtext > ol a:visited, .u-richtext > p a:visited, .u-richtext > table a:visited, .u-richtext > ul a:visited {
        color: #006694;
    }

    .u-richtext > dl a:active, .u-richtext > dl a:focus, .u-richtext > dl a:hover, .u-richtext > ol a:active, .u-richtext > ol a:focus, .u-richtext > ol a:hover, .u-richtext > p a:active, .u-richtext > p a:focus, .u-richtext > p a:hover, .u-richtext > table a:active, .u-richtext > table a:focus, .u-richtext > table a:hover, .u-richtext > ul a:active, .u-richtext > ul a:focus, .u-richtext > ul a:hover {
        color: #078ac0;
    }

.u-richtext > ol, .u-richtext > ul {
    margin-bottom: 1em;
    padding-left: 2.352941rem;
    overflow: hidden;
}

    .u-richtext > ol ol, .u-richtext > ol ul, .u-richtext > ul ol, .u-richtext > ul ul {
        margin-bottom: 0;
    }

    .u-richtext > ol li, .u-richtext > ul li {
        margin-top: .5em;
    }

        .u-richtext > ol li ol, .u-richtext > ol li ul, .u-richtext > ul li ol, .u-richtext > ul li ul {
            padding-left: 2.352941rem;
        }

        .u-richtext > ol li:first-child, .u-richtext > ul li:first-child {
            margin-top: 0;
        }

        .u-richtext > ol li li:first-child, .u-richtext > ul li li:first-child {
            margin-top: .5em;
        }

        .u-richtext > ol li > p:last-child, .u-richtext > ul li > p:last-child {
            margin-bottom: 0;
        }

    .u-richtext > ol ul, .u-richtext > ul {
        list-style: disc;
    }

        .u-richtext > ol ul ul, .u-richtext > ul ul {
            list-style: circle;
        }

        .u-richtext > ol, .u-richtext > ul ol {
            list-style: decimal;
        }

            .u-richtext > ol ol, .u-richtext > ul ol ol {
                list-style: lower-alpha;
            }

                .u-richtext > ol ol ol, .u-richtext > ul ol ol ol {
                    list-style: lower-roman;
                }

.u-richtext > ul {
    padding-left: 21px;
    overflow: hidden;
}

    .u-richtext > ul > li {
        padding-left: 1.117647rem;
        position: relative;
        list-style-type: none;
    }

@media print {
    .u-richtext > ul > li {
        list-style: disc;
        padding-left: 0;
    }
}

.u-richtext > ul > li:before {
    background-color: #ccc;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '\0020';
    height: 6px;
    left: 0;
    position: absolute;
    top: .5em;
    width: 6px;
}

@media print {
    .u-richtext > ul > li:before {
        display: none;
    }
}

.u-richtext b, .u-richtext strong {
    font-weight: 600;
}

.u-richtext sup {
    font-size: .8em;
    font-weight: 600;
    left: -1px;
}

.u-richtext--article > h2 {
    font-size: 1.058824rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext--article > h2 {
        font-size: 1.058824rem;
        line-height: 1.444;
    }
}

@media screen and (min-width:60em) {
    .u-richtext--article > h2 {
        font-size: 1.176471rem;
        line-height: 1.3;
    }
}

.fonts-loaded .u-richtext--article > h2 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext--article > h2 {
    text-rendering: auto;
}

.u-richtext--article > h3 {
    font-size: .941176rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .u-richtext--article > h3 {
        font-size: .941176rem;
    }
}

@media screen and (min-width:40em) {
    .u-richtext--article > h3 {
        font-size: 1rem;
    }
}

.fonts-loaded .u-richtext--article > h3 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext--article > h3 {
    text-rendering: auto;
}

.u-richtext--article > h4 {
    font-size: .823529rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@media screen and (min-width:20em) {
    .u-richtext--article > h4 {
        font-size: .823529rem;
    }
}

@media screen and (min-width:60em) {
    .u-richtext--article > h4 {
        font-size: .882353rem;
    }
}

.fonts-loaded .u-richtext--article > h4 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext--article > h4 {
    text-rendering: auto;
}

.u-richtext--article > h5 {
    font-size: .705882rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@media screen and (min-width:20em) {
    .u-richtext--article > h5 {
        font-size: .705882rem;
    }
}

@media screen and (min-width:60em) {
    .u-richtext--article > h5 {
        font-size: .764706rem;
    }
}

.fonts-loaded .u-richtext--article > h5 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext--article > h5 {
    text-rendering: auto;
}

.u-richtext--article > h6 {
    font-size: .705882rem;
    font-weight: 600;
    color: #666;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@media screen and (min-width:20em) {
    .u-richtext--article > h6 {
        font-size: .705882rem;
    }
}

@media screen and (min-width:60em) {
    .u-richtext--article > h6 {
        font-size: .764706rem;
    }
}

.fonts-loaded .u-richtext--article > h6 {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .u-richtext--article > h6 {
    text-rendering: auto;
}

.u-richtext--intro > p:first-child {
    font-size: 1.4em;
    margin-bottom: 1rem;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*, :after, :before {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

embed, iframe, object {
    z-index: 1 !important;
}

.ios input, .ios select, .ios textarea {
    font-size: 16px !important;
}

a > svg, button > svg {
    pointer-events: none;
}

a[href^=mailto] {
    word-break: break-all;
    word-break: break-word;
}

html {
    font-size: 106.25%;
}

body {
    font-weight: 400;
    background-color: #fff;
    color: #454545;
    font-family: "Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
    line-height: 1.529412;
}

.fonts-loaded body {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.disable-hover {
    pointer-events: none;
}

[data-whatinput=mouse] :focus, [data-whatinput=touch] :focus {
    outline: 0;
}

.u-clear {
    clear: both;
}

.u-clearfix:after, .u-clearfix:before {
    content: " ";
    display: table;
}

.u-clearfix:after {
    clear: both;
}

.u-shiftUp {
    margin-top: 25px;
}

@media screen and (min-width:48em) {
    .u-shiftUp {
        background-color: #fff;
        margin-top: -50px;
        padding-top: 28px;
        position: relative;
    }

        .u-shiftUp .Main-secondary > [class*=u-mt]:first-child {
            margin-top: 0;
        }
}

.u-fullWidth {
    max-width: none;
}

@media screen and (min-width:48em) {
    .u-fullWidth {
        width: 95%;
    }
}

@media screen and (min-width:60em) {
    .u-fullWidth {
        width: 90%;
    }
}

.u-italic {
    font-style: italic;
}

.u-uppercase {
    text-transform: uppercase;
}

.u-nowrap {
    white-space: nowrap;
}

.u-textCenter {
    text-align: center;
}

.u-textLeft {
    text-align: left;
}

.u-textRight {
    text-align: right;
}

.u-bulletsDots > li {
    padding-left: .941176rem;
    position: relative;
}

@media print {
    .u-bulletsDots > li {
        list-style: disc;
        padding-left: 0;
    }
}

.u-bulletsDots > li:before {
    background-color: #08a7e7;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '\0020';
    height: 6px;
    left: 0;
    position: absolute;
    top: .5em;
    width: 6px;
}

@media print {
    .u-bulletsDots > li:before {
        display: none;
    }
}

.u-aspectWide {
    display: block;
    position: relative;
}

    .u-aspectWide:before {
        content: "\0020";
        display: block;
    }

    .u-aspectWide:before {
        padding-top: 56.25%;
    }

.u-aspectSquare {
    display: block;
    position: relative;
}

    .u-aspectSquare:before {
        content: "\0020";
        display: block;
    }

    .u-aspectSquare:before {
        padding-top: 100%;
    }

.u-show {
    display: block;
}

.u-hide {
    display: none;
}

.u-HIDE {
    display: none !important;
}

.u-invisible {
    visibility: hidden;
}

.u-visible {
    visibility: visible;
}

.no-js-show {
    display: none !important;
}

.no-js .no-js-show {
    display: block !important;
}

.no-js .no-js-hide {
    display: none !important;
}

.js-hide {
    display: none !important;
}

.no-js .js-hide {
    display: block !important;
}

.u-fadeIn {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}

.u-fadeIn--fast {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
}

.u-fadeOut {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}

.u-fadeOut--fast {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
}

.u-topShadow {
    overflow: hidden;
    position: relative;
}

    .u-topShadow:before {
        background: -webkit-radial-gradient(center top ellipse,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        background: radial-gradient(ellipse at center top,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        content: '\0020';
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.u-topGradient {
    overflow: hidden;
    position: relative;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f2f2f2),color-stop(46%,#fff));
    background-image: -webkit-linear-gradient(top,#f2f2f2,#fff 46%);
    background-image: linear-gradient(to bottom,#f2f2f2,#fff 46%);
    overflow: hidden;
}

    .u-topGradient:before {
        background: -webkit-radial-gradient(center top ellipse,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        background: radial-gradient(ellipse at center top,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        content: '\0020';
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.u-circleArrow {
    height: 0;
    position: relative;
}

@media print {
    .u-circleArrow {
        display: none !important;
    }
}

.u-circleArrow:before {
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20140%20140%22%20width=%22140%22%20height=%22140%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23ccc%22%3E%3Cpath%20d=%22M123%2078.8c-2.9-2.9-7.9-2.9-10.8%200l-35%2034.9V8.1C77.2%204%2073.8.6%2069.7.6S62.2%204%2062.2%208.1v105.6L27.5%2078.8c-2.9-2.9-7.7-2.9-10.6%200-2.9%202.9-2.9%207.7%200%2010.7l47.5%2047.8c.1.1.1.5.2.5%201.3%201%202.9%201.7%204.5%201.9.3%200%20.5.1.8.1.3%200%20.5-.2.8-.2v-.1c1.7-.2%203.5-.9%204.5-2.2%200-.1.2-.1.2-.2L123%2089.5c2.9-2.9%203-7.7%200-10.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: 40% auto;
    background-size: 40% auto;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '\0020';
    height: 64px;
    left: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%) translateY(-40%);
    -ms-transform: translateX(-50%) translateY(-40%);
    transform: translateX(-50%) translateY(-40%);
    width: 64px;
    z-index: 1;
}

.no-csstransforms .u-circleArrow:before {
    margin-left: -32px;
    margin-top: -32px;
}

@media print {
    .u-circleArrow {
        display: none !important;
    }
}

.u-circleArrow--shadow {
    height: 0;
    position: relative;
}

@media print {
    .u-circleArrow--shadow {
        display: none !important;
    }
}

.u-circleArrow--shadow:before {
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20140%20140%22%20width=%22140%22%20height=%22140%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23ccc%22%3E%3Cpath%20d=%22M123%2078.8c-2.9-2.9-7.9-2.9-10.8%200l-35%2034.9V8.1C77.2%204%2073.8.6%2069.7.6S62.2%204%2062.2%208.1v105.6L27.5%2078.8c-2.9-2.9-7.7-2.9-10.6%200-2.9%202.9-2.9%207.7%200%2010.7l47.5%2047.8c.1.1.1.5.2.5%201.3%201%202.9%201.7%204.5%201.9.3%200%20.5.1.8.1.3%200%20.5-.2.8-.2v-.1c1.7-.2%203.5-.9%204.5-2.2%200-.1.2-.1.2-.2L123%2089.5c2.9-2.9%203-7.7%200-10.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: 40% auto;
    background-size: 40% auto;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 12px 0 rgba(0,0,0,.3);
    box-shadow: 0 0 12px 0 rgba(0,0,0,.3);
    background-position: center 80%;
    content: '\0020';
    height: 64px;
    left: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%) translateY(-40%);
    -ms-transform: translateX(-50%) translateY(-40%);
    transform: translateX(-50%) translateY(-40%);
    -webkit-transform: translateX(-50%) translateY(-46%);
    -ms-transform: translateX(-50%) translateY(-46%);
    transform: translateX(-50%) translateY(-46%);
    width: 64px;
    z-index: 1;
}

.no-csstransforms .u-circleArrow--shadow:before {
    margin-left: -32px;
    margin-top: -32px;
}

@media print {
    .u-circleArrow--shadow {
        display: none !important;
    }
}

.u-mb1 {
    margin-bottom: .294118rem;
}

.u-mt1 {
    margin-top: .294118rem;
}

.u-mb2 {
    margin-bottom: .588235rem;
}

.u-mt2 {
    margin-top: .588235rem;
}

.u-mb3 {
    margin-bottom: .882353rem;
}

.u-mt3 {
    margin-top: .882353rem;
}

.u-mb4 {
    margin-bottom: 1.176471rem;
}

.u-mt4 {
    margin-top: 1.176471rem;
}

.u-mb5 {
    margin-bottom: 1.470588rem;
}

.u-mt5 {
    margin-top: 1.470588rem;
}

.u-mb6 {
    margin-bottom: 1.764706rem;
}

.u-mt6 {
    margin-top: 1.764706rem;
}

.u-mb7 {
    margin-bottom: 2.058824rem;
}

.u-mt7 {
    margin-top: 2.058824rem;
}

.u-mb8 {
    margin-bottom: 2.352941rem;
}

.u-mt8 {
    margin-top: 2.352941rem;
}

.u-mb9 {
    margin-bottom: 2.647059rem;
}

.u-mt9 {
    margin-top: 2.647059rem;
}

.u-mb10 {
    margin-bottom: 2.941176rem;
}

.u-mt10 {
    margin-top: 2.941176rem;
}

.u-mb11 {
    margin-bottom: 3.235294rem;
}

.u-mt11 {
    margin-top: 3.235294rem;
}

.u-mb12 {
    margin-bottom: 3.529412rem;
}

.u-mt12 {
    margin-top: 3.529412rem;
}

.u-mb13 {
    margin-bottom: 3.823529rem;
}

.u-mt13 {
    margin-top: 3.823529rem;
}

.u-mb14 {
    margin-bottom: 4.117647rem;
}

.u-mt14 {
    margin-top: 4.117647rem;
}

.u-mb15 {
    margin-bottom: 4.411765rem;
}

.u-mt15 {
    margin-top: 4.411765rem;
}

.u-mb16 {
    margin-bottom: 4.705882rem;
}

.u-mt16 {
    margin-top: 4.705882rem;
}

.u-mb17 {
    margin-bottom: 5rem;
}

.u-mt17 {
    margin-top: 5rem;
}

.u-mb18 {
    margin-bottom: 5.294118rem;
}

.u-mt18 {
    margin-top: 5.294118rem;
}

.u-mb19 {
    margin-bottom: 5.588235rem;
}

.u-mt19 {
    margin-top: 5.588235rem;
}

.u-mb20 {
    margin-bottom: 5.882353rem;
}

.u-mt20 {
    margin-top: 5.882353rem;
}

.u-break1-top {
    margin-top: 35px;
}

@media screen and (min-width:48em) {
    .u-break1-top {
        margin-top: 60px;
    }
}

.u-break2-top {
    margin-top: 60px;
}

@media screen and (min-width:48em) {
    .u-break2-top {
        margin-top: 90px;
    }
}

@media print {
    .u-break2-top {
        margin-top: 30px;
    }
}

.u-break2-bottom {
    margin-bottom: 20px;
}

@media screen and (min-width:48em) {
    .u-break2-bottom {
        margin-bottom: 60px;
    }
}

.u-break3-top {
    margin-top: 8px;
}

@media screen and (min-width:48em) {
    .u-break3-top {
        margin-top: 30px;
    }
}

.u-break3-bottom {
    margin-bottom: 15px;
}

@media screen and (min-width:48em) {
    .u-break3-bottom {
        margin-bottom: 30px;
    }
}

.u-bt1 {
    border-top: 1px solid #ccc;
    padding-top: 30px;
}

@media screen and (min-width:51.875em) {
    .u-bt1--sidebar {
        border-top: 1px solid #ddd;
        padding-top: 30px;
    }
}

.u-bb1 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 17px;
}

.u-bt2 {
    border-top: 1px solid #ccc;
    padding-top: 24px;
}

@media screen and (min-width:48em) {
    .u-bt2 {
        padding-top: 40px;
    }
}

.bg-blue {
    background-color: #08a7e7;
}

.bg-darkBlue {
    background-color: #006694;
}

.bg-darkGray {
    background-color: #3b3b3b;
}

.bg-medGray {
    background-color: #b0b0b0;
}

.bg-gray {
    background-color: #ccc;
}

.bg-lightGray {
    background-color: #f2f2f2;
}

.bg-lime {
    background-color: #a2b01f;
}

.bg-orange {
    background-color: #f39c23;
}

.bg-purple {
    background-color: #7e1c65;
}

.bg-red {
    background-color: #ed1c24;
}

.bg-teal {
    background-color: #04405b;
}

.bg-yellow {
    background-color: #f0b800;
}

.c-black {
    color: #454545;
}

.c-blue {
    color: #08a7e7;
}

.c-darkBlue {
    color: #006694;
}

.c-gray {
    color: #666;
}

.c-lightBlue {
    color: #adcad9;
}

.c-lightGray {
    color: #999;
}

.c-medGray {
    color: #777;
}

.c-orange {
    color: #f39c23;
}

.c-red {
    color: #ed1c24;
}

.c-yellow {
    color: #fbc303;
}

.AuthorBio {
    border-top: 1px solid #ccc;
    font-size: .882353em;
    line-height: 1.5;
    padding-top: 25px;
}

.AuthorBio-thumbnail {
    float: left;
}

    .AuthorBio-thumbnail img {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        display: block;
        margin-bottom: 15px;
        margin-right: 20px;
        max-height: 75px;
        max-width: 75px;
    }

@media screen and (min-width:30em) {
    .AuthorBio-content {
        overflow: hidden;
    }
}

.AuthorBio-name {
    font-weight: 600;
    color: #454545;
    font-size: 1.466667em;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: 5px;
}

.fonts-loaded .AuthorBio-name {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.AuthorBio-name-link, .AuthorBio-name-link:link {
    color: #454545;
}

    .AuthorBio-name-link:visited {
        color: #454545;
    }

    .AuthorBio-name-link:active, .AuthorBio-name-link:focus, .AuthorBio-name-link:hover {
        color: #12b5f7;
    }

.AuthorBio-title {
    margin-bottom: 5px;
}

.AuthorBio-twitter {
    display: inline-block;
    margin-bottom: 10px;
}

    .AuthorBio-twitter, .AuthorBio-twitter:link, .AuthorBio-twitter:visited {
        color: #454545;
    }

@media screen and (min-width:40em) {
    .AuthorBio-twitter, .AuthorBio-twitter:link, .AuthorBio-twitter:visited {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.AuthorBio-twitter:active, .AuthorBio-twitter:focus, .AuthorBio-twitter:hover {
    color: #12b5f7;
}

.AuthorBio-twitter svg {
    fill: #08a7e7;
    position: relative;
    top: 2px;
}

.AuthorBio-description {
    margin-bottom: 15px;
}

    .AuthorBio-description p {
        margin-bottom: 1em;
    }

    .AuthorBio-description a {
        font-weight: 600;
    }

.AuthorBio-more {
    display: inline-block;
    font-weight: 600;
}

.AuthorBio-disclaimer {
    border-top: 1px solid #ccc;
    color: #666;
    font-size: .866667em;
    font-style: italic;
    margin-top: 30px;
    padding-top: 15px;
}

@media screen and (min-width:51.875em) {
    .AuthorBio-jumpLink {
        display: none;
    }
}

.AuthorBio--aside {
    border-top: none;
    margin-top: 30px;
    padding-top: 0;
}

@media screen and (min-width:51.875em) {
    .AuthorBio--aside {
        margin-top: 0;
    }
}

.AuthorBio--aside .AuthorBio-thumbnail {
    float: none;
    width: 100px;
}

@media screen and (min-width:30em) {
    .AuthorBio--aside .AuthorBio-thumbnail {
        max-width: 143px;
        width: 20%;
    }
}

@media screen and (min-width:48em) {
    .AuthorBio--aside .AuthorBio-thumbnail {
        width: 143px;
    }
}

.AuthorBio--aside .AuthorBio-twitter {
    font-weight: 600;
}

    .AuthorBio--aside .AuthorBio-twitter, .AuthorBio--aside .AuthorBio-twitter:link {
        color: #08a7e7;
    }

        .AuthorBio--aside .AuthorBio-twitter:visited {
            color: #08a7e7;
        }

        .AuthorBio--aside .AuthorBio-twitter:active, .AuthorBio--aside .AuthorBio-twitter:focus, .AuthorBio--aside .AuthorBio-twitter:hover {
            color: #078ac0;
        }

.BlogBillboard {
    background-color: #f0b800;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 26px 0 24px;
}

@media screen and (min-width:30em) {
    .BlogBillboard {
        padding: 30px 0 20px;
    }
}

@media screen and (min-width:48em) {
    .BlogBillboard {
        padding-bottom: 80px;
    }
}

.BlogBillboard .Breadcrumbs {
    display: none;
    margin-bottom: 10px;
}

@media screen and (min-width:30em) {
    .BlogBillboard .Breadcrumbs {
        display: block;
    }
}

.BlogBillboard .Breadcrumbs-item:after {
    color: #fff;
}

.BlogBillboard .Breadcrumbs-link, .BlogBillboard .Breadcrumbs-link:link {
    color: #fff;
}

    .BlogBillboard .Breadcrumbs-link:visited {
        color: #fff;
    }

    .BlogBillboard .Breadcrumbs-link:active, .BlogBillboard .Breadcrumbs-link:focus, .BlogBillboard .Breadcrumbs-link:hover {
        color: #12b5f7;
    }

.BlogBillboard-content {
    font-weight: 400;
}

.fonts-loaded .BlogBillboard-content {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:69.375em) {
    .BlogBillboard-content {
        padding-left: 0;
        padding-right: 0;
    }
}

.BlogBillboard-title {
    color: #fff;
    font-size: 1.529412em;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 1.25;
    text-transform: uppercase;
}

@media screen and (min-width:30em) {
    .BlogBillboard-title {
        font-size: 200%;
    }
}

@media screen and (min-width:33.5625em) {
    .BlogBillboard-title {
        font-size: 211.764706%;
    }
}

@media screen and (min-width:37.125em) {
    .BlogBillboard-title {
        font-size: 223.529412%;
    }
}

@media screen and (min-width:40.6875em) {
    .BlogBillboard-title {
        font-size: 235.294118%;
    }
}

@media screen and (min-width:44.25em) {
    .BlogBillboard-title {
        font-size: 247.058824%;
    }
}

@media screen and (min-width:47.8125em) {
    .BlogBillboard-title {
        font-size: 258.823529%;
    }
}

.BlogBillboard-title-link, .BlogBillboard-title-link:link {
    color: #fff;
}

    .BlogBillboard-title-link:visited {
        color: #fff;
    }

    .BlogBillboard-title-link:active, .BlogBillboard-title-link:focus, .BlogBillboard-title-link:hover {
        color: rgba(255,255,255,.9);
    }

.BlogBillboard-title b {
    font-weight: 700;
}

@media screen and (min-width:48em) {
    .BlogBillboard--large {
        padding-top: 40px;
    }
}

@media screen and (min-width:48em) {
    .BlogBillboard--large .BlogBillboard-title {
        font-size: 294.117647%;
    }
}

@media screen and (min-width:51.1875em) {
    .BlogBillboard--large .BlogBillboard-title {
        font-size: 311.764706%;
    }
}

@media screen and (min-width:54.375em) {
    .BlogBillboard--large .BlogBillboard-title {
        font-size: 329.411765%;
    }
}

@media screen and (min-width:57.5625em) {
    .BlogBillboard--large .BlogBillboard-title {
        font-size: 347.058824%;
    }
}

@media screen and (min-width:60.75em) {
    .BlogBillboard--large .BlogBillboard-title {
        font-size: 364.705882%;
    }
}

@media screen and (min-width:51.875em) {
    .FeaturedPost {
        border-bottom: 1px solid #ccc;
        margin-bottom: 22px;
        padding-bottom: 40px;
    }
}

.BlogNav {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    overflow: hidden;
    position: relative;
}

@media print {
    .BlogNav {
        display: none !important;
    }
}

@media screen and (min-width:48em) {
    .BlogNav:before {
        background-color: #ccc;
        bottom: 15px;
        content: '\0020';
        position: absolute;
        left: 50%;
        top: 15px;
        width: 1px;
    }
}

.BlogNav-link {
    display: block;
    padding-bottom: 20px;
    padding-top: 20px;
}

    .BlogNav-link:after, .BlogNav-link:before {
        content: " ";
        display: table;
    }

    .BlogNav-link:after {
        clear: both;
    }

    .BlogNav-link:first-child {
        margin-top: 0;
    }

@media screen and (min-width:48em) {
    .BlogNav-link {
        margin-top: 0;
        width: 50%;
    }
}

.BlogNav-link:active .BlogNav-title, .BlogNav-link:focus .BlogNav-title, .BlogNav-link:hover .BlogNav-title {
    color: #08a7e7;
}

.BlogNav-link + .BlogNav-link {
    border-top: 1px solid #ccc;
}

@media screen and (min-width:48em) {
    .BlogNav-link + .BlogNav-link {
        border-top: none;
    }
}

.BlogNav-next, .BlogNav-prev {
    background-repeat: no-repeat;
    -webkit-background-size: auto 56px;
    background-size: auto 56px;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

@media screen and (min-width:52.5em) {
    .BlogNav-next, .BlogNav-prev {
        -webkit-background-size: auto 66px;
        background-size: auto 66px;
    }
}

@media screen and (min-width:48em) {
    .BlogNav-next {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20100%20100%22%20width=%22100%22%20height=%22100%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M59.2%2050L34.5%2092.9c-0.9%201.5-0.4%203.5%201.2%204.3%201.5%200.9%203.5%200.4%204.3-1.2l25.5-44.3c0.3-0.5%200.4-1.1%200.4-1.7%200%200%200%200%200-0.1%200%200%200%200%200-0.1%200-0.6-0.1-1.2-0.4-1.7L40%203.9c-0.6-1-1.7-1.6-2.8-1.6%20-0.5%200-1.1%200.1-1.6%200.4%20-1.5%200.9-2%202.8-1.2%204.3L59.2%2050z%22%2F%3E%3C%2Fsvg%3E);
        background-position: 100% center;
        background-position: right -17px center;
        float: right;
        padding-left: 25px;
        padding-right: 50px;
    }

        .BlogNav-next:active, .BlogNav-next:focus, .BlogNav-next:hover {
            background-position: 102% center;
            background-position: right -22px center;
        }
}

@media screen and (min-width:52.5em) {
    .BlogNav-next {
        padding-left: 35px;
        padding-right: 60px;
    }
}

@media screen and (min-width:48em) {
    .BlogNav-prev {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20100%20100%22%20width=%22100%22%20height=%22100%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M40.8%2050L65.5%207.1c0.9-1.5%200.4-3.5-1.2-4.3%20-1.5-0.9-3.5-0.4-4.3%201.2L34.5%2048.2c-0.3%200.5-0.4%201.1-0.4%201.7%200%200%200%200%200%200.1%200%200%200%200%200%200.1%200%200.6%200.1%201.2%200.4%201.7L60%2096.1c0.6%201%201.7%201.6%202.8%201.6%200.5%200%201.1-0.1%201.6-0.4%201.5-0.9%202-2.8%201.2-4.3L40.8%2050z%22%2F%3E%3C%2Fsvg%3E);
        background-position: left center;
        background-position: left -17px center;
        float: left;
        padding-left: 50px;
        padding-right: 25px;
    }

        .BlogNav-prev:active, .BlogNav-prev:focus, .BlogNav-prev:hover {
            background-position: -2% center;
            background-position: left -22px center;
        }
}

@media screen and (min-width:48em) and (min-width:52.5em) {
    .BlogNav-prev {
        padding-left: 60px;
        padding-right: 35px;
    }
}

.BlogNav-thumbnail {
    float: left;
}

    .BlogNav-thumbnail img {
        display: block;
        margin-right: 15px;
        width: 75px;
    }

@media screen and (min-width:48em) {
    .BlogNav-thumbnail img {
        width: 100px;
    }
}

.BlogNav-content {
    overflow: hidden;
}

.BlogNav-label {
    color: #999;
    font-size: .764706em;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.BlogNav-title {
    color: #454545;
    font-weight: 600;
    font-size: .941176em;
    line-height: 1.333;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.fonts-loaded .BlogNav-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .BlogNav-title {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:25em) {
    .BlogNav-title {
        font-size: 100%;
    }
}

@media screen and (min-width:30em) {
    .BlogNav-title {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:48em) {
    .BlogNav-title {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:50.25em) {
    .BlogNav-title {
        font-size: 100%;
    }
}

@media screen and (min-width:52.5em) {
    .BlogNav-title {
        font-size: 105.882353%;
    }
}

.BlogNav--fixed {
    height: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 50%;
    -webkit-transition: visibility 0s linear 250ms,opacity 250ms;
    transition: visibility 0s linear 250ms,opacity 250ms;
    visibility: hidden;
    width: 100%;
    z-index: -1;
    -webkit-backface-visibility: hidden;
}

@media screen and (min-width:73.75em) {
    .BlogNav--fixed.is-visible {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s linear 0s,opacity 250ms;
        transition: visibility 0s linear 0s,opacity 250ms;
        z-index: 400;
    }

    .BlogNav--fixed.is-hidden {
        opacity: 0;
        -webkit-transition: visibility 0s linear 250ms,opacity 250ms;
        transition: visibility 0s linear 250ms,opacity 250ms;
        visibility: hidden;
    }
}

.BlogNav--fixed img {
    width: 100px;
}

.BlogNav--fixed .BlogNav-link {
    background-color: #fff;
    -webkit-background-size: auto 78px;
    background-size: auto 78px;
    border: 1px solid #ccc;
    padding: 20px;
    position: relative;
    width: 420px;
    -webkit-transition: color 150ms ease-in-out,-webkit-transform .4s cubic-bezier(.455,.030,.515,.955);
    transition: color 150ms ease-in-out,-webkit-transform .4s cubic-bezier(.455,.030,.515,.955);
    transition: transform .4s cubic-bezier(.455,.030,.515,.955),color 150ms ease-in-out;
    transition: transform .4s cubic-bezier(.455,.030,.515,.955),color 150ms ease-in-out,-webkit-transform .4s cubic-bezier(.455,.030,.515,.955);
}

    .BlogNav--fixed .BlogNav-link:active, .BlogNav--fixed .BlogNav-link:focus, .BlogNav--fixed .BlogNav-link:hover {
        -webkit-transform: translateX(0) translateY(-50%);
        -ms-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    }

    .BlogNav--fixed .BlogNav-link:focus {
        border-color: #08a7e7;
    }

.BlogNav--fixed .BlogNav-prev {
    background-position: right -7px center;
    border-left: none;
    padding-right: 60px;
    -webkit-transform: translateX(-360px) translateY(-50%);
    -ms-transform: translateX(-360px) translateY(-50%);
    transform: translateX(-360px) translateY(-50%);
}

.BlogNav--fixed .BlogNav-next {
    background-position: left -7px center;
    border-right: none;
    padding-left: 60px;
    -webkit-transform: translateX(360px) translateY(-50%);
    -ms-transform: translateX(360px) translateY(-50%);
    transform: translateX(360px) translateY(-50%);
}

.Breadcrumbs {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #777;
    display: none;
    font-size: .764706em;
    margin-bottom: 15px;
}

    .Breadcrumbs:after, .Breadcrumbs:before {
        content: " ";
        display: table;
    }

    .Breadcrumbs:after {
        clear: both;
    }

@media screen and (min-width:30em) {
    .Breadcrumbs {
        display: block;
    }
}

.Breadcrumbs + .TitleBlock {
    margin-top: 10px;
}

@media screen and (min-width:30em) {
    .Breadcrumbs + .TitleBlock {
        margin-top: 0;
    }
}

.Breadcrumbs-item {
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 5px;
}

    .Breadcrumbs-item:after {
        color: rgba(102,102,102,.6);
        content: '\002F';
        font-size: .923077em;
        padding-left: .333333em;
    }

.Breadcrumbs-link, .Breadcrumbs-link:link {
    color: #777;
}

    .Breadcrumbs-link:visited {
        color: #777;
    }

    .Breadcrumbs-link:active, .Breadcrumbs-link:focus, .Breadcrumbs-link:hover {
        color: #12b5f7;
    }

@media print {
    .ButtonGroup {
        display: none !important;
    }
}

.ButtonGroup:after, .ButtonGroup:before {
    content: " ";
    display: table;
}

.ButtonGroup:after {
    clear: both;
}

.ButtonGroup--divider {
    border-top: 1px solid #ccc;
    margin-top: 12px;
    padding-top: 30px;
}

.ButtonGroup-item {
    margin-bottom: 20px;
    padding-bottom: 18px;
    padding-top: 18px;
    width: 100%;
}

    .ButtonGroup-item:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .ButtonGroup-item.Button--white {
        padding-bottom: 17px;
        padding-top: 17px;
    }

@media screen and (min-width:37em) {
    .ButtonGroup-item {
        float: left;
        margin-bottom: 0;
        margin-right: 4%;
        width: 48%;
    }
}

@media screen and (min-width:51.875em) {
    .ButtonGroup-item {
        float: none;
        margin-right: 15px;
        width: auto;
    }

    .ButtonGroup-item .ButtonGroup-item-inline {
        float: left;
        margin-right: 15px;
        width: auto;
    }
}

.CalloutBox {
    background-color: #f2f2f2;
    font-size: .823529em;
    padding: 20px 15px;
}

@media screen and (min-width:26.25em) {
    .CalloutBox {
        padding: 25px 20px;
    }
}

.CalloutBox-header {
    font-weight: 700;
    color: #454545;
    font-size: .823529rem;
    line-height: 1.285;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fonts-loaded .CalloutBox-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.CalloutBox li {
    margin-top: 10px;
}

    .CalloutBox li:first-child {
        margin-top: 0;
    }

.CalloutBox ol > li {
    padding-left: 20px;
    position: relative;
}

body {
    counter-reset: bullet-numbers-0;
}

.CalloutBox ol > li:before {
    color: #a2b01f;
    counter-increment: bullet-numbers-0;
    content: counter(bullet-numbers-0);
    font-size: .823529rem;
    font-weight: 600;
    left: 0;
    line-height: 1;
    position: absolute;
    top: .285714em;
}

.CalloutBox a, .CalloutBox a:link, .CalloutBox a:visited {
    color: #454545;
}

@media screen and (min-width:40em) {
    .CalloutBox a, .CalloutBox a:link, .CalloutBox a:visited {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.CalloutBox a:active, .CalloutBox a:focus, .CalloutBox a:hover {
    color: #12b5f7;
}

.js-carousel {
    display: none;
    position: relative;
}

@media print {
    .js-carousel {
        display: none !important;
    }
}

.no-js .js-carousel {
    display: block;
}

@media print {
    .slick {
        display: none !important;
    }
}

.slick-initialized {
    display: block;
}

.slick-next, .slick-prev {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: 9px auto;
    background-size: 9px auto;
    -webkit-border-radius: 32px;
    border-radius: 32px;
    -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
    box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
    font-size: 0;
    height: 32px;
    margin-top: -15px;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: background-color 150ms ease-in-out;
    transition: background-color 150ms ease-in-out;
    width: 32px;
}

    .slick-next:focus, .slick-prev:focus {
        background-color: #fff;
    }

    .slick-next:hover, .slick-prev:hover {
        background-color: #08a7e7;
    }

    .slick-next.slick-disabled, .slick-prev.slick-disabled {
        background-color: #f2f2f2;
        cursor: not-allowed !important;
    }

.slick-next {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M0.5%203c-0.7-0.7-0.7-1.8%200-2.4%200.7-0.7%201.8-0.7%202.4%200l9.6%209.8c0.7%200.7%200.7%201.8%200%202.4l-9.6%209.8c-0.7%200.7-1.8%200.7-2.4%200%20-0.7-0.7-0.7-1.8%200-2.4l7.9-8.5L0.5%203z%22%2F%3E%3C%2Fsvg%3E);
    right: -32px;
}

    .slick-next:hover {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M0.5%203c-0.7-0.7-0.7-1.8%200-2.4%200.7-0.7%201.8-0.7%202.4%200l9.6%209.8c0.7%200.7%200.7%201.8%200%202.4l-9.6%209.8c-0.7%200.7-1.8%200.7-2.4%200%20-0.7-0.7-0.7-1.8%200-2.4l7.9-8.5L0.5%203z%22%2F%3E%3C%2Fsvg%3E);
    }

    .slick-next.slick-disabled {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23ccc%22%3E%3Cpath%20d=%22M0.5%203c-0.7-0.7-0.7-1.8%200-2.4%200.7-0.7%201.8-0.7%202.4%200l9.6%209.8c0.7%200.7%200.7%201.8%200%202.4l-9.6%209.8c-0.7%200.7-1.8%200.7-2.4%200%20-0.7-0.7-0.7-1.8%200-2.4l7.9-8.5L0.5%203z%22%2F%3E%3C%2Fsvg%3E);
    }

.slick-prev {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M12.5%2020c0.7%200.7%200.7%201.8%200%202.4%20-0.7%200.7-1.8%200.7-2.4%200l-9.6-9.8c-0.7-0.7-0.7-1.8%200-2.4l9.6-9.8c0.7-0.7%201.8-0.7%202.4%200%200.7%200.7%200.7%201.8%200%202.4l-7.9%208.5L12.5%2020z%22%2F%3E%3C%2Fsvg%3E);
    left: -32px;
}

    .slick-prev:hover {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M12.5%2020c0.7%200.7%200.7%201.8%200%202.4%20-0.7%200.7-1.8%200.7-2.4%200l-9.6-9.8c-0.7-0.7-0.7-1.8%200-2.4l9.6-9.8c0.7-0.7%201.8-0.7%202.4%200%200.7%200.7%200.7%201.8%200%202.4l-7.9%208.5L12.5%2020z%22%2F%3E%3C%2Fsvg%3E);
    }

    .slick-prev.slick-disabled {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2013%2023%22%20width=%2213%22%20height=%2223%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23ccc%22%3E%3Cpath%20d=%22M12.5%2020c0.7%200.7%200.7%201.8%200%202.4%20-0.7%200.7-1.8%200.7-2.4%200l-9.6-9.8c-0.7-0.7-0.7-1.8%200-2.4l9.6-9.8c0.7-0.7%201.8-0.7%202.4%200%200.7%200.7%200.7%201.8%200%202.4l-7.9%208.5L12.5%2020z%22%2F%3E%3C%2Fsvg%3E);
    }

.slick-dots {
    margin-top: 25px;
    position: absolute;
    text-align: center;
    width: 100%;
}

    .slick-dots li {
        display: inline-block;
        margin: 0 10px;
    }

    .slick-dots button {
        background-color: #fff;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
        box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
        font-size: 0;
        float: left;
        height: 15px;
        padding: 0;
        width: 15px;
    }

        .slick-dots button:hover {
            background-color: #fff;
            -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,.2);
            box-shadow: 0 0 6px 0 rgba(0,0,0,.2);
        }

.slick-active button, .slick-active button:active, .slick-active button:focus, .slick-active button:hover, .slick-active button:link, .slick-active button:visited {
    background-color: #aaa;
}

.TopicsCarousel {
    overflow: hidden;
    position: relative;
    background-color: #f2f2f2;
    background-image: -webkit-gradient(linear,left bottom,left top,from(#dbdbdb),to(#fff));
    background-image: -webkit-linear-gradient(bottom,#dbdbdb,#fff);
    background-image: linear-gradient(to top,#dbdbdb,#fff);
    padding: 40px 50px;
    text-align: center;
}

@media print {
    .TopicsCarousel {
        display: none !important;
    }
}

.TopicsCarousel:before {
    background: -webkit-radial-gradient(center top ellipse,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
    background: radial-gradient(ellipse at center top,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
    content: '\0020';
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.TopicsCarousel-wrap {
    margin: 0 auto;
    max-width: 1160px;
}

.TopicsCarousel-header {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #454545;
    font-weight: 400;
    font-size: 1.176471em;
    line-height: 1.3;
    margin-bottom: 30px;
}

.fonts-loaded .TopicsCarousel-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .TopicsCarousel-header {
        font-size: 117.647059%;
    }
}

@media screen and (min-width:22.8125em) {
    .TopicsCarousel-header {
        font-size: 129.411765%;
    }
}

@media screen and (min-width:25.625em) {
    .TopicsCarousel-header {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:28.4375em) {
    .TopicsCarousel-header {
        font-size: 152.941176%;
    }
}

@media screen and (min-width:31.25em) {
    .TopicsCarousel-header {
        font-size: 164.705882%;
    }
}

.TopicsCarousel-item {
    padding-left: 20px;
    padding-right: 20px;
}

.TopicsCarousel-item-link {
    display: block;
}

    .TopicsCarousel-item-link:visited {
        color: #08a7e7;
    }

    .TopicsCarousel-item-link img {
        display: block;
        margin: 0 auto;
    }

.TopicsCarousel-item-text {
    font-weight: 600;
    font-size: .764706em;
    letter-spacing: 1px;
    line-height: 1;
    margin-top: 30px;
    text-transform: uppercase;
}

.fonts-loaded .TopicsCarousel-item-text {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.PhotoCarousel {
    clear: both;
    overflow: hidden;
    position: relative;
}

@media print {
    .PhotoCarousel {
        display: none !important;
    }
}

.PhotoCarousel-item-link {
    display: block;
}

    .PhotoCarousel-item-link img {
        display: block;
        width: 100%;
    }

.PhotoCarousel .slick-next, .PhotoCarousel .slick-prev {
    background-color: transparent;
    -webkit-background-size: 40px auto;
    background-size: 40px auto;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 100%;
    margin-top: 0;
    max-width: 100px;
    min-width: 60px;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    width: 12%;
}

    .PhotoCarousel .slick-next:hover, .PhotoCarousel .slick-prev:hover {
        background-color: rgba(0,0,0,.25);
    }

.PhotoCarousel .slick-next {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2020%2020%22%20width=%2220%22%20height=%2220%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M13.25%2010l-7.14-7.42c-0.27-0.27-0.27-0.71%200-0.98%200.27-0.27%200.7-0.27%200.97%200l7.83%207.91c0.27%200.27%200.27%200.71%200%200.98l-7.83%207.91c-0.27%200.27-0.7%200.27-0.97%200s-0.27-0.71%200-0.98l7.14-7.42z%22%2F%3E%3C%2Fsvg%3E);
    right: 0;
}

.PhotoCarousel .slick-prev {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2020%2020%22%20width=%2220%22%20height=%2220%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M13.89%2017.42c0.27%200.27%200.27%200.71%200%200.98s-0.7%200.27-0.97%200l-7.83-7.91c-0.27-0.27-0.27-0.71%200-0.98l7.83-7.91c0.27-0.27%200.7-0.27%200.97%200s0.27%200.71%200%200.98l-7.14%207.42%207.14%207.42z%22%2F%3E%3C%2Fsvg%3E);
    left: 0;
}

.ChapterList-item {
    overflow: hidden;
    border-top: 1px solid #ccc;
    margin-top: 30px;
    padding-top: 30px;
}

    .ChapterList-item:first-child {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
    }

.ChapterList-media {
    height: 200px;
    margin-bottom: 15px;
    width: 200px;
}

    .ChapterList-media iframe, .ChapterList-media iframe body, .ChapterList-media iframe html {
        overflow: hidden !important;
    }

@media screen and (min-width:33.75em) {
    .ChapterList-media {
        float: left;
        margin-bottom: 0;
        margin-right: 20px;
    }
}

.ChapterList-content {
    overflow: hidden;
}

.ChapterList-title {
    font-weight: 600;
    font-size: 1.294118em;
    line-height: 1.2;
    margin-bottom: 5px;
}

.fonts-loaded .ChapterList-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ChapterList-title a, .ChapterList-title a:link {
    //color: #454545;
    color: #006694;
}

/*.ChapterList-title a:visited {
    color: #454545;
}*/

    .ChapterList-title a:active, .ChapterList-title a:focus, .ChapterList-title a:hover {
        color: #12b5f7;
    }

.ChapterList-distance {
    color: #999;
    font-size: .882353em;
    font-style: italic;
    margin-bottom: 5px;
}

.ChapterList-row {
    overflow: hidden;
    font-size: .882353em;
    max-width: 600px;
}

.ChapterList-address {
    margin-bottom: 10px;
}

.ChapterList-row[data-eq-state~=bp-two-col] .ChapterList-address {
    float: left;
    margin-bottom: 0;
    padding-right: 2.5%;
    width: 47.5%;
}

.ChapterList-row[data-eq-state~=bp-two-col] .ChapterList-contact {
    float: left;
    padding-left: 2.5%;
    width: 47.5%;
}

@media print {
    .ChapterNav {
        display: none !important;
    }
}

.ChapterNav-toggle {
    font-weight: 600;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    background-color: #f2f2f2;
    display: block;
    font-size: .882353rem;
    text-shadow: none;
    text-transform: none;
    width: 100%;
}

.fonts-loaded .ChapterNav-toggle {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ChapterNav-toggle, .ChapterNav-toggle:link {
    color: #08a7e7;
}

    .ChapterNav-toggle:visited {
        color: #08a7e7;
    }

    .ChapterNav-toggle:active, .ChapterNav-toggle:focus, .ChapterNav-toggle:hover {
        color: #078ac0;
    }

    .ChapterNav-toggle:focus {
        background-color: #f2f2f2;
    }

    .ChapterNav-toggle:hover {
        background-color: #dedede;
    }

@media screen and (min-width:40em) {
    .ChapterNav-toggle {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@media screen and (min-width:51.875em) {
    .ChapterNav-toggle {
        display: none;
    }
}

.ChapterNav-toggle svg {
    fill: currentColor;
    margin-top: -2px;
    vertical-align: middle;
}

.ChapterNav-toggle-text:after {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right center;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
    background-repeat: no-repeat;
    content: '\0020';
    display: inline-block;
    height: 20px;
    margin-left: 4px;
    vertical-align: middle;
    width: 20px;
}

.ChapterNav-toggle.js-active .ChapterNav-toggle-text:after {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
}

@media screen and (min-width:51.875em) {
    .ChapterNav-toggle.js-active .ChapterNav-toggle-text:after {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
    }
}

.ChapterNav-content {
    background-color: #f2f2f2;
}

@media screen and (min-width:51.875em) {
    .ChapterNav-content {
        display: block !important;
    }
}

@media screen and (max-width:51.8125em) {
    .ChapterNav-item:first-child a {
        border-top: 1px solid #ccc;
    }
}

.ChapterNav-link {
    display: block;
    font-size: .882353em;
    padding: 12px 20px;
}

    .ChapterNav-link.ChapterNav-link {
        font-weight: 400;
    }

    .ChapterNav-link, .ChapterNav-link:active, .ChapterNav-link:focus, .ChapterNav-link:hover, .ChapterNav-link:link, .ChapterNav-link:visited {
        color: #454545;
    }

        .ChapterNav-link:active, .ChapterNav-link:focus, .ChapterNav-link:hover {
            background-color: #dedede;
        }

.ChapterNav-link--active {
    background-color: #fff;
    border: 1px solid #ccc;
}

    .ChapterNav-link--active:active, .ChapterNav-link--active:focus, .ChapterNav-link--active:hover {
        background-color: #fff;
    }

.CirclePromos:after, .CirclePromos:before {
    content: " ";
    display: table;
}

.CirclePromos:after {
    clear: both;
}

@media screen and (min-width:58.4375em) {
    .CirclePromos {
        text-align: center;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos {
        display: table;
        width: 100%;
    }
}

@media screen and (max-width:58.375em) {
    .CirclePromos-item {
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

        .CirclePromos-item:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-item {
        display: inline-block;
        margin: 0 10px;
        width: 280px;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-item {
        display: table-cell;
        padding-left: 20px;
        position: relative;
        width: auto;
    }
}

.CirclePromos-link {
    display: block;
}

    .CirclePromos-link svg, .CirclePromos-link:active svg, .CirclePromos-link:focus svg, .CirclePromos-link:hover svg, .CirclePromos-link:link svg, .CirclePromos-link:visited svg {
        fill: #fff;
    }

    .CirclePromos-link:active, .CirclePromos-link:focus, .CirclePromos-link:hover {
        text-decoration: none;
    }

@media screen and (max-width:58.375em) {
    .CirclePromos-link:active .CirclePromos-title, .CirclePromos-link:focus .CirclePromos-title, .CirclePromos-link:hover .CirclePromos-title {
        color: #08a7e7;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-link {
        display: block;
        position: relative;
    }

        .CirclePromos-link:before {
            content: "\0020";
            display: block;
        }

        .CirclePromos-link:before {
            padding-top: 100%;
        }

        .CirclePromos-link:after {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            content: '\0020';
            height: 100%;
            -webkit-transition: all 150ms ease-in-out;
            transition: all 150ms ease-in-out;
            width: 100%;
        }

        .CirclePromos-link:active:after, .CirclePromos-link:focus:after, .CirclePromos-link:hover:after {
            background-color: rgba(0,0,0,.1);
        }
}

.CirclePromos-wrap:after, .CirclePromos-wrap:before {
    content: " ";
    display: table;
}

.CirclePromos-wrap:after {
    clear: both;
}

@media screen and (max-width:58.375em) {
    .CirclePromos-wrap {
        background-color: transparent;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-wrap {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        padding: 15px 32px 27px;
        -webkit-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }
}

@media screen and (min-width:58.4375em) and (min-width:60.625em) {
    .CirclePromos-wrap {
        padding-top: 27px;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-wrap {
        padding-left: 35px;
        padding-right: 35px;
    }
}

.CirclePromos-thumbnail {
    height: 70px;
    margin-bottom: 20px;
    position: relative;
    width: 70px;
    z-index: 1;
}

    .CirclePromos-thumbnail svg {
        display: block;
    }

@media screen and (min-width:20em) {
    .CirclePromos-thumbnail {
        float: left;
        margin-bottom: 0;
    }
}

@media screen and (max-width:58.375em) {
    .CirclePromos-thumbnail {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        margin-right: 15px;
        padding: 10px;
    }

        .CirclePromos-thumbnail svg {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 100%;
        }

    .no-csstransforms .CirclePromos-thumbnail svg {
        top: auto;
        position: static;
    }
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-thumbnail {
        background-color: transparent;
        float: none;
        margin: 0 auto 10px;
    }
}

.CirclePromos-content {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.CirclePromos-title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #454545;
    font-size: 1.235294em;
    line-height: 1.2;
    margin-bottom: .294118rem;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.fonts-loaded .CirclePromos-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-title {
        color: #fff;
    }
}

@media screen and (min-width:58.4375em) and (min-width:58.4375em) {
    .CirclePromos-title {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:58.4375em) and (min-width:62.5em) {
    .CirclePromos-title {
        font-size: 123.529412%;
    }
}

.CirclePromos-description {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666;
    font-size: .882353em;
}

@media screen and (min-width:58.4375em) {
    .CirclePromos-description {
        color: #fff;
    }
}

.CommentPromo {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20111%2095%22%20width=%22111%22%20height=%2295%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23f2f2f2%22%3E%3Cpath%20d=%22M104.3%2060h0c4.7-6.2%207.3-13.3%207.2-21C111.1%2017%2087.8-0.4%2059.6%200.2%2031.3%200.7%208.8%2019%209.2%2041c0%200.4%200%200.8%200%201.2l1.4-0.9c0-0.1%207.5-4%207.5-4.1%200-16%2023.2-31.4%2041.5-31.4%2016.5%200%2044.7%209.5%2044.7%2033.2%200%207.2-4.1%2014.4-8.6%2020.1%20-3.7%205.8%200.7%2018.8%200.7%2018.8l-14.6-7.8c-3.8%201.2-9.7%201.8-11.4%202.2%20-2%204.6-5%207.2-5%207.2%205.2-0.4%209.1-1.2%2013.7-2.7l25.9%2011.1C105.1%2088%2096.3%2070.3%20104.3%2060zM35.5%2036.1C16.4%2035.7%200.8%2047.4%200.5%2062.2c-0.1%205.1%201.7%2010%204.8%2014.1H5.3c5.4%206.9-0.5%2018.9-0.5%2018.9l17.4-7.5c3.8%201.2%207.8%201.9%2012.1%202%2019%200.4%2034.7-11.3%2035-26.1C69.6%2048.7%2054.5%2036.5%2035.5%2036.1z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right -25px;
    background-repeat: no-repeat;
    -webkit-background-size: 190px auto;
    background-size: 190px auto;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 25px 20px 25px 0;
}

    .CommentPromo:after, .CommentPromo:before {
        content: " ";
        display: table;
    }

    .CommentPromo:after {
        clear: both;
    }

@media print {
    .CommentPromo {
        display: none !important;
    }
}

@media screen and (min-width:30em) {
    .CommentPromo {
        padding-right: 60px;
    }
}

.CommentPromo-text {
    font-weight: 400;
    color: #454545;
    font-size: 1.294118em;
    line-height: 1.36;
    margin-bottom: 15px;
    max-width: 450px;
}

.fonts-loaded .CommentPromo-text {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.CommentPromo-link {
    font-size: .882353em;
    font-weight: 600;
}

    .CommentPromo-link svg {
        fill: currentColor;
    }

.ContactInfo {
    font-size: .882353em;
}

    .ContactInfo p {
        margin-bottom: 10px;
    }

.ContactInfo-map {
    max-width: 240px;
}

.ContentColumns:after, .ContentColumns:before {
    content: " ";
    display: table;
}

.ContentColumns:after {
    clear: both;
}

@media screen and (min-width:48em) {
    .ContentColumns {
        margin-left: -40px;
    }
}

.ContentColumns-col {
    margin-top: 15px;
}

    .ContentColumns-col:first-child {
        margin-top: 0;
    }

@media screen and (min-width:48em) {
    .ContentColumns-col {
        float: left;
        padding-left: 40px;
        margin-top: 0;
        width: 50%;
    }
}

@media screen and (max-width:48em) {
    .ContentColumns--dividers .ContentColumns-col {
        border-top: 1px solid #ccc;
        margin-top: 8px;
        padding-top: 10px;
    }

        .ContentColumns--dividers .ContentColumns-col:first-child {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
}

.ContentGroup-header {
    font-weight: 700;
    color: #08a7e7;
    font-size: 1.294118rem;
    line-height: 1.36;
    margin-bottom: 30px;
    margin-top: 10px;
    text-align: center;
}

.fonts-loaded .ContentGroup-header {
    font-family: "Rock Salt","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:40em) {
    .ContentGroup-header {
        font-size: 129.411765%;
    }
}

@media screen and (min-width:42em) {
    .ContentGroup-header {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:44em) {
    .ContentGroup-header {
        font-size: 152.941176%;
    }
}

@media screen and (min-width:46em) {
    .ContentGroup-header {
        font-size: 164.705882%;
    }
}

@media screen and (min-width:48em) {
    .ContentGroup-header {
        font-size: 176.470588%;
    }
}

@media screen and (max-width:43.75em) {
    .ContentLists-title {
        margin-bottom: 0;
    }
}

.ContentList-item {
    border-bottom: 1px solid #ccc;
    margin-top: 26px;
    padding-bottom: 16px;
}

    .ContentList-item:after, .ContentList-item:before {
        content: " ";
        display: table;
    }

    .ContentList-item:after {
        clear: both;
    }

@media print {
    .ContentList-item {
        height: auto !important;
    }
}

.ContentList-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.ContentList-item:first-child {
    margin-top: 0;
    padding-top: 0;
}

    .ContentList-item:first-child .ContentList-featured {
        margin-top: 0;
    }

.ContentList--condensed .ContentList-item {
    margin-top: 13px;
    padding-bottom: 9px;
}

    .ContentList--condensed .ContentList-item:first-child {
        margin-top: 0;
    }

    .ContentList--condensed .ContentList-item:last-child {
        padding-bottom: 0;
    }

.ContentList-calendar, .ContentList-content, .ContentList-media {
    font-size: .882353em;
}

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-calendar, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-content, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-media {
    font-size: .941176em;
}

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-calendar, .ContentList[data-eq-state~=bp-promo-medium] .ContentList-content, .ContentList[data-eq-state~=bp-promo-medium] .ContentList-media {
    font-size: 1em;
}

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-calendar, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-content, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-media {
    font-size: 1em;
}

.ContentList-media {
    margin-bottom: 15px;
}

@media print {
    .ContentList-media {
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
    }
}

.ContentList-media a, .ContentList-media img {
    display: block;
}

.ContentList-media--square {
    float: left;
    margin-right: 1.235294rem;
    margin-top: .294118rem;
    width: 75px;
}

.ContentList[data-eq-state~=bp-two-col] .ContentList-media--square {
    width: 100px;
}

.ContentList-media--small {
    position: relative;
}

    .ContentList-media--small .ContentList-featured {
        bottom: 0;
        left: 0;
        margin: 0;
        position: absolute;
    }

.ContentList-media--full, .ContentList-media--large, .ContentList-media--medium {
    position: relative;
}

    .ContentList-media--full img, .ContentList-media--large img, .ContentList-media--medium img {
        width: 100%;
    }

    .ContentList-media--full .ContentList-featured, .ContentList-media--large .ContentList-featured, .ContentList-media--medium .ContentList-featured {
        bottom: 0;
        left: 0;
        margin: 0;
        position: absolute;
    }

.ContentList-item--cols .ContentList-media img {
    width: 100%;
}

@media screen and (min-width:28.125em) {
    .ContentList[data-eq-pts] .ContentList-item--cols .ContentList-media img {
        max-width: 260px;
    }

    .ContentList[data-eq-state] .ContentList-item--cols .ContentList-media img {
        max-width: none;
    }
}

.ContentList[data-eq-state~=bp-two-col] .ContentList-item--cols .ContentList-media--small {
    float: left;
    margin-right: 20px;
    max-width: 260px;
    width: 34%;
}

    .ContentList[data-eq-state~=bp-two-col] .ContentList-item--cols .ContentList-media--small .ContentList-featured {
        display: none;
    }

.ContentList[data-eq-state~=bp-two-col] .ContentList-item--cols .ContentList-media--medium {
    float: left;
    margin-right: 20px;
    width: 34%;
}

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-item--cols .ContentList-media--large {
    float: left;
    margin-right: 20px;
    width: 34%;
}

    .ContentList[data-eq-state~=bp-two-col-large] .ContentList-item--cols .ContentList-media--large .ContentList-featured {
        display: none;
    }

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-item--cols .ContentList-media--medium {
    max-width: 375px;
    width: 46%;
}

    .ContentList[data-eq-state~=bp-promo-medium] .ContentList-item--cols .ContentList-media--medium .ContentList-featured {
        display: none;
    }

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-item--cols .ContentList-media--large {
    max-width: 375px;
    width: 46%;
}

.ContentList[data-eq-state~=bp-promo-large] .ContentList-item--cols .ContentList-media--large {
    margin-right: -375px;
    max-width: none;
    padding-right: 395px;
    width: 100%;
}

.ContentList-content {
    clear: left;
    line-height: 1.5;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

@media screen and (min-width:18.75em) {
    .ContentList-content {
        clear: none;
    }
}

.ContentList[data-eq-state~=bp-two-col] .ContentList-content {
    overflow: hidden;
}

@media screen and (min-width:28.75em) {
    .ContentList-content {
        overflow: hidden;
    }
}

.ContentList-content--large, .ContentList-content--medium, .ContentList-content--small {
    overflow: hidden;
}

    .ContentList-content--large .ContentList-featured, .ContentList-content--medium .ContentList-featured, .ContentList-content--small .ContentList-featured {
        display: none;
    }

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-content--large .ContentList-featured, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-content--medium .ContentList-featured, .ContentList[data-eq-state~=bp-two-col-large] .ContentList-content--small .ContentList-featured {
    display: inline-block;
}

.ContentList--grid .ContentList-content {
    font-size: .882353rem;
    line-height: 1.46;
}

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-content {
    font-size: .882353rem;
}

.ContentList--rows .ContentList-content {
    font-size: .882353rem;
    line-height: 1.46;
}

.ContentList[data-eq-state~=bp-two-col-large] .ContentList-content {
    font-size: .882353rem;
}

.ContentList-title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.176471rem;
    line-height: 1.25;
    margin-bottom: .647059rem;
}

.fonts-loaded .ContentList-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:28.125em) {
    .ContentList-title {
        font-size: 1.294118rem;
        line-height: 1.27;
    }
}

.ContentList--grid .ContentList-title {
    font-size: 1.058824rem;
    line-height: 1.33;
}

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-title {
    font-size: 1.294118rem;
    line-height: 1.27;
}

.ContentList--grid[data-eq-state~=bp-promo-medium] .ContentList-title {
    font-size: 1.058824rem;
    line-height: 1.33;
}

.ContentList-content--large .ContentList-title {
    font-size: 1.529412rem;
}

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-content--large .ContentList-title {
    font-size: 1.647059rem;
}

.ContentList[data-eq-state~=bp-promo-medium] .ContentList-content--large .ContentList-title {
    font-size: 1.764706rem;
}

.ContentList-item--promo .ContentList-title {
    font-size: 1.294118rem;
}

.ContentList-title a, .ContentList-title a:link {
    color: #454545;
}

    .ContentList-title a:visited {
        color: #666;
    }

    .ContentList-title a:active, .ContentList-title a:focus, .ContentList-title a:hover {
        color: #12b5f7;
    }

.ContentList-featured, .ContentList-label {
    font-size: .764706rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
}

    .ContentList-featured, .ContentList-featured ~ .ContentList-label {
        display: inline-block;
        margin-bottom: 5px;
        padding: .470588rem 0 .411765rem;
    }

.ContentList-label {
    color: #999;
    margin-bottom: 12px;
}

@media screen and (min-width:43.75em) {
    .ContentList--grid .ContentList-label {
        padding-top: 25px;
    }
}

.ContentList-featured {
    background-color: #f39c23;
    color: #fff;
    margin-bottom: .941176rem;
    margin-right: .588235rem;
    padding-left: .705882rem;
    padding-right: .705882rem;
}

.ContentList-meta {
    color: #999;
}

.ContentList-meta-author a, .ContentList-meta-author a:link, .ContentList-meta-author a:visited {
    color: #454545;
}

@media screen and (min-width:40em) {
    .ContentList-meta-author a, .ContentList-meta-author a:link, .ContentList-meta-author a:visited {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.ContentList-meta-author a:active, .ContentList-meta-author a:focus, .ContentList-meta-author a:hover {
    color: #12b5f7;
}

.ContentList-meta-date:before {
    background-color: #ccc;
    content: '\0020';
    display: inline-block;
    height: 1em;
    margin-left: .25em;
    margin-right: .5em;
    position: relative;
    top: 2px;
    width: 1px;
}

.ContentList-meta-date:first-child:before {
    display: none;
}

.ContentList-calendar {
    font-weight: 600;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    background-color: #7e1c65;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    float: left;
    margin-bottom: 1em;
    margin-right: 1.235294rem;
    height: 60px;
    line-height: 1;
    padding-top: 3px;
    text-align: center;
    width: 60px;
}

.fonts-loaded .ContentList-calendar {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:31.25em) {
    .ContentList-calendar {
        height: 78px;
        padding-top: 3px;
        width: 78px;
    }
}

.ContentList-calendar-wrap {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.no-csstransforms .ContentList-calendar-wrap {
    top: auto;
    position: static;
}

.ContentList-calendar-month {
    display: block;
    font-size: .705882rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media screen and (min-width:31.25em) {
    .ContentList-calendar-month {
        font-size: .823529rem;
    }
}

.ContentList-calendar-date {
    display: block;
    font-size: 1.529412rem;
    margin-top: 0;
}

@media screen and (min-width:31.25em) {
    .ContentList-calendar-date {
        font-size: 1.882353rem;
        margin-top: 3px;
    }
}

.ContentList-eventInfo {
    margin-top: .647059rem;
}

@media screen and (min-width:31.25em) {
    .ContentList-eventInfo:first-child {
        margin-top: -1px;
    }
}

.ContentList-eventInfo-time:after {
    color: #b0b0b0;
    content: '\0020\007C\0020';
    display: inline-block;
    padding-left: .235294em;
    position: relative;
    top: -.058824em;
}

.ContentList-eventInfo-distance {
    color: #999;
    font-style: italic;
}

.ContentList-eventInfo-chapter {
    clear: left;
    margin-top: .647059rem;
}

.ContentList[data-eq-state~=bp-two-col] .ContentList-eventInfo-chapter {
    clear: none;
}

.ContentList-description {
    margin-top: .647059rem;
}

    .ContentList-description:first-child {
        margin-top: 0;
    }

.ContentList-cta {
    font-weight: 600;
    margin-top: .647059rem;
}

@media print {
    .ContentList-cta {
        display: none !important;
    }
}

.ContentList .Taglist {
    margin-top: .647059rem;
}

    .ContentList .Taglist:first-child {
        margin-top: 0;
    }

.ContentList--grid:after, .ContentList--grid:before {
    content: " ";
    display: table;
}

.ContentList--grid:after {
    clear: both;
}

.ContentList--grid .ContentList-item {
    margin-top: 17px;
    padding-bottom: 11px;
}

@media screen and (min-width:43.75em) {
    .ContentList--grid .ContentList-item {
        border-left: 1px solid #ccc;
        float: left;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        position: relative;
        width: 50%;
    }

        .ContentList--grid .ContentList-item:after {
            border-bottom: 1px solid #fff;
            bottom: -1px;
            content: '\0020';
            height: 0;
            left: 0;
            position: absolute;
            width: 10px;
            z-index: 1;
        }

    .no-js .ContentList--grid .ContentList-item:after {
        display: none;
    }

    .ContentList--grid .ContentList-item:first-child .ContentList-label {
        padding-top: 7px;
    }

    .ContentList--grid .ContentList-item:nth-child(2) {
        border-top: none;
        padding-top: 0;
    }

        .ContentList--grid .ContentList-item:nth-child(2) .ContentList-label {
            padding-top: 7px;
        }

    .ContentList--grid .ContentList-item:nth-last-child(2) {
        border-bottom: none;
    }

    .ContentList--grid .ContentList-item:nth-child(odd) {
        border-left: none;
        clear: left;
        padding-left: 0;
        padding-right: 20px;
    }

        .ContentList--grid .ContentList-item:nth-child(odd):after {
            left: auto;
            right: 0;
        }
}

@media screen and (min-width:43.75em) {
    .ContentList--grid .ContentList-media {
        margin-bottom: 20px;
    }
}

@media screen and (min-width:43.75em) {
    .ContentList--grid .ContentList-content {
        margin-bottom: 20px;
    }
}

.ContentList--rows:after, .ContentList--rows:before {
    content: " ";
    display: table;
}

.ContentList--rows:after {
    clear: both;
}

@media screen and (min-width:43.75em) {
    .ContentList--rows .ContentList-list {
        margin-left: -20px;
    }
}

.ContentList--rows .ContentList-item {
    border-bottom: none;
    margin-bottom: 25px;
    margin-top: 0;
    padding-bottom: 0;
}

@media screen and (min-width:43.75em) {
    .ContentList--rows .ContentList-item {
        float: left;
        padding-left: 20px;
        width: 50%;
    }

        .ContentList--rows .ContentList-item:nth-child(odd) {
            clear: left;
        }
}

@media screen and (min-width:43.75em) {
    .ContentList--rows .ContentList-media {
        margin-bottom: 20px;
    }
}

@media screen and (min-width:43.75em) {
    .ContentList--rows .ContentList-content {
        margin-bottom: 20px;
        max-width: 316px;
    }
}

.ContentList--rows .ContentList-description {
    margin-top: 0;
}

.ContentList-footer {
    border-top: 1px solid #ccc;
    font-size: 1.058824em;
    font-weight: 600;
    line-height: 1.2;
    padding-top: 13px;
}

@media print {
    .ContentList-footer {
        display: none !important;
    }
}

.ContentList-footer-cta {
    display: table;
    width: 100%;
}

.ContentList-footer-icon {
    display: table-cell;
    vertical-align: top;
    width: 27px;
}

.ContentList-footer svg {
    fill: currentColor;
}

.ContentList-footer-content {
    display: table-cell;
    overflow: hidden;
    padding-bottom: 5px;
    padding-left: 15px;
    vertical-align: middle;
}

    .ContentList-footer-content:first-child {
        padding-left: 0;
    }

.CustomPromo + .CustomPromo {
    margin-top: 2.941176rem;
}

.CustomPromo-content {
    overflow: hidden;
}

.CustomPromo-image {
    display: block;
    margin-bottom: 10px;
    width: 80px;
}

@media screen and (max-width:51.8125em) {
    .CustomPromo-image {
        float: left;
        margin-right: 20px;
    }
}

.CustomPromo-title {
    font-size: 1.058824rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .CustomPromo-title {
        font-size: 1.058824rem;
        line-height: 1.444;
    }
}

@media screen and (min-width:60em) {
    .CustomPromo-title {
        font-size: 1.176471rem;
        line-height: 1.3;
    }
}

.fonts-loaded .CustomPromo-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .CustomPromo-title {
    text-rendering: auto;
}

.CustomPromo-text {
    margin-bottom: 1.071429em;
}

.CustomPromo-link {
    font-size: .857143em;
}

select.u-customSelect {
    opacity: 0;
}

.no-cssanimations select.u-customSelect, .no-js select.u-customSelect {
    opacity: 1;
}

.js-customSelect {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

    .js-customSelect select {
        border: 0;
        color: #333;
        cursor: pointer;
        display: block;
        font-weight: 700;
        height: 2.411765rem;
        opacity: 0;
        position: relative;
        width: auto;
        z-index: 10;
    }

    .js-customSelect div {
        border: 0px;
        color: #333;
        font-size: 1em;
        font-weight: 700;
        height: 2.411765rem;
        left: 0;
        line-height: 2.294118rem;
        opacity: 0;
        overflow: hidden;
        padding: 0 1.8em 0 .5em;
        text-overflow: ellipsis;
        top: 0;
        width: 100%;
        z-index: 1;
    }

        .js-customSelect div:after {
            content: '\0020';
            display: block;
            margin-top: -.15em;
            position: absolute;
            top: 50%;
            right: .5em;
            height: 0;
            width: 0;
            border-left: .4em solid transparent;
            border-right: .4em solid transparent;
            border-top: .4em solid #08a7e7;
        }

        .js-customSelect div.focus {
            border-color: #08a7e7;
        }

            .js-customSelect div.focus:before {
                background-color: #08a7e7;
            }

    .js-customSelect.js-init .placeholder {
        opacity: 1;
        position: absolute;
        -webkit-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }

    .js-customSelect.disabled select {
        cursor: not-allowed;
    }

    .js-customSelect.disabled div {
        background-color: #f5f5f5;
        color: #999;
    }

        .js-customSelect.disabled div:after {
            height: 0;
            width: 0;
            border-left: .4em solid transparent;
            border-right: .4em solid transparent;
            border-top: .4em solid #ccc;
        }

    .js-customSelect.-block {
        display: block;
    }

        .js-customSelect.-block select {
            width: 100%;
        }

    .js-customSelect.-form1:active .placeholder, .js-customSelect.-form1:focus .placeholder, .js-customSelect.-form1:hover .placeholder {
        color: #08a7e7;
    }

    .js-customSelect.-form1 select {
        height: 2.705882rem;
    }

    .js-customSelect.-form1 div {
        color: #454545;
        background-color: #fff;
        font-size: .882353rem;
        font-weight: 400;
        height: 2.705882rem;
        line-height: 1.4;
    }

@media screen and (min-width:40em) {
    .js-customSelect.-form1 div {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.js-customSelect.-form1 div:after {
    height: 0;
    width: 0;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .5em solid #08a7e7;
    right: .882353em;
}

.js-customSelect.-form1 div:before {
    display: none;
}

@media print {
    .Disqus {
        display: none !important;
    }
}

.Downloads {
    background-color: #f2f2f2;
    display: none;
    margin: 0 auto 30px;
    padding: 15px;
}

@media print {
    .Downloads {
        display: none !important;
    }
}

@media screen and (min-width:33.75em) {
    .Downloads {
        display: block;
        float: right;
        margin-left: 20px;
        width: 200px;
    }
}

.Downloads-header {
    font-weight: 700;
    color: #454545;
    font-size: .823529rem;
    line-height: 1.285;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fonts-loaded .Downloads-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Downloads-item {
    font-size: .823529em;
    margin-top: 15px;
}

    .Downloads-item:first-child {
        margin-top: 0;
    }

.Downloads-link {
    display: block;
    font-weight: 600;
}

.Downloads-label {
    color: #666;
    display: block;
    font-size: .857143em;
    font-weight: 400;
    line-height: 1;
    margin-top: 10px;
    text-transform: uppercase;
}

    .Downloads-label svg {
        fill: #b5b5b5;
        margin-right: 3px;
        vertical-align: bottom;
    }

.Downloads-list {
    margin-bottom: 20px;
}

.ErrorMsg {
    margin-bottom: 80px;
    margin-top: 20px;
}

@media screen and (min-width:40em) {
    .ErrorMsg {
        text-align: center;
    }
}

@media screen and (min-width:48em) {
    .ErrorMsg {
        margin-bottom: 100px;
        margin-top: 60px;
    }
}

.ErrorMsg-title {
    font-weight: 400;
    text-rendering: optimizeLegibility;
    color: #454545;
    font-size: 1.352941rem;
    line-height: 1.2;
    margin-bottom: 20px;
}

.fonts-loaded .ErrorMsg-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .ErrorMsg-title {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .ErrorMsg-title {
        font-size: 1.411765rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .ErrorMsg-title {
        font-size: 1.588235rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .ErrorMsg-title {
        font-size: 1.764706rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .ErrorMsg-title {
        font-size: 1.941176rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .ErrorMsg-title {
        font-size: 2.117647rem;
        line-height: 1.2;
    }
}

@media screen and (min-width:40em) {
    .ErrorMsg-title {
        margin-bottom: 40px;
    }
}

.ErrorMsg-description {
    font-size: 1.058824rem;
    line-height: 1.4;
    margin-bottom: 30px;
}

@media screen and (min-width:20em) {
    .ErrorMsg-description {
        font-size: 1.058824rem;
    }
}

@media screen and (min-width:30em) {
    .ErrorMsg-description {
        font-size: 1.117647rem;
    }
}

@media screen and (min-width:40em) {
    .ErrorMsg-description {
        font-size: 1.176471rem;
    }
}

@media screen and (min-width:50em) {
    .ErrorMsg-description {
        font-size: 1.235294rem;
    }
}

@media screen and (min-width:60em) {
    .ErrorMsg-description {
        font-size: 1.294118rem;
    }
}

@media screen and (min-width:40em) {
    .ErrorMsg-description {
        margin-bottom: 40px;
    }
}

.ErrorMsg-description p {
    margin-bottom: 1em;
}

.ErrorMsg-description small {
    font-size: .882353rem;
}

.ErrorMsg-search {
    margin: 0 auto 30px;
    max-width: 540px;
}

@media screen and (min-width:40em) {
    .ErrorMsg-search {
        margin-bottom: 40px;
    }
}

.ErrorMsg-search .SearchBox--large .SearchBox-input[type=search] {
    font-size: .941176em;
}

.ErrorMsg--maintenance {
    margin: 0 auto 50px;
    max-width: 600px;
}

[data-whatinput=mouse] .Expandable-content, [data-whatinput=touch] .Expandable-content {
    outline: 0;
}

.js .Expandable-content {
    height: 0;
    overflow: hidden;
}

.Expandable-content.is-active {
    overflow: visible;
}

.Expandable.js-init .Expandable-content {
    display: none;
    height: auto;
}

.ExplainerSection .Component {
    margin-bottom: 30px;
    margin-top: 0;
}

@media screen and (min-width:48em) {
    .ExplainerSection .Component {
        border: 1px solid #ccc;
        clear: right;
        float: right;
        margin-left: 30px;
        padding: 15px;
        width: 225px;
    }
}

@media screen and (min-width:51.875em) {
    .ExplainerSection .Figure--left, .ExplainerSection .Figure--leftWide, .ExplainerSection .Figure--right, .ExplainerSection .Figure--rightWide {
        max-width: 300px;
    }
}

.ExplainerNav {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f2f2f2;
    -webkit-box-shadow: inset rgba(0,0,0,.09) 0 11px 13px -11px;
    box-shadow: inset rgba(0,0,0,.09) 0 11px 13px -11px;
    padding: 4px 15px 17px;
}

@media print {
    .ExplainerNav {
        display: none !important;
    }
}

.ExplainerNav:after, .ExplainerNav:before {
    content: " ";
    display: table;
}

.ExplainerNav:after {
    clear: both;
}

.ExplainerNav-share, .ExplainerNav-title, .ExplainerNav-toggle, .ExplainerNav-top {
    display: none;
}

.ExplainerNav:not(.is-stuck) .ExplainerNav-list {
    display: block !important;
}

.ExplainerNav-item {
    margin-top: 20px;
}

@media screen and (min-width:40em) {
    .ExplainerNav-item {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 13px;
    }
}

.ExplainerNav-link {
    padding-left: 33px;
    position: relative;
    display: block;
}

    .ExplainerNav-link, .ExplainerNav-link:link {
        color: #006694;
    }

        .ExplainerNav-link:visited {
            color: #006694;
        }

        .ExplainerNav-link:active, .ExplainerNav-link:focus, .ExplainerNav-link:hover {
            color: #12b5f7;
        }

body {
    counter-reset: bullet-numbers-0 bullet-numbers-1;
}

.ExplainerNav-link:before {
    background-color: #006694;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    counter-increment: bullet-numbers-1;
    content: counter(bullet-numbers-1);
    font-size: .705882rem;
    font-weight: 600;
    height: 1.916667em;
    left: 0;
    line-height: 1.926667em;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    width: 1.916667em;
}

.ExplainerNav-link:active:before, .ExplainerNav-link:focus:before, .ExplainerNav-link:hover:before {
    background-color: #08a7e7;
}

.ExplainerNav.is-stuck {
    background-color: #04405b;
    color: #fff;
    left: 0;
    right: 0;
    padding: 0 20px;
    position: relative;
    width: 100% !important;
    z-index: 600;
}

@media screen and (min-width:48em) {
    .ExplainerNav.is-stuck {
        padding: 10px 30px;
    }
}

.ExplainerNav.is-stuck > * {
    float: left;
    height: 40px;
    line-height: 40px;
}

.ExplainerNav.is-stuck a, .ExplainerNav.is-stuck a:link {
    color: #fff;
}

    .ExplainerNav.is-stuck a:visited {
        color: #fff;
    }

    .ExplainerNav.is-stuck a:active, .ExplainerNav.is-stuck a:focus, .ExplainerNav.is-stuck a:hover {
        color: #12b5f7;
    }

.ExplainerNav.is-stuck .ExplainerNav-toggle {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    font-weight: 400;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right center;
    background-repeat: no-repeat;
    -webkit-background-size: 18px auto;
    background-size: 18px auto;
    display: block;
    font-size: .882353em;
    height: 40px;
    line-height: 40px;
    padding-right: 26px;
    position: relative;
}

    .ExplainerNav.is-stuck .ExplainerNav-toggle:active, .ExplainerNav.is-stuck .ExplainerNav-toggle:focus, .ExplainerNav.is-stuck .ExplainerNav-toggle:hover {
        background-color: transparent;
    }

.fonts-loaded .ExplainerNav.is-stuck .ExplainerNav-toggle {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ExplainerNav.is-stuck .ExplainerNav-toggle, .ExplainerNav.is-stuck .ExplainerNav-toggle:link {
    color: #fff;
}

    .ExplainerNav.is-stuck .ExplainerNav-toggle:visited {
        color: #fff;
    }

    .ExplainerNav.is-stuck .ExplainerNav-toggle:active, .ExplainerNav.is-stuck .ExplainerNav-toggle:focus, .ExplainerNav.is-stuck .ExplainerNav-toggle:hover {
        color: #12b5f7;
    }

    .ExplainerNav.is-stuck .ExplainerNav-toggle:before {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
        background-position: right center;
        background-repeat: no-repeat;
        -webkit-background-size: 18px auto;
        background-size: 18px auto;
        content: '\0020';
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.no-inlinesvg .ExplainerNav.is-stuck .ExplainerNav-toggle:before {
    display: block;
    background-image: url(../images/icons/icon-sprite.png);
    background-position: 0 -340px;
    width: 17px;
    height: 22px;
}

.ExplainerNav.is-stuck .ExplainerNav-toggle.js-active {
    background-image: none;
}

    .ExplainerNav.is-stuck .ExplainerNav-toggle.js-active:before {
        display: block;
    }

.ExplainerNav.is-stuck .ExplainerNav-list {
    background-color: #006694;
    display: none;
    left: 0;
    position: absolute;
    min-width: 260px;
}

@media screen and (min-width:48em) {
    .ExplainerNav.is-stuck .ExplainerNav-list {
        margin-top: 10px;
    }
}

@media screen and (min-width:64em) {
    .ExplainerNav.is-stuck .ExplainerNav-list {
        left: -20px;
    }
}

.ExplainerNav.is-stuck .ExplainerNav-item {
    display: block;
    margin: 0;
}

    .ExplainerNav.is-stuck .ExplainerNav-item:first-child a {
        border-top: none;
    }

.ExplainerNav.is-stuck .ExplainerNav-link {
    background-color: #006694;
    border-top: 1px solid rgba(255,255,255,.25);
    display: block;
    line-height: 1.25;
    padding: 20px 15px;
    width: 100%;
}

body {
    counter-reset: bullet-numbers-0 bullet-numbers-1 bullet-numbers-2;
}

.ExplainerNav.is-stuck .ExplainerNav-link:before {
    counter-increment: bullet-numbers-2;
    content: "Part " counter(bullet-numbers-2);
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: currentColor;
    font-size: .823529em;
    height: auto;
    line-height: 1;
    position: static;
    text-align: left;
    width: auto;
}

.fonts-loaded .ExplainerNav.is-stuck .ExplainerNav-link:before {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ExplainerNav.is-stuck .ExplainerNav-link:before {
    color: #fff;
}

.ExplainerNav.is-stuck .ExplainerNav-link:active, .ExplainerNav.is-stuck .ExplainerNav-link:focus, .ExplainerNav.is-stuck .ExplainerNav-link:hover {
    background-color: rgba(4,64,91,.49);
}

    .ExplainerNav.is-stuck .ExplainerNav-link:active:before, .ExplainerNav.is-stuck .ExplainerNav-link:focus:before, .ExplainerNav.is-stuck .ExplainerNav-link:hover:before {
        color: #08a7e7;
    }

.ExplainerNav.is-stuck .ExplainerNav-utils {
    float: right;
}

.ExplainerNav.is-stuck .ExplainerNav-top {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20439%201024%22%20width=%22439%22%20height=%221024%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M437.1%20281.7q-5.1%2010.9-16.6%2010.9h-128v713.1q0%208-5.1%2013.1t-13.1%205.1h-109.7q-8%200-13.1-5.1t-5.1-13.1v-713.1h-128q-12%200-16.6-10.9t2.9-20l200-219.4q5.7-5.7%2013.1-5.7%208%200%2013.7%205.7l202.9%20219.4q7.4%209.1%202.9%2020z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right center;
    background-repeat: no-repeat;
    -webkit-background-size: auto 18px;
    background-size: auto 18px;
    display: block;
    float: left;
    font-size: .882353em;
    padding-right: 15px;
    vertical-align: middle;
}

@media screen and (min-width:27.5em) {
    .ExplainerNav.is-stuck .ExplainerNav-share {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
        background-image: none;
        cursor: pointer;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        text-align: left;
        text-shadow: none;
        text-transform: none;
        border-right: 1px solid rgba(255,255,255,.29);
        margin-right: 20px;
        padding-right: 20px;
        display: block;
        float: left;
        font-size: .882353em;
        line-height: 40px;
        vertical-align: middle;
    }

        .ExplainerNav.is-stuck .ExplainerNav-share:active, .ExplainerNav.is-stuck .ExplainerNav-share:focus, .ExplainerNav.is-stuck .ExplainerNav-share:hover {
            background-color: transparent;
        }

        .ExplainerNav.is-stuck .ExplainerNav-share svg {
            fill: #fff;
            margin-left: 5px;
            vertical-align: middle;
        }

        .ExplainerNav.is-stuck .ExplainerNav-share:active, .ExplainerNav.is-stuck .ExplainerNav-share:focus, .ExplainerNav.is-stuck .ExplainerNav-share:hover {
            color: #08a7e7;
        }
}

@media screen and (min-width:64em) {
    .ExplainerNav.is-stuck .ExplainerNav-menu {
        position: relative;
        z-index: 600;
    }

    .ExplainerNav.is-stuck .ExplainerNav-title {
        font-weight: 700;
        border-right: 1px solid rgba(255,255,255,.29);
        margin-right: 20px;
        padding-right: 20px;
        display: block;
        font-size: 1.058824em;
        vertical-align: middle;
    }

    .fonts-loaded .ExplainerNav.is-stuck .ExplainerNav-title {
        font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
    }
}

.ExplainerSection {
    border-top: 4px solid #ccc;
    margin-top: 40px;
    padding-top: 30px;
}

    .ExplainerSection:after, .ExplainerSection:before {
        content: " ";
        display: table;
    }

    .ExplainerSection:after {
        clear: both;
    }

@media screen and (min-width:60em) {
    .ExplainerSection {
        margin-top: 50px;
    }
}

.ExplainerSection-intro {
    font-size: 1.058824rem;
    margin-bottom: 15px;
    max-width: 820px;
}

@media screen and (min-width:20em) {
    .ExplainerSection-intro {
        font-size: 1.058824rem;
        line-height: 1.555;
    }
}

@media screen and (min-width:31em) {
    .ExplainerSection-intro {
        font-size: 1.117647rem;
        line-height: 1.57525;
    }
}

@media screen and (min-width:42em) {
    .ExplainerSection-intro {
        font-size: 1.176471rem;
        line-height: 1.5955;
    }
}

@media screen and (min-width:53em) {
    .ExplainerSection-intro {
        font-size: 1.235294rem;
        line-height: 1.61575;
    }
}

@media screen and (min-width:64em) {
    .ExplainerSection-intro {
        font-size: 1.294118rem;
        line-height: 1.636;
    }
}

@media screen and (min-width:48em) {
    .ExplainerSection-intro {
        margin-top: 20px;
    }
}

.ExplainerSection-header {
    padding-left: 50px;
    position: relative;
    font-weight: 600;
    color: #006694;
    font-size: 1.411765em;
    line-height: 1.385;
    margin-bottom: 22px;
}

body {
    counter-reset: bullet-numbers-0 bullet-numbers-1 bullet-numbers-2 bullet-numbers-3;
}

.ExplainerSection-header:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #006694;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    counter-increment: bullet-numbers-3;
    content: counter(bullet-numbers-3);
    font-size: 1.529412rem;
    font-weight: 600;
    height: 1.538462em;
    left: 0;
    line-height: 1.548462em;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    width: 1.538462em;
}

.fonts-loaded .ExplainerSection-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .ExplainerSection-header {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:34em) {
    .ExplainerSection-header {
        font-size: 147.058824%;
    }
}

@media screen and (min-width:48em) {
    .ExplainerSection-header {
        font-size: 152.941176%;
    }
}

.ExplainerSection-header:before {
    margin-top: -2px;
}

@media screen and (min-width:42.5em) {
    .ExplainerSection-wrap {
        padding-right: 5%;
    }
}

@media screen and (min-width:45em) {
    .ExplainerSection-wrap {
        padding-right: 10%;
    }
}

@media screen and (min-width:51.875em) {
    .ExplainerSection-wrap {
        padding-right: 20%;
    }
}

@media screen and (min-width:60em) {
    .ExplainerSection-wrap {
        padding-right: 30%;
    }
}

@media screen and (min-width:64em) {
    .ExplainerSection-wrap {
        padding-right: 35%;
    }
}

.ExplainerTitle .Breadcrumbs {
    margin-bottom: 12px;
    margin-top: -6px;
}

.ExplainerTitle-title {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #006694;
    font-size: 2em;
    line-height: 1.2;
    margin-bottom: 20px;
}

.fonts-loaded .ExplainerTitle-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .ExplainerTitle-title {
        font-size: 188.235294%;
        line-height: 1.2;
    }
}

@media screen and (min-width:26.625em) {
    .ExplainerTitle-title {
        font-size: 205.882353%;
        line-height: 1.191667;
    }
}

@media screen and (min-width:33.25em) {
    .ExplainerTitle-title {
        font-size: 223.529412%;
        line-height: 1.183333;
    }
}

@media screen and (min-width:39.875em) {
    .ExplainerTitle-title {
        font-size: 241.176471%;
        line-height: 1.175;
    }
}

@media screen and (min-width:46.5em) {
    .ExplainerTitle-title {
        font-size: 258.823529%;
        line-height: 1.166667;
    }
}

@media screen and (min-width:53.125em) {
    .ExplainerTitle-title {
        font-size: 276.470588%;
        line-height: 1.158333;
    }
}

@media screen and (min-width:59.75em) {
    .ExplainerTitle-title {
        font-size: 294.117647%;
        line-height: 1.15;
    }
}

.Figure {
    clear: both;
    margin: 30px auto;
}

    .Figure:after, .Figure:before {
        content: " ";
        display: table;
    }

    .Figure:after {
        clear: both;
    }

    .Figure .Figure {
        margin: 0;
    }

@media print {
    .Figure {
        max-width: 50%;
    }
}

.Figure:first-child {
    margin-top: 0;
}

.Figure + .Figure {
    margin-top: 30px;
}

.Figure-media img {
    display: block;
    width: 100%;
}

@media print {
    .Figure-media .fluid-width-video-wrapper, .Figure-media iframe {
        display: none !important;
    }
}

.Figure-caption {
    font-size: .764706rem;
    line-height: 1.5;
    margin-top: 15px;
}

.Figure-caption-label {
    text-transform: uppercase;
}

    .Figure-caption-label:before {
        content: '\005B';
        padding-right: 3px;
        vertical-align: text-bottom;
    }

    .Figure-caption-label:after {
        content: '\005D';
        padding-left: 3px;
        vertical-align: text-bottom;
    }

@media screen and (min-width:28.75em) {
    .Figure--center {
        max-width: none;
        width: 80%;
    }
}

@media screen and (min-width:32.5em) {
    .Figure--left, .Figure--leftWide, .Figure--right, .Figure--rightWide {
        margin-bottom: 1em;
        margin-top: 6px;
        width: 50%;
    }
}

@media screen and (min-width:32.5em) {
    .Figure--right, .Figure--rightWide {
        float: right;
        margin-left: 20px;
        padding-left: 10px;
    }
}

@media screen and (min-width:32.5em) {
    .Figure--left, .Figure--leftWide {
        float: left;
        margin-right: 20px;
        padding-right: 10px;
    }
}

@media screen and (min-width:36.875em) {
    .Figure--left, .Figure--right {
        max-width: 250px;
    }
}

@media screen and (min-width:51.875em) {
    .Figure--leftWide, .Figure--rightWide {
        min-width: 320px;
    }
}

.Figure--split {
    margin: 30px auto;
}

@media screen and (max-width:32.4375em) {
    .Figure--split {
        max-width: none;
    }
}

@media screen and (min-width:32.5em) {
    .Figure--split {
        margin-left: -20px;
    }
}

.Figure--split-col {
    margin-top: 30px;
}

    .Figure--split-col:first-child {
        margin-top: 0;
    }

@media screen and (min-width:32.5em) {
    .Figure--split-col {
        float: left;
        margin-top: 0;
        padding-left: 20px;
        width: 50%;
    }
}

.Figure--collage {
    margin: 30px auto;
}

@media screen and (max-width:32.4375em) {
    .Figure--collage {
        max-width: none;
    }
}

.Figure--collage img {
    display: block;
    width: 100%;
}

@media screen and (min-width:40.625em) {
    .Figure--collage-wrap {
        position: relative;
    }

        .Figure--collage-wrap .Figure-caption {
            display: none;
        }
}

@media screen and (min-width:40.625em) {
    .Figure--collage-primary {
        padding-right: 20px;
        width: 66.666667%;
    }

        .Figure--collage-primary img {
            width: 100%;
        }
}

.Figure--collage-secondary:after, .Figure--collage-secondary:before {
    content: " ";
    display: table;
}

.Figure--collage-secondary:after {
    clear: both;
}

@media screen and (min-width:32.5em) {
    .Figure--collage-secondary {
        margin-left: -20px;
    }
}

@media screen and (min-width:40.625em) {
    .Figure--collage-secondary {
        height: 100%;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 33.333333%;
    }
}

.Figure--collage-secondary-item {
    margin-top: 30px;
}

@media screen and (min-width:32.5em) {
    .Figure--collage-secondary-item {
        float: left;
        margin-bottom: 0;
        padding-left: 20px;
        width: 50%;
    }
}

@media screen and (min-width:40.625em) {
    .Figure--collage-secondary-item {
        float: none;
        height: 50%;
        margin-top: 0;
        padding-left: 0;
        padding-top: 10px;
        width: auto;
    }

        .Figure--collage-secondary-item:first-child {
            padding-bottom: 10px;
            padding-top: 0;
        }

        .Figure--collage-secondary-item img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

    .no-css_objectfit .Figure--collage-secondary-item img {
        height: 124px;
    }
}

.Figure--collage-footer {
    display: none;
    clear: both;
}

@media screen and (min-width:40.625em) {
    .Figure--collage-footer {
        display: block;
    }
}

.Filters {
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 20px;
}

@media print {
    .Filters {
        display: none !important;
    }
}

@media screen and (min-width:51.875em) {
    .Filters {
        background-color: transparent;
        border: none;
        padding: 0;
    }
}

.Filters-header {
    font-weight: 600;
    color: #454545;
    font-size: 1.176471em;
    line-height: 1.333;
    margin-bottom: 10px;
}

.fonts-loaded .Filters-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:28.125em) {
    .Filters-header {
        font-size: 1.294118em;
    }
}

.Filters-content:after, .Filters-content:before {
    content: " ";
    display: table;
}

.Filters-content:after {
    clear: both;
}

.Filters-list {
    border-top: 1px solid #ccc;
    padding-left: 15px;
    padding-right: 15px;
}

.Filters-item {
    border-bottom: 1px solid #ccc;
    position: relative;
}

    .Filters-item:last-child {
        border-bottom: none;
    }

    .Filters-item label {
        display: block;
        padding: 15px 15px 15px 40px;
    }

    .Filters-item input {
        left: 15px;
        margin-top: -6px;
        position: absolute;
        top: 50%;
    }

.Filters-reset {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    font-weight: 600;
    display: inline-block;
    margin-left: 20px;
    margin-top: 10px;
    text-decoration: underline;
}

    .Filters-reset:active, .Filters-reset:focus, .Filters-reset:hover {
        background-color: transparent;
    }

.fonts-loaded .Filters-reset {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Filters-reset, .Filters-reset:link {
    color: #08a7e7;
}

    .Filters-reset:visited {
        color: #08a7e7;
    }

    .Filters-reset:active, .Filters-reset:focus, .Filters-reset:hover {
        color: #078ac0;
    }

.Filters-section {
    background-color: #fff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

    .Filters-section .Expandable-content {
        font-size: .823529em;
    }

    .Filters-section .chosen-select {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
        background-position: 93% center;
        background-position: right 22px center;
        background-repeat: no-repeat;
        -webkit-background-size: 17px auto;
        background-size: 17px auto;
        font-size: .823529em !important;
        font-weight: 600;
        height: 50px;
        text-indent: 10px;
    }

        .Filters-section .chosen-select:before {
            background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
            background-position: 93% center;
            background-position: right 22px center;
            background-repeat: no-repeat;
            -webkit-background-size: 17px auto;
            background-size: 17px auto;
            content: '\0020';
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

.no-inlinesvg .Filters-section .chosen-select:before {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -54px -340px;
    width: 17px;
    height: 22px;
    display: block;
    left: auto;
    margin-top: -8px;
    position: absolute;
    right: 22px;
    top: 50%;
}

.Filters-section .chosen-select, .Filters-section .chosen-select:link {
    color: #08a7e7;
}

    .Filters-section .chosen-select:visited {
        color: #08a7e7;
    }

    .Filters-section .chosen-select:active, .Filters-section .chosen-select:focus, .Filters-section .chosen-select:hover {
        color: #078ac0;
    }

.Filters-section .chosen-container {
    font-size: .823529em;
    width: 100% !important;
}

.Filters-section .chosen-single {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 93% center;
    background-position: right 22px center;
    background-repeat: no-repeat;
    -webkit-background-size: 17px auto;
    background-size: 17px auto;
    padding: 15px;
}

    .Filters-section .chosen-single:before {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
        background-position: 93% center;
        background-position: right 22px center;
        background-repeat: no-repeat;
        -webkit-background-size: 17px auto;
        background-size: 17px auto;
        content: '\0020';
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.no-inlinesvg .Filters-section .chosen-single:before {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -54px -340px;
    width: 17px;
    height: 22px;
    display: block;
    left: auto;
    margin-top: -8px;
    position: absolute;
    right: 22px;
    top: 50%;
}

.Filters-section .chosen-with-drop .chosen-single {
    background-image: none;
}

    .Filters-section .chosen-with-drop .chosen-single:before {
        display: block;
    }

.Filters-toggle {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 93% center;
    background-position: right 22px center;
    background-repeat: no-repeat;
    -webkit-background-size: 17px auto;
    background-size: 17px auto;
    font-weight: 600;
    display: block;
    font-size: .823529em;
    letter-spacing: 0;
    line-height: 1.43;
    padding: 15px;
    position: relative;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    width: 100%;
}

    .Filters-toggle:before {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
        background-position: 93% center;
        background-position: right 22px center;
        background-repeat: no-repeat;
        -webkit-background-size: 17px auto;
        background-size: 17px auto;
        content: '\0020';
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.no-inlinesvg .Filters-toggle:before {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -54px -340px;
    width: 17px;
    height: 22px;
    display: block;
    left: auto;
    margin-top: -8px;
    position: absolute;
    right: 22px;
    top: 50%;
}

.fonts-loaded .Filters-toggle {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

button.Filters-toggle {
    background-color: #fff;
}

    button.Filters-toggle, button.Filters-toggle:link {
        color: #08a7e7;
    }

        button.Filters-toggle:visited {
            color: #08a7e7;
        }

        button.Filters-toggle:active, button.Filters-toggle:focus, button.Filters-toggle:hover {
            color: #078ac0;
        }

.Filters-toggle.js-active {
    background-image: none;
}

    .Filters-toggle.js-active:before {
        display: block;
    }

.FinderBillboard {
    background-position: center 20%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    padding-top: 40px;
    position: relative;
}

    .FinderBillboard .Breadcrumbs a, .FinderBillboard .Breadcrumbs a:active, .FinderBillboard .Breadcrumbs a:focus, .FinderBillboard .Breadcrumbs a:hover, .FinderBillboard .Breadcrumbs a:link, .FinderBillboard .Breadcrumbs a:visited {
        color: #fff;
    }

    .FinderBillboard .Breadcrumbs-item:after {
        color: #fff;
    }

    .FinderBillboard:before {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0,0,0,.49);
        content: '\0020';
        z-index: 1;
    }

@media print {
    .FinderBillboard:before {
        display: none !important;
    }
}

.FinderBillboard-wrap {
    position: relative;
    z-index: 2;
}

.FinderBillboard-title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.764706rem;
    font-weight: 700;
    color: #454545;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
    color: #fff;
}

@media screen and (min-width:20em) {
    .FinderBillboard-title {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:24.625em) {
    .FinderBillboard-title {
        font-size: 2.058824rem;
    }
}

@media screen and (min-width:29.25em) {
    .FinderBillboard-title {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:33.875em) {
    .FinderBillboard-title {
        font-size: 2.411765rem;
    }
}

@media screen and (min-width:38.5em) {
    .FinderBillboard-title {
        font-size: 2.588235rem;
    }
}

@media screen and (min-width:43.125em) {
    .FinderBillboard-title {
        font-size: 2.764706rem;
    }
}

@media screen and (min-width:47.75em) {
    .FinderBillboard-title {
        font-size: 2.941176rem;
    }
}

.fonts-loaded .FinderBillboard-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .FinderBillboard-title {
    text-rendering: auto;
}

@media screen and (min-width:48em) {
    .FinderBillboard-title {
        padding-bottom: 42px;
    }
}

.FinderBillboard-description {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.058824em;
}

@media screen and (min-width:20em) {
    .FinderBillboard-description {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:22.5em) {
    .FinderBillboard-description {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:25em) {
    .FinderBillboard-description {
        font-size: 117.647059%;
    }
}

@media screen and (min-width:27.5em) {
    .FinderBillboard-description {
        font-size: 123.529412%;
    }
}

@media screen and (min-width:30em) {
    .FinderBillboard-description {
        font-size: 129.411765%;
    }
}

.FinderBillboard-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
}

@media print {
    .FinderBillboard-form {
        display: none !important;
    }
}

@media screen and (min-width:61.875em) {
    .FinderBillboard-form {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.FinderBillboard-form-wrap {
    padding: 30px 20px 12px;
}

    .FinderBillboard-form-wrap:after, .FinderBillboard-form-wrap:before {
        content: " ";
        display: table;
    }

    .FinderBillboard-form-wrap:after {
        clear: both;
    }

@media screen and (min-width:38.125em) {
    .FinderBillboard-form-wrap {
        padding-bottom: 30px;
    }
}

@media screen and (min-width:48em) {
    .FinderBillboard-form-wrap {
        background-color: rgba(69,69,69,.8);
        display: table;
        width: 100%;
    }
}

.FinderBillboard-form-wrap .placeholder, .FinderBillboard-form-wrap input {
    border: 1px solid #ccc;
    font-size: .882353em;
    padding: 12px;
}

    .FinderBillboard-form-wrap input[type=submit] {
        border: none;
        padding-left: 30px;
        padding-right: 30px;
    }

.FinderBillboard-form-zip {
    color: #454545;
}

@media screen and (min-width:40em) {
    .FinderBillboard-form-zip {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.FinderBillboard-form-zip input {
    height: 46px;
}

@media screen and (min-width:25em) {
    .FinderBillboard-form-miles, .FinderBillboard-form-zip {
        float: left;
        padding-left: 15px;
        width: 50%;
    }
}

.FinderBillboard-form-miles {
    border: 0px;
}

.FinderBillboard-form-miles, .FinderBillboard-form-submit, .FinderBillboard-form-zip {
    margin-bottom: 20px;
}

@media screen and (min-width:38.125em) {
    .FinderBillboard-form-miles, .FinderBillboard-form-submit, .FinderBillboard-form-zip {
        float: left;
        margin-bottom: 0;
        padding-left: 15px;
        width: 33.333333%;
    }
}

.FinderBillboard-form-zip {
    padding-left: 0;
}

@media screen and (min-width:48em) {
    .FinderBillboard-form-miles, .FinderBillboard-form-state, .FinderBillboard-form-submit, .FinderBillboard-form-text, .FinderBillboard-form-zip {
        display: table-cell;
        float: none;
        padding-left: 20px;
        width: 28%;
        vertical-align: top;
    }
}

@media screen and (min-width:48em) {
    .FinderBillboard-form-state {
        padding-left: 0;
    }
}

.FinderBillboard-form-miles .chosen-select,
.FinderBillboard-form-state .chosen-select {
    height: 46px;
    text-indent: 10px;
}

.FinderBillboard-form-miles .chosen-container,
.FinderBillboard-form-state .chosen-container {
    color: #454545;
    font-size: .882353em;
    width: 100% !important;
}

@media screen and (min-width:40em) {
    .FinderBillboard-form-miles .chosen-container,
    .FinderBillboard-form-state .chosen-container {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.FinderBillboard-form-miles .chosen-container-single:after,
.FinderBillboard-form-state .chosen-container-single:after {
    height: 0;
    width: 0;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .5em solid #08a7e7;
    content: '\0020';
    display: block;
    margin-top: -.15em;
    position: absolute;
    top: 50%;
    right: .882353em;
}

.FinderBillboard-form-miles .chosen-container-single .chosen-single,
.FinderBillboard-form-state .chosen-container-single .chosen-single {
    color: #454545;
    font-weight: 400;
    padding: 12px;
}

@media screen and (min-width:40em) {
    .FinderBillboard-form-miles .chosen-container-single .chosen-single,
    .FinderBillboard-form-state .chosen-container-single .chosen-single {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.FinderBillboard-form-miles .chosen-container-single .chosen-single:active, .FinderBillboard-form-miles .chosen-container-single .chosen-single:focus, .FinderBillboard-form-miles .chosen-container-single .chosen-single:hover,
.FinderBillboard-form-state .chosen-container-single .chosen-single:active, .FinderBillboard-form-state .chosen-container-single .chosen-single:focus, .FinderBillboard-form-state .chosen-container-single .chosen-single:hover {
    color: #08a7e7;
}

.FinderBillboard-form-miles .chosen-container.chosen-with-drop .chosen-drop,
.FinderBillboard-form-state .chosen-container.chosen-with-drop .chosen-drop {
    left: 0;
    right: 0;
}

//TODO: If specific chosenjs configuration options can be set for this specific dropdown, then these styles could (probably) be removed
.FinderBillboard-form-miles {
    .chosen-container .chosen-drop {
        .chosen-search {
            display: none;
        }

        .chosen-results {
            height: auto;
        }
    }
}

.FinderBillboard-form-text {
    font-size: 1.294118em;
    margin: 7px 0 12px;
    text-align: center;
    vertical-align: middle;
}

@media screen and (min-width:48em) {
    .FinderBillboard-form-text {
        margin: 0;
        width: 60px;
    }
}

.FinderBillboard-form-submit .Button {
    width: 100%;
}

@media screen and (min-width:38.125em) {
    .FinderBillboard-form-submit .Button {
        padding-bottom: 16px;
        padding-top: 16px;
    }
}

.FinderBillboard-form-submit .Button svg {
    margin-top: -1px;
    vertical-align: middle;
}

.FinderBillboard--full {
    padding: 0;
}

@media screen and (min-width:48em) {
    .FinderBillboard--full {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}

@media screen and (min-width:48em) and (min-height:43.75em) {
    .FinderBillboard--full {
        height: 100vh;
    }
}

@media all and (device-width:768px) and (device-height:1024px) and (orientation:portrait) {
    .FinderBillboard--full {
        height: 860px;
    }
}

@media all and (device-width:768px) and (device-height:1024px) and (orientation:landscape) {
    .FinderBillboard--full {
        height: 645px;
    }
}

.FinderBillboard--full .FinderBillboard-wrap {
    background-color: rgba(69,69,69,.8);
    margin: 0 auto;
    padding: 40px 15px;
}

@media screen and (min-width:26.25em) {
    .FinderBillboard--full .FinderBillboard-wrap {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (min-width:48em) {
    .FinderBillboard--full .FinderBillboard-wrap {
        max-width: 800px;
        padding-left: 40px;
        padding-right: 40px;
        width: 90%;
    }
}

@media screen and (min-width:48em) and (min-height:43.75em) {
    .FinderBillboard--full .FinderBillboard-wrap {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .no-csstransforms .FinderBillboard--full .FinderBillboard-wrap {
        top: auto;
        position: static;
    }
}

.FinderBillboard--full .FinderBillboard-wrap .u-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.FinderBillboard--full .FinderBillboard-title {
    margin-bottom: 20px;
}

.FinderBillboard--full .FinderBillboard-description {
    margin-bottom: 30px;
}

.FinderBillboard--full .FinderBillboard-form {
    padding: 0;
}

.FinderBillboard--full .FinderBillboard-form-wrap {
    background-color: transparent;
    padding: 0;
}

@media screen and (min-width:38.125em) {
    .FinderBillboard--full .FinderBillboard-form-miles, .FinderBillboard--full .FinderBillboard-form-zip {
        width: 50%;
    }
}

@media screen and (min-width:48em) {
    .FinderBillboard--full .FinderBillboard-form-miles, .FinderBillboard--full .FinderBillboard-form-zip {
        width: 30%;
    }
}

.FinderBillboard--full .FinderBillboard-form-submit {
    display: block;
    float: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
    width: 100%;
}

@media screen and (min-width:38.125em) {
    .FinderBillboard--full .FinderBillboard-form-submit {
        margin-top: 30px;
    }
}

.Footer {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #04405b;
    color: #adcad9;
    padding-bottom: 40px;
    padding-top: 40px;
}

.Footer-row {
    border-top: 1px solid rgba(255,255,255,.2);
    padding-top: 25px;
    text-align: center;
}

    .Footer-row:after, .Footer-row:before {
        content: " ";
        display: table;
    }

    .Footer-row:after {
        clear: both;
    }

@media screen and (min-width:64.25em) {
    .Footer-row .Footer-row {
        padding-left: 30px;
    }
}

.Footer-row--table {
    border-top: none;
    padding-bottom: 0;
    padding-top: 0;
}

@media screen and (min-width:64.25em) {
    .Footer-row--table {
        display: table;
        width: 100%;
    }
}

.Footer-primary {
    padding-bottom: 30px;
}

@media screen and (min-width:64.25em) {
    .Footer-primary {
        border-right: 1px solid rgba(255,255,255,.2);
        display: table-cell;
        padding-bottom: 0;
        padding-right: 50px;
        text-align: left;
        width: 235px;
        vertical-align: top;
    }
}

@media print {
    .Footer-primary {
        padding-bottom: 0;
    }
}

@media print {
    .Footer-secondary {
        display: none !important;
    }
}

.Footer-secondary .Footer-row:first-child {
    padding-bottom: 0;
}

@media screen and (min-width:64.25em) {
    .Footer-secondary {
        display: table-cell;
        vertical-align: top;
    }

        .Footer-secondary .Footer-row:first-child {
            border-top: none;
            padding-top: 0;
        }
}

.Footer-logo {
    display: inline-block;
}

@media print {
    .Footer-logo {
        display: none !important;
    }
}

.Footer-logo svg {
    max-width: 100%;
}

.Footer-info {
    border-top: 1px solid rgba(255,255,255,.2);
    margin-top: 25px;
    padding-top: 25px;
}

    .Footer-info:after, .Footer-info:before {
        content: " ";
        display: table;
    }

    .Footer-info:after {
        clear: both;
    }

@media screen and (min-width:64.25em) {
    .Footer-info {
        border: none;
        margin-top: 0;
        padding-top: 20px;
    }
}

@media print {
    .Footer-info {
        margin-top: 0;
        padding-top: 0;
    }
}

.Footer-contact {
    font-size: .823529em;
    margin-bottom: 20px;
}

    .Footer-contact .adr {
        margin-bottom: 1em;
    }

@media screen and (min-width:20em) {
    .Footer-contact {
        float: left;
        margin-bottom: 0;
        padding-right: 20px;
        text-align: left;
    }
}

@media screen and (min-width:64.25em) {
    .Footer-contact {
        float: none;
        margin-bottom: 20px;
        padding-right: 0;
    }
}

@media print {
    .Footer-logos {
        display: none !important;
    }
}

@media screen and (min-width:20em) {
    .Footer-logos {
        float: right;
    }
}

@media screen and (min-width:64.25em) {
    .Footer-logos {
        float: none;
    }
}

.Footer-logos-link {
    display: block;
    text-align: center;
}

@media screen and (min-width:64.25em) {
    .Footer-logos-link {
        text-align: left;
    }
}

.Footer-logos img {
    display: inline-block;
}

.Footer-donate {
    margin-bottom: 10px;
}

@media print {
    .Footer-donate {
        display: none !important;
    }
}

@media screen and (min-width:64.25em) {
    .Footer-donate {
        margin-bottom: 0;
        text-align: left;
    }
}

.Footer-donate-text {
    font-weight: 400;
    color: #fff;
    display: block;
    font-size: .882353em;
    letter-spacing: 3px;
    line-height: 2;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.fonts-loaded .Footer-donate-text {
    font-family: "Rock Salt","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:45em) {
    .Footer-donate-text {
        display: inline-block;
    }
}

.Footer-donate-link {
    font-size: .882353rem;
    margin: 0 auto 20px;
    max-width: 300px;
    text-align: center;
}

@media screen and (min-width:45em) {
    .Footer-donate-link {
        margin-left: 10px;
    }
}

.Footer-sitemap {
    font-size: .764706em;
    margin-bottom: 5px;
}

@media print {
    .Footer-sitemap {
        display: none !important;
    }
}

.Footer-sitemap:after, .Footer-sitemap:before {
    content: " ";
    display: table;
}

.Footer-sitemap:after {
    clear: both;
}

@media screen and (min-width:20.625em) {
    .Footer-sitemap {
        margin-left: -15px;
    }
}

.Footer-sitemap-item {
    margin-bottom: 20px;
}

@media screen and (min-width:20.625em) {
    .Footer-sitemap-item {
        float: left;
        padding-left: 15px;
        width: 50%;
    }
}

@media screen and (min-width:33.125em) {
    .Footer-sitemap-item {
        width: 33.333333%;
    }
}

@media screen and (min-width:64.25em) {
    .Footer-sitemap-item {
        text-align: left;
    }
    @media screen and (min-width: 64.25em) {
        .Footer-sitemap-item--wide {
            width: 50%
        }

        .Footer-sitemap-item.care {
            clear: left;
        }

        .Footer-sitemap-item--wide li {
            width: 50%
        }
        /*.Footer-sitemap-item--wide li:last-child {
        float: right
    }*/
        /*.Footer-sitemap-item--wide li:nth-child(6n+1) {
        float: right;
    }*/
    }
}

@media screen and (min-width:64.25em) {
    .Footer-sitemap-item {
        margin-bottom: 40px;
    }
}

@media screen and (min-width:64.25em) {
    .Footer-sitemap-item {
        margin-bottom: 30px;
        width: 25%;
    }
}

@media screen and (min-width:25em) {
    .Footer-sitemap-item--wide ul {
        margin-left: -15px;
    }

    .Footer-sitemap-item--wide li {
        float: left;
        padding-left: 15px;
        width: 50%;
    }
}

@media screen and (min-width:35em) and (max-width:64.1875em) {
    .Footer-sitemap-item--wide li {
        width: 33.333333%;
    }

        .Footer-sitemap-item--wide li:last-child {
            clear: left;
        }
}

@media screen and (min-width:56.25em) {
    .Footer-sitemap-item--wide li {
        width: 33.333333%;
    }

        .Footer-sitemap-item--wide li:last-child {
            float: left;
        }
}

@media screen and (min-width:64.25em) {
    .Footer-sitemap-item--wide {
        width: 50%;
    }
    
        .Footer-sitemap-item--wide li {
            width: 50%;
        }

            .Footer-sitemap-item--wide li:last-child {
                clear: left;
            }
}

.Footer-sitemap-header {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.fonts-loaded .Footer-sitemap-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Footer-sitemap-header, .Footer-sitemap-header:link {
    color: #08a7e7;
}

    .Footer-sitemap-header:visited {
        color: #08a7e7;
    }

    .Footer-sitemap-header:active, .Footer-sitemap-header:focus, .Footer-sitemap-header:hover {
        color: #078ac0;
    }

.Footer-sitemap-sublist {
    display: none;
}

    .Footer-sitemap-sublist:after, .Footer-sitemap-sublist:before {
        content: " ";
        display: table;
    }

    .Footer-sitemap-sublist:after {
        clear: both;
    }

@media screen and (min-width:64.25em) {
    .Footer-sitemap-sublist {
        display: block;
    }
}

.Footer-sitemap-subitem {
    line-height: 1.3;
    margin-top: .769231em;
}

    .Footer-sitemap-subitem a, .Footer-sitemap-subitem a:link {
        color: #adcad9;
    }

        .Footer-sitemap-subitem a:visited {
            color: #adcad9;
        }

        .Footer-sitemap-subitem a:active, .Footer-sitemap-subitem a:focus, .Footer-sitemap-subitem a:hover {
            color: #12b5f7;
        }

.Footer-newsletter {
    font-weight: 600;
    font-size: 1.058824em;
    margin-bottom: 20px;
}

.fonts-loaded .Footer-newsletter {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media print {
    .Footer-newsletter {
        display: none !important;
    }
}

@media screen and (min-width:35em) {
    .Footer-newsletter {
        float: left;
        margin-bottom: 0;
        margin-top: -5px;
        text-align: left;
    }
}

.Footer-newsletter-link, .Footer-newsletter-link:link {
    color: #fff;
}

    .Footer-newsletter-link:visited {
        color: #fff;
    }

    .Footer-newsletter-link:active, .Footer-newsletter-link:focus, .Footer-newsletter-link:hover {
        color: #12b5f7;
    }

    .Footer-newsletter-link svg {
        margin-left: .117647rem;
        vertical-align: middle;
    }

.Footer .SocialNetworks {
    float: none;
    margin-bottom: 25px;
}

@media print {
    .Footer .SocialNetworks {
        display: none !important;
    }
}

.Footer .SocialNetworks:after, .Footer .SocialNetworks:before {
    content: " ";
    display: table;
}

.Footer .SocialNetworks:after {
    clear: both;
}

@media screen and (min-width:35em) {
    .Footer .SocialNetworks {
        float: right;
    }
}

.Footer .SocialNetworks-list:after, .Footer .SocialNetworks-list:before {
    content: " ";
    display: table;
}

.Footer .SocialNetworks-list:after {
    clear: both;
}

.Footer .SocialNetworks-item {
    display: inline-block;
    float: none;
    margin-left: 15px;
}

@media screen and (min-width:35em) {
    .Footer .SocialNetworks-item {
        float: left;
    }
}

.Footer .SocialNetworks-link {
    display: block;
}

    .Footer .SocialNetworks-link, .Footer .SocialNetworks-link:link {
        color: #fff;
    }

        .Footer .SocialNetworks-link:visited {
            color: #fff;
        }

        .Footer .SocialNetworks-link:active, .Footer .SocialNetworks-link:focus, .Footer .SocialNetworks-link:hover {
            color: #12b5f7;
        }

.Footer .SocialNetworks svg {
    display: block;
    height: 22px;
    width: 22px;
}

.Footer-legal {
    font-size: 0;
}

@media print {
    .Footer-legal {
        display: none !important;
    }
}

.Footer-legal:after, .Footer-legal:before {
    content: " ";
    display: table;
}

.Footer-legal:after {
    clear: both;
}

@media screen and (min-width:36.25em) {
    .Footer-legal {
        text-align: left;
    }
}

.Footer-legal-item {
    display: inline-block;
    font-size: .764706rem;
    margin: 0 3.5% 15px;
}

@media screen and (min-width:36.25em) {
    .Footer-legal-item {
        float: left;
        margin-left: 0;
        margin-right: 0;
    }

        .Footer-legal-item:after {
            content: '\2022';
            display: inline-block;
            font-size: 1.153846em;
            margin-top: -2px;
            padding: 0 3.5%;
        }
}

@media screen and (min-width:36.25em) and (min-width:34.375em) {
    .Footer-legal-item:after {
        padding: 0 15px;
    }
}

.Footer-legal-item:last-child:after {
    display: none;
}

.Footer-legal a, .Footer-legal a:link {
    color: #adcad9;
}

    .Footer-legal a:visited {
        color: #adcad9;
    }

    .Footer-legal a:active, .Footer-legal a:focus, .Footer-legal a:hover {
        color: #12b5f7;
    }

.Footer--minimal {
    text-align: center;
}

    .Footer--minimal .Footer-top {
        position: relative;
    }

        .Footer--minimal .Footer-top:after, .Footer--minimal .Footer-top:before {
            content: " ";
            display: table;
        }

        .Footer--minimal .Footer-top:after {
            clear: both;
        }

    .Footer--minimal .Footer-logo {
        display: inline-block;
        margin-bottom: 20px;
    }

@media screen and (min-width:33.75em) {
    .Footer--minimal .Footer-logo {
        float: left;
    }
}

.Footer--minimal .SocialNetworks {
    margin-bottom: 20px;
}

@media screen and (min-width:33.75em) {
    .Footer--minimal .SocialNetworks {
        bottom: 0;
        position: absolute;
        right: 0;
    }
}

.Footer--minimal .Footer-BBB {
    float: right;
    margin-bottom: 0;
    margin-left: 30px;
}

.Footer--minimal .Footer-section {
    border-top: 1px solid rgba(255,255,255,.2);
    clear: both;
    color: #fff;
    font-size: .764706em;
    padding-top: 30px;
}

    .Footer--minimal .Footer-section:after, .Footer--minimal .Footer-section:before {
        content: " ";
        display: table;
    }

    .Footer--minimal .Footer-section:after {
        clear: both;
    }

    .Footer--minimal .Footer-section p {
        max-width: 700px;
        margin-bottom: 1em;
    }

        .Footer--minimal .Footer-section p:last-child {
            margin-bottom: 0;
        }

.Footer--minimal .Footer-description {
    overflow: hidden;
    text-align: left;
}

.Footer--minimal .Footer-legal {
    margin-top: 20px;
}

.parsley-required, .parsley-type {
    color: #ed1c24;
    font-size: .882353em;
    margin-top: 5px;
}

.Form-required {
    color: #ed1c24;
}

.GetInTouch {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    position: relative;
    background-color: #08a7e7;
    color: #fff;
    padding-bottom: 50px;
    padding-top: 40px;
}

    .GetInTouch:before {
        background: -webkit-radial-gradient(center top ellipse,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        background: radial-gradient(ellipse at center top,rgba(0,0,0,.13) 0,rgba(255,255,255,0) 65%);
        content: '\0020';
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

@media screen and (min-width:48em) {
    .GetInTouch {
        padding-top: 60px;
    }
}

.GetInTouch .u-col-half--med-wide {
    margin-top: 30px;
}

    .GetInTouch .u-col-half--med-wide:first-child {
        margin-top: 0;
    }

@media screen and (min-width:48em) {
    .GetInTouch .u-col-half--med-wide {
        margin-top: 0;
    }
}

@media print {
    .GetInTouch-finder {
        display: none !important;
    }
}

.GetInTouch svg {
    fill: #fff;
}

.GetInTouch p {
    font-size: 1em;
    margin-bottom: .6em;
}

    .GetInTouch p:last-child {
        margin-bottom: 0;
    }

.GetInTouch input {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    height: 2.411765rem;
}

    .GetInTouch input::-webkit-input-placeholder {
        color: #fff;
    }

    .GetInTouch input::-moz-placeholder {
        color: #fff;
    }

    .GetInTouch input:-ms-input-placeholder {
        color: #fff;
    }

    .GetInTouch input::placeholder {
        color: #fff;
    }

.GetInTouch button {
    font-size: 1.058824em;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: none;
}

.GetInTouch-icon {
    display: none;
    float: left;
    margin-right: 20px;
}

@media screen and (min-width:26.25em) {
    .GetInTouch-icon {
        display: block;
    }
}

.GetInTouch-finder .GetInTouch-icon {
    margin-right: 12px;
}

@media print {
    .GetInTouch-finder .GetInTouch-icon {
        display: none !important;
    }
}

.GetInTouch-content {
    overflow: hidden;
}

.GetInTouch-header {
    font-weight: 700;
    font-size: 1.764706em;
    line-height: 1.2;
    margin-bottom: 11px;
    text-transform: uppercase;
}

.fonts-loaded .GetInTouch-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .GetInTouch-header {
        font-size: 176.470588%;
    }
}

@media screen and (min-width:22em) {
    .GetInTouch-header {
        font-size: 182.352941%;
    }
}

@media screen and (min-width:24em) {
    .GetInTouch-header {
        font-size: 188.235294%;
    }
}

@media screen and (min-width:26em) {
    .GetInTouch-header {
        font-size: 194.117647%;
    }
}

@media screen and (min-width:28em) {
    .GetInTouch-header {
        font-size: 200%;
    }
}

@media screen and (min-width:30em) {
    .GetInTouch-header {
        font-size: 205.882353%;
    }
}

@media screen and (min-width:48em) {
    .GetInTouch-header {
        font-size: 176.470588%;
    }
}

@media screen and (min-width:50.375em) {
    .GetInTouch-header {
        font-size: 182.352941%;
    }
}

@media screen and (min-width:52.75em) {
    .GetInTouch-header {
        font-size: 188.235294%;
    }
}

@media screen and (min-width:55.125em) {
    .GetInTouch-header {
        font-size: 194.117647%;
    }
}

@media screen and (min-width:57.5em) {
    .GetInTouch-header {
        font-size: 200%;
    }
}

@media screen and (min-width:59.875em) {
    .GetInTouch-header {
        font-size: 205.882353%;
    }
}

.GetInTouch-header b {
    color: #04405b;
}

.GetInTouch-form:after, .GetInTouch-form:before {
    content: " ";
    display: table;
}

.GetInTouch-form:after {
    clear: both;
}

.GetInTouch-miles, .GetInTouch-zip {
    display: inline-block;
    margin-bottom: 15px;
}

@media screen and (max-width:25.5625em) {
    .GetInTouch-miles, .GetInTouch-zip {
        width: 100%;
    }
}

.GetInTouch-form[data-eq-state~=wide] .GetInTouch-miles, .GetInTouch-form[data-eq-state~=wide] .GetInTouch-zip {
    float: left;
}

@media screen and (min-width:25.625em) {
    .GetInTouch-zip {
        margin-right: 10px;
        width: 185px;
    }
}

.GetInTouch .js-customSelect div {
    border-color: #fff;
    color: #fff;
    font-size: 1em;
    font-weight: 400;
}

    .GetInTouch .js-customSelect div:before {
        display: none;
    }

    .GetInTouch .js-customSelect div:after {
        border-top-color: #fff;
    }

.Header {
    -webkit-box-shadow: 1px 1px 3px 2px rgba(0,0,0,.11);
    box-shadow: 1px 1px 3px 2px rgba(0,0,0,.11);
    position: relative;
    z-index: 500;
}

    .Header:after {
        clear: both;
        content: "";
        display: table;
    }

    .Header:before {
        background-color: #ccc;
        bottom: 10px;
        content: '\0020';
        left: 18%;
        position: absolute;
        top: 10px;
        width: 1px;
    }

@media print {
    .Header:before {
        display: none !important;
    }
}

.Header-logo {
    background-color: #fff;
    padding: 18px 0 14px;
    position: relative;
    z-index: 1;
}

@media print {
    .page-clinical-trials .Header-logo, .page-drug-pipeline .Header-logo {
        padding: 0;
    }
}

.aos .Header-logo {
    min-height: 110px;
}

@media screen and (max-width:60em) {
    .Header-logo {
        border-bottom: 1px solid #ccc;
    }
}

@media screen and (min-width:64em) {
    .Header-logo {
        float: left;
        padding: 10px 0;
        vertical-align: top;
        width: 18%;
    }
}

@media screen and (min-width:66.75em) {
    .Header-logo {
        padding-bottom: 16px;
        padding-top: 16px;
    }
}

.Header .SocialNetworks {
    display: none;
}

@media screen and (min-width:56.25em) {
    .Header .SocialNetworks {
        display: block;
        float: right;
        margin-right: 20px;
        margin-top: 9px;
    }
}

@media screen and (min-width:66.75em) {
    .Header .SocialNetworks {
        margin-top: 18px;
    }
}

@media screen and (min-width:61.25em) {
    .Header .SocialNetworks-item {
        margin-left: 15px;
    }
}

.Header .SearchBox {
    margin-bottom: .294118rem;
}

@media screen and (min-width:64em) {
    .Header .SearchBox {
        float: right;
        margin-bottom: 0;
        margin-top: 4px;
        width: 125px;
    }
}

@media screen and (min-width:66.75em) {
    .Header .SearchBox {
        margin-top: 13px;
    }
}

.Header-nav {
    background-color: #fff;
    position: absolute;
    top: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    width: 100%;
    z-index: -1;
}

@media screen and (max-width:60em) {
    .Header-nav {
        -webkit-transition: -webkit-transform .5s cubic-bezier(.215,.61,.355,1);
        transition: -webkit-transform .5s cubic-bezier(.215,.61,.355,1);
        transition: transform .5s cubic-bezier(.215,.61,.355,1);
        transition: transform .5s cubic-bezier(.215,.61,.355,1),-webkit-transform .5s cubic-bezier(.215,.61,.355,1);
    }
}

.Header-nav.js-active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.no-js .Header-nav {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.no-csstransforms .Header-nav .Nav {
    height: 0;
    overflow: hidden;
    margin-top: 0;
}

.no-csstransforms .Header-nav.js-active .Nav {
    height: auto;
}

@media screen and (min-width:64em) {
    .Header-nav {
        float: left;
        margin-top: 0;
        max-width: none;
        padding: 10px 0;
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        vertical-align: top;
        width: 56%;
    }
}

@media screen and (min-width:64em) {
    .Header-nav {
        width: 56%;
    }
}

.Header-nav .Nav {
    margin-top: 20px;
}

@media screen and (min-width:64em) {
    .Header-nav .Nav {
        margin-top: 0;
        padding-left: 2.769231%;
        padding-right: 2.769231%;
    }
}

@media screen and (min-width:64em) {
    .Header-nav .Nav {
        padding-left: 2.608696%;
        padding-right: 2.608696%;
    }
}

@media screen and (min-width:64em) {
    .Header-nav .Nav-primary-wrap {
        bottom: 10px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}

@media screen and (min-width:66.75em) {
    .Header-nav .Nav-primary-wrap {
        bottom: 21px;
    }
}

@media screen and (min-width:64em) {
    .Header-nav .Nav-primary {
        padding-left: 19.8%;
        padding-right: 18.8%;
    }
}

@media screen and (min-width:64em) {
    .Header-nav .Nav-primary {
        padding-right: 27.8%;
    }
}

@media screen and (min-width:64em) {
    .Header-nav .Nav-secondary-wrap {
        float: left;
        margin-top: 9px;
    }
}

@media screen and (min-width:66.75em) {
    .Header-nav .Nav-secondary-wrap {
        margin-top: 19px;
    }
}

.Header-navAction {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
}

@media screen and (min-width:64em) {
    .Header-navAction {
        float: right;
        position: static;
        width: 17%;
    }
}

@media screen and (min-width:64em) {
    .Header-navAction {
        width: 13%;
    }
}

.Header--minimal {
    padding-bottom: 20px;
    padding-top: 18px;
}

    .Header--minimal:before {
        display: none;
    }

    .Header--minimal .Logo {
        padding: 0;
    }

    .Header--minimal .Logo-link {
        float: left;
        max-width: none;
        width: 153px;
    }

    .Header--minimal .Logo svg {
        float: none;
    }

.HomeBillboard {
    background-color: #b0b0b0;
    color: #fff;
}

    .HomeBillboard a, .HomeBillboard a:link {
        color: #fff;
    }

        .HomeBillboard a:visited {
            color: #fff;
        }

        .HomeBillboard a:active, .HomeBillboard a:focus, .HomeBillboard a:hover {
            color: #12b5f7;
        }

@media screen and (min-width:50em) {
    .HomeBillboard {
        position: relative;
    }
}

.HomeBillboard-primary {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

@media screen and (min-width:37.5em) {
    .HomeBillboard-primary {
        display: block;
        position: relative;
        overflow: hidden;
    }

        .HomeBillboard-primary:before {
            content: "\0020";
            display: block;
        }

        .HomeBillboard-primary:before {
            padding-top: 56.25%;
        }
}

@media screen and (min-aspect-ratio:1 / 1) {
    .HomeBillboard-primary {
        min-height: 56.25%;
    }
}

@media screen and (min-height:31.25em) {
    .HomeBillboard-primary {
        max-height: 80vh;
    }
}

.HomeBillboard video::-webkit-media-controls {
    display: none !important;
}

.HomeBillboard-video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    width: 100%;
}

@media screen and (max-aspect-ratio:1 / 1) {
    .HomeBillboard-video {
        height: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: auto;
    }

    .no-csstransforms .HomeBillboard-video {
        left: 0;
    }
}

@media screen and (min-aspect-ratio:1 / 1) {
    .HomeBillboard-video {
        height: auto;
        width: 100%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .no-csstransforms .HomeBillboard-video {
        top: 0;
    }
}

.HomeBillboard-video.js-active {
    display: block;
}

.no-autoplay .HomeBillboard-video {
    display: none;
}

.HomeBillboard-wrap {
    padding: 15px;
    position: relative;
}

    .HomeBillboard-wrap:before {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(8%,rgba(64,64,64,.05)),color-stop(20%,rgba(64,64,64,.16)),color-stop(43%,rgba(64,64,64,.41)),color-stop(64%,rgba(64,64,64,.68)),color-stop(83%,rgba(64,64,64,.87)),to(#404040));
        background-image: -webkit-linear-gradient(top,transparent 0,rgba(64,64,64,.05) 8%,rgba(64,64,64,.16) 20%,rgba(64,64,64,.41) 43%,rgba(64,64,64,.68) 64%,rgba(64,64,64,.87) 83%,#404040 100%);
        background-image: linear-gradient(to bottom,transparent 0,rgba(64,64,64,.05) 8%,rgba(64,64,64,.16) 20%,rgba(64,64,64,.41) 43%,rgba(64,64,64,.68) 64%,rgba(64,64,64,.87) 83%,#404040 100%);
        content: '\0020';
    }

@media screen and (min-width:28.75em) {
    .HomeBillboard-wrap {
        padding: 25px;
    }
}

@media screen and (min-width:37.5em) {
    .HomeBillboard-wrap {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        padding: 50px;
    }

        .HomeBillboard-wrap:before {
            display: none;
        }
}

.HomeBillboard-content {
    margin-top: 50%;
    max-width: 1020px;
    position: relative;
    text-shadow: 0 0 15px rgba(0,0,0,.3);
    z-index: 1;
}

@media screen and (max-height:31.25em) and (min-width:28.75em) {
    .HomeBillboard-content {
        margin-top: 10%;
    }
}

@media screen and (min-height:31.25em) and (min-width:28.75em) {
    .HomeBillboard-content {
        margin-top: 25%;
    }
}

@media screen and (min-width:37.5em) {
    .HomeBillboard-content {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: 0;
        width: 80%;
    }

    .no-csstransforms .HomeBillboard-content {
        top: auto;
        position: static;
    }
}

@media screen and (min-width:50em) {
    .HomeBillboard-content {
        margin-top: -69px;
        width: 70%;
    }
}

.HomeBillboard-title {
    font-weight: 600;
    font-size: 1.764706em;
    line-height: 1.2;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.fonts-loaded .HomeBillboard-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .HomeBillboard-title {
        font-size: 188.235294%;
        line-height: 1.2;
    }
}

@media screen and (min-width:28.125em) {
    .HomeBillboard-title {
        font-size: 205.882353%;
        line-height: 1.1875;
    }
}

@media screen and (min-width:36.25em) {
    .HomeBillboard-title {
        font-size: 223.529412%;
        line-height: 1.175;
    }
}

@media screen and (min-width:44.375em) {
    .HomeBillboard-title {
        font-size: 241.176471%;
        line-height: 1.1625;
    }
}

@media screen and (min-width:52.5em) {
    .HomeBillboard-title {
        font-size: 258.823529%;
        line-height: 1.15;
    }
}

@media screen and (min-width:60.625em) {
    .HomeBillboard-title {
        font-size: 276.470588%;
        line-height: 1.1375;
    }
}

@media screen and (min-width:68.75em) {
    .HomeBillboard-title {
        font-size: 294.117647%;
        line-height: 1.125;
    }
}

@media screen and (min-width:76.875em) {
    .HomeBillboard-title {
        font-size: 311.764706%;
        line-height: 1.1125;
    }
}

.HomeBillboard-title .yellow, .HomeBillboard-title b, .HomeBillboard-title strong {
    color: #fbc303;
    font-weight: inherit;
}

.HomeBillboard-title .blue, .HomeBillboard-title i {
    color: #08a7e7;
    font-style: normal;
}

.HomeBillboard-title .orange {
    color: #f39c23;
}

.HomeBillboard-title .dark-blue {
    color: #006694;
}

.HomeBillboard-subtitle {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.058824em;
    line-height: 1.4;
}

.fonts-loaded .HomeBillboard-subtitle {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .HomeBillboard-subtitle {
        font-size: 111.764706%;
        line-height: 1.5;
    }
}

@media screen and (min-width:30em) {
    .HomeBillboard-subtitle {
        font-size: 123.529412%;
        line-height: 1.4;
    }
}

@media screen and (min-width:40em) {
    .HomeBillboard-subtitle {
        font-size: 135.294118%;
        line-height: 1.3;
    }
}

@media screen and (min-width:50em) {
    .HomeBillboard-subtitle {
        font-size: 147.058824%;
        line-height: 1.2;
    }
}

.HomeBillboard-subtitle svg {
    margin-left: .588235rem;
    vertical-align: middle;
}

.HomeBillboard-subtitle a:active, .HomeBillboard-subtitle a:focus, .HomeBillboard-subtitle a:hover {
    color: rgba(255,255,255,.75);
}

.HomeBillboard-secondary {
    background-color: #474747;
    padding: 25px 15px 45px;
}

@media screen and (min-width:50em) {
    .HomeBillboard-secondary {
        background-color: rgba(71,71,71,.8);
        bottom: 0;
        display: table;
        left: 0;
        padding: 0 0 0 50px;
        position: absolute;
        width: 100%;
    }
}

.HomeBillboard-promoted-item {
    margin-bottom: 25px;
    position: relative;
}

@media screen and (min-width:50em) {
    .HomeBillboard-promoted-item {
        display: table-cell;
        margin-bottom: 0;
        padding: 25px 4% 25px 0;
        vertical-align: top;
        width: 33.333333%;
    }
}

.HomeBillboard-promoted-label {
    font-weight: 600;
    background-color: #333;
    display: inline-block;
    font-size: .705882em;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 13px;
    padding: 10px 12px 8px;
    text-transform: uppercase;
}

.fonts-loaded .HomeBillboard-promoted-label {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:50em) {
    .HomeBillboard-promoted-label {
        left: 0;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .no-csstransforms .HomeBillboard-promoted-label {
        top: -15px;
    }
}

.HomeBillboard-promoted-title {
    font-size: 1.058824em;
}

@media screen and (min-width:50em) {
    .HomeBillboard-promoted-title {
        font-size: 82.352941%;
    }
}

@media screen and (min-width:55.875em) {
    .HomeBillboard-promoted-title {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:61.75em) {
    .HomeBillboard-promoted-title {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:67.625em) {
    .HomeBillboard-promoted-title {
        font-size: 100%;
    }
}

@media screen and (min-width:73.5em) {
    .HomeBillboard-promoted-title {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:79.375em) {
    .HomeBillboard-promoted-title {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:85.25em) {
    .HomeBillboard-promoted-title {
        font-size: 117.647059%;
    }
}

.HomeBillboard-buttons {
    padding: 15px 0;
    text-align: center;
}

    .HomeBillboard-buttons:after, .HomeBillboard-buttons:before {
        content: " ";
        display: table;
    }

    .HomeBillboard-buttons:after {
        clear: both;
    }

@media screen and (min-width:20em) {
    .HomeBillboard-buttons {
        margin-left: -5%;
        text-align: left;
    }
}

@media screen and (min-width:28.125em) {
    .HomeBillboard-buttons {
        margin-left: 0;
    }
}

@media screen and (min-width:50em) {
    .HomeBillboard-buttons {
        display: table-cell;
        vertical-align: middle;
        width: 20%;
    }
}

@media screen and (min-width:81.25em) {
    .HomeBillboard-buttons {
        width: 250px;
    }
}

.HomeBillboard-buttons a, .HomeBillboard-buttons a:active, .HomeBillboard-buttons a:focus, .HomeBillboard-buttons a:hover, .HomeBillboard-buttons a:link, .HomeBillboard-buttons a:visited {
    color: #fff;
}

.HomeBillboard-button {
    clear: left;
    display: inline-block;
    float: left;
    font-size: 1.058824em;
    margin-top: 20px;
    padding: 12px 10px;
    text-align: center;
}

    .HomeBillboard-button, .HomeBillboard-button:link, .HomeBillboard-button:visited {
        background-color: #08a7e7;
        color: #fff;
    }

        .HomeBillboard-button:active, .HomeBillboard-button:focus, .HomeBillboard-button:hover {
            background-color: #0795ce;
        }

@media screen and (min-width:50em) {
    .HomeBillboard-button {
        font-size: 82.352941%;
    }
}

@media screen and (min-width:55.875em) {
    .HomeBillboard-button {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:61.75em) {
    .HomeBillboard-button {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:67.625em) {
    .HomeBillboard-button {
        font-size: 100%;
    }
}

@media screen and (min-width:73.5em) {
    .HomeBillboard-button {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:79.375em) {
    .HomeBillboard-button {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:85.25em) {
    .HomeBillboard-button {
        font-size: 117.647059%;
    }
}

.HomeBillboard-button:first-child {
    margin-top: 0;
}

.HomeBillboard-button svg {
    height: 10px;
    width: 10px;
    vertical-align: middle;
    margin: -2px 0 0 5px;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.HomeBillboard-button:active svg, .HomeBillboard-button:focus svg, .HomeBillboard-button:hover svg {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

@media screen and (min-width:20em) {
    .HomeBillboard-button {
        clear: none;
        margin-left: 5%;
        margin-top: 0;
        width: 45%;
    }
}

@media screen and (min-width:28.125em) {
    .HomeBillboard-button {
        float: none;
        margin-left: 0;
        margin-right: 20px;
        padding-left: 25px;
        padding-right: 25px;
        width: auto;
    }
}

@media screen and (min-width:50em) {
    .HomeBillboard-button {
        font-weight: 600;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        text-align: left;
        width: 100%;
    }

        .HomeBillboard-button:first-child {
            margin-top: 0;
        }
}

.ImageBillboard {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    height: 80vh;
    max-height: 200px;
}

@media print {
    .ImageBillboard {
        display: none !important;
    }
}

@media screen and (min-width:22.25em) {
    .ImageBillboard {
        display: block;
        position: relative;
        height: auto;
        max-height: 320px;
        overflow: hidden;
    }

        .ImageBillboard:before {
            content: "\0020";
            display: block;
        }

        .ImageBillboard:before {
            padding-top: 56.25%;
        }
}

@media screen and (min-width:48em) {
    .ImageBillboard {
        max-height: 440px;
    }
}

.ImageBillboard--short {
    background-position: center 18%;
    margin-bottom: 30px;
    max-height: 130px;
}

@media screen and (min-width:22.25em) {
    .ImageBillboard--short {
        max-height: 200px;
    }
}

@media screen and (min-width:48em) {
    .ImageBillboard--short {
        max-height: 280px;
    }
}

.ImagePromo {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    display: block;
    height: 256px;
    position: relative;
}

    .ImagePromo:active .ImagePromo-text, .ImagePromo:focus .ImagePromo-text, .ImagePromo:hover .ImagePromo-text {
        background-color: rgba(102,102,102,.9);
        background-position: right 15px center;
    }

@media screen and (min-width:51.875em) {
    .ImagePromo {
        height: 225px;
        width: 100%;
    }
}

.ImagePromo-text {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(102,102,102,.8);
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20140%20140%22%20width=%22140%22%20height=%22140%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M130.9%2074l.3-.6c0-.1%200-.1.1-.2.1-.2.1-.4.2-.5%200-.1.1-.2.1-.3%200-.1.1-.3.1-.4%200-.1.1-.2.1-.4%200-.1%200-.3.1-.4%200-.1%200-.3.1-.4v-1.6c0-.1%200-.3-.1-.4%200-.1%200-.3-.1-.4%200-.1-.1-.2-.1-.4%200-.1-.1-.3-.1-.4%200-.1-.1-.2-.1-.3-.1-.2-.1-.4-.2-.5%200-.1%200-.1-.1-.2l-.3-.6c-.3-.5-.6-1-1-1.5L93.6%2021.4c-3-3.5-8.3-4-11.9-1-3.5%203-4%208.4-1%2011.9l24.6%2029.4H15.6c-4.6%200-8.4%203.6-8.4%208.3%200%204.6%203.8%207.7%208.4%207.7h89.7l-24.6%2029.7c-3%203.5-2.5%209.1%201%2012v.1c3.5%203%208.9%202.6%2011.9-1l36.2-43.1c.4-.3.7-.8%201.1-1.4-.1%200-.1%200%200%200z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right 20px center;
    background-repeat: no-repeat;
    -webkit-background-size: 40px auto;
    background-size: 40px auto;
    bottom: 0;
    font-size: 1em;
    line-height: 1.4;
    padding: 20px 80px 20px 15px;
    position: absolute;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    width: 100%;
}

.fonts-loaded .ImagePromo-text {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ImagePromo-text, .ImagePromo-text:active, .ImagePromo-text:focus, .ImagePromo-text:hover, .ImagePromo-text:link, .ImagePromo-text:visited {
    color: #fff;
}

@media screen and (min-width:20em) {
    .ImagePromo-text {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:30em) {
    .ImagePromo-text {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:40em) {
    .ImagePromo-text {
        font-size: 117.647059%;
    }
}

@media screen and (min-width:51.875em) {
    .ImagePromo-text {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:55.9375em) {
    .ImagePromo-text {
        font-size: 100%;
    }
}

@media screen and (min-width:60em) {
    .ImagePromo-text {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:40em) {
    .ImagePromo-text {
        padding-bottom: 25px;
        padding-top: 25px;
    }
}

@media screen and (min-width:51.875em) {
    .ImagePromo-text {
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

.ImageStrata {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    position: relative;
    text-align: center;
    z-index: 1;
}

@media print {
    .ImageStrata {
        display: none !important;
    }
}

.ImageStrata:before {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,.4);
    content: '\0020';
    z-index: -1;
}

.ImageStrata-wrap {
    padding: 30px 20px;
    margin: 0 auto;
    max-width: 800px;
}

@media screen and (min-width:30em) {
    .ImageStrata-wrap {
        padding-bottom: 45px;
        padding-top: 45px;
    }
}

@media screen and (min-width:60em) {
    .ImageStrata-wrap {
        padding-bottom: 60px;
        padding-top: 60px;
    }
}

.ImageStrata-header {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-size: 1.352941em;
    line-height: 1.25;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.fonts-loaded .ImageStrata-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .ImageStrata-header {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:24em) {
    .ImageStrata-header {
        font-size: 152.941176%;
    }
}

@media screen and (min-width:28em) {
    .ImageStrata-header {
        font-size: 164.705882%;
    }
}

@media screen and (min-width:32em) {
    .ImageStrata-header {
        font-size: 176.470588%;
    }
}

@media screen and (min-width:36em) {
    .ImageStrata-header {
        font-size: 188.235294%;
    }
}

@media screen and (min-width:40em) {
    .ImageStrata-header {
        font-size: 200%;
    }
}

@media screen and (min-width:44em) {
    .ImageStrata-header {
        font-size: 211.764706%;
    }
}

@media screen and (min-width:48em) {
    .ImageStrata-header {
        font-size: 223.529412%;
    }
}

.ImageStrata-description {
    font-size: .823529em;
    line-height: 1.5;
    margin-bottom: 20px;
}

@media screen and (min-width:20em) {
    .ImageStrata-description {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:25.5625em) {
    .ImageStrata-description {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:31.125em) {
    .ImageStrata-description {
        font-size: 100%;
    }
}

@media screen and (min-width:36.6875em) {
    .ImageStrata-description {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:42.25em) {
    .ImageStrata-description {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:47.8125em) {
    .ImageStrata-description {
        font-size: 117.647059%;
    }
}

@media screen and (min-width:40em) {
    .ImageStrata-description {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.InfoBar {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: .941176em;
    line-height: 1.25;
    margin-bottom: 30px;
}

    .InfoBar:after, .InfoBar:before {
        content: " ";
        display: table;
    }

    .InfoBar:after {
        clear: both;
    }

@media screen and (min-width:39.0625em) {
    .InfoBar {
        padding: 15px 0;
    }
}

@media print {
    .InfoBar {
        border: none;
        padding: 0;
        margin-top: 10px !important;
    }
}

.InfoBar-byline {
    border-bottom: 1px solid #ccc;
    display: table;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

    .InfoBar-byline:after, .InfoBar-byline:before {
        content: " ";
        display: table;
    }

    .InfoBar-byline:after {
        clear: both;
    }

@media screen and (min-width:39.0625em) {
    .InfoBar-byline {
        margin-bottom: 15px;
        margin-top: -5px;
        padding-top: 0;
    }
}

@media print {
    .InfoBar-byline {
        border: none;
    }
}

.InfoBar-byline-thumbnail {
    display: table-cell;
    padding-right: 15px;
    vertical-align: top;
    width: 53px;
}

    .InfoBar-byline-thumbnail img {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        display: block;
        width: 100%;
    }

.InfoBar-byline-text {
    display: table-cell;
    vertical-align: middle;
}

    .InfoBar-byline-text a {
        font-weight: 600;
    }

.InfoBar-date {
    padding-bottom: 15px;
}

@media screen and (min-width:39.0625em) {
    .InfoBar-date {
        border-left: 1px solid #ccc;
        float: left;
        margin-left: 15px;
        padding-bottom: 0;
        padding-left: 15px;
        position: relative;
        top: 2px;
    }
}

.InfoBar-utils {
    padding-bottom: 15px;
    padding-top: 15px;
}

@media print {
    .InfoBar-utils {
        display: none !important;
    }
}

.InfoBar-utils:after, .InfoBar-utils:before {
    content: " ";
    display: table;
}

.InfoBar-utils:after {
    clear: both;
}

@media screen and (min-width:39.0625em) {
    .InfoBar-utils {
        border-top: 0;
        float: left;
        margin-top: 0;
        padding: 0;
    }
}

.InfoBar .ShareLinks {
    float: left;
}

.InfoBar .Downloads {
    background-color: transparent;
    border-top: 1px solid #ccc;
    display: block;
    margin: 0;
    padding: 0;
}

@media screen and (min-width:33.75em) {
    .InfoBar .Downloads {
        display: none;
    }
}

.InfoBar .Downloads-header {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    font-weight: 700;
    color: #454545;
    font-size: .823529rem;
    line-height: 1.285;
    margin-bottom: 15px;
    text-transform: uppercase;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M16%2011q0%200.4-0.3%200.7l-7%207q-0.3%200.3-0.7%200.3t-0.7-0.3l-7-7q-0.3-0.3-0.3-0.7t0.3-0.7%200.7-0.3h14q0.4%200%200.7%200.3t0.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 95% center;
    -webkit-background-size: 15px auto;
    background-size: 15px auto;
    background-repeat: no-repeat;
    margin: 0;
    padding: 15px 0;
    position: relative;
    width: 100%;
}

    .InfoBar .Downloads-header:active, .InfoBar .Downloads-header:focus, .InfoBar .Downloads-header:hover {
        background-color: transparent;
    }

.fonts-loaded .InfoBar .Downloads-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.InfoBar .Downloads-header:before {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M16%2019q0%200.4-0.3%200.7t-0.7%200.3h-14q-0.4%200-0.7-0.3t-0.3-0.7%200.3-0.7l7-7q0.3-0.3%200.7-0.3t0.7%200.3l7%207q0.3%200.3%200.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 95% center;
    -webkit-background-size: 15px auto;
    background-size: 15px auto;
    background-repeat: no-repeat;
    content: '\0020';
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.InfoBar .Downloads-header.js-active {
    background-image: none;
}

    .InfoBar .Downloads-header.js-active:before {
        display: block;
    }

.InfoBar .Downloads-list.js-active {
    margin-bottom: 20px;
}

.InfoBar .Downloads-item {
    font-size: 1em;
    margin-top: 20px;
}

    .InfoBar .Downloads-item:first-child {
        margin-top: 0;
    }

@media screen and (max-width:33.6875em) {
    .InfoBar .SortLinks {
        clear: left;
        margin-top: 10px;
    }
}

@media screen and (min-width:33.75em) {
    .InfoBar .SortLinks {
        float: right;
    }
}

@media screen and (min-width:52.5em) {
    .InfoBar--author {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

        .InfoBar--author .InfoBar-byline {
            border-bottom: none;
            display: inline-block;
            margin-bottom: 0;
            max-width: 300px;
            padding-bottom: 0;
            width: auto;
            vertical-align: top;
        }

        .InfoBar--author .InfoBar-utils {
            border-left: 1px solid #ccc;
            display: inline-block;
            float: none;
            margin-left: 15px;
            margin-top: 2px;
            padding-left: 15px;
            vertical-align: top;
        }

        .InfoBar--author .InfoBar-date {
            border-left: none;
            float: right;
            margin-left: 0;
            margin-top: 2px;
        }
}

.InfoBar--article .InfoBar-date {
    padding-bottom: 0;
    padding-top: 15px;
}

@media screen and (min-width:39.0625em) {
    .InfoBar--article .InfoBar-date {
        border-left: none;
        border-right: 1px solid #ccc;
        margin-left: 0;
        margin-right: 15px;
        padding-top: 0;
        padding-left: 0;
        padding-right: 15px;
    }
}

@media screen and (max-width:51.8125em) {
    .InfoBar--search {
        border-bottom: none;
        border-top: none;
        margin: 10px 0 0;
        padding: 0;
    }
}

.InfoBar--search .InfoBar-results {
    float: none;
}

@media screen and (min-width:33.75em) {
    .InfoBar--search .InfoBar-results {
        float: left;
    }
}

.InfoBar--chapters {
    padding-bottom: 25px;
}

@media screen and (max-width:47.9375em) {
    .InfoBar--chapters {
        border-top: none;
        padding-top: 0;
    }
}

@media screen and (min-width:48em) {
    .InfoBar--chapters {
        padding-bottom: 15px;
    }
}

@media screen and (min-width:30em) {
    .InfoBar--chapters .InfoBar-results {
        float: left;
    }
}

@media screen and (min-width:30em) {
    .InfoBar--chapters .SortLinks {
        clear: none;
        float: right;
        margin-top: 0;
    }
}

.InfoBar-form:after, .InfoBar-form:before {
    content: " ";
    display: table;
}

.InfoBar-form:after {
    clear: both;
}

.InfoBar-form .chosen-container {
    border: 1px solid #ccc;
    font-size: .941176rem;
}

.InfoBar-form .FinderBillboard-form-zip {
    font-size: .941176rem;
}

.InfoBar-form .FinderBillboard-form-miles .placeholder {
    font-size: .941176rem;
    line-height: 2.588235rem;
}

.InfoBar--slim {
    border-bottom: 0;
    border-top: 0;
    padding-bottom: 0;
    padding-top: 0;
}

    .InfoBar--slim .InfoBar-utils {
        padding-bottom: 0;
        padding-top: 0;
    }

@media print {
    .InfoBar--slim {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
}

.LogoGrid {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

    .LogoGrid:after, .LogoGrid:before {
        content: " ";
        display: table;
    }

    .LogoGrid:after {
        clear: both;
    }

.u-richtext .LogoGrid {
    margin-bottom: 0;
    padding-left: 0;
}

.LogoGrid-item {
    border: 1px solid #ccc;
    height: 100px;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (min-width:30em) {
    .LogoGrid-item {
        float: left;
        width: 49%;
    }

        .LogoGrid-item:nth-child(odd) {
            clear: left;
            margin-right: 1%;
        }

        .LogoGrid-item:nth-child(even) {
            margin-left: 1%;
        }
}

@media screen and (min-width:30em) {
    .LogoGrid-item:last-child, .LogoGrid-item:nth-last-child(2) {
        margin-bottom: 0;
    }
}

.u-richtext .LogoGrid-item {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
}

    .u-richtext .LogoGrid-item:before {
        display: none;
    }

.LogoGrid-link {
    border: 1px solid transparent;
    display: block;
    height: 100%;
    padding: 10px;
    text-align: center;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

    .LogoGrid-link:active, .LogoGrid-link:focus, .LogoGrid-link:hover {
        border-color: #08a7e7;
    }

.LogoGrid-logo {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 100%;
}

.no-csstransforms .LogoGrid-logo {
    top: auto;
    position: static;
}

.LogoGrid-name {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    font-size: 1.058824em;
}

.no-csstransforms .LogoGrid-name {
    top: auto;
    position: static;
}

.Logo {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

    .Logo:after, .Logo:before {
        content: " ";
        display: table;
    }

    .Logo:after {
        clear: both;
    }

@media screen and (min-width:64em) {
    .Logo {
        padding: 0 10%;
        text-align: center;
    }
}

.no-inlinesvg .Logo-fallback {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-image: url(/images/cff-logo-153.png);
    background-position: center center;
    background-repeat: no-repeat;
    height: 78px;
    margin-left: 20px;
    width: 153px;
}

.Logo-link {
    display: block;
    max-width: 45%;
    overflow: hidden;
}

@media screen and (min-width:64em) {
    .Logo-link {
        max-width: none;
        width: 100%;
    }
}

.Logo svg {
    display: block;
    max-width: 153px;
    width: 100%;
}

@media screen and (min-width:64em) {
    .Logo svg {
        float: right;
    }
}

@media print {
    .Logo svg {
        height: 60px;
        position: absolute;
        width: 115px;
    }
}

.MailTo {
    display: none;
}

    .MailTo[href] {
        display: inline-block;
    }

.MediaBlock {
    background-color: #f2f2f2;
}

.MediaBlock-iframe {
    margin: 0 auto;
    max-width: 600px;
}

    .MediaBlock-iframe > * {
        display: none;
    }

    .MediaBlock-iframe > :first-child {
        display: block;
    }

    .MediaBlock-iframe iframe {
        max-height: 100vh !important;
    }

html.js-no-scroll {
    height: 100%;
}

body.js-no-scroll {
    overflow: hidden;
    width: 100%;
}

@media print {
    [data-modal] {
        display: none !important;
    }
}

.no-js [data-modal] {
    display: none;
}

[data-modal] .js-active {
    -webkit-overflow-scrolling: touch;
}

.Modal {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,.5);
    font-size: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    text-align: center;
    visibility: hidden;
    z-index: 901;
}

    .Modal:after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

.Modal-content {
    background-color: #fff;
    display: inline-block;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 25px;
    position: relative;
    text-align: left;
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9);
    -webkit-transition: all .2s cubic-bezier(.215,.61,.355,1);
    transition: all .2s cubic-bezier(.215,.61,.355,1);
    vertical-align: middle;
    width: 100%;
    z-index: 901;
}

@media print {
    .Modal-content {
        display: none !important;
    }
}

.Modal.js-active .Modal-content, .aos .Modal-content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

@media screen and (min-width:30em) {
    .Modal-content {
        max-width: 450px;
    }
}

.Modal-close {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    font-weight: 600;
    color: #999;
    font-size: .764706em;
    font-weight: 700;
    height: auto;
    position: absolute;
    right: 18px;
    text-transform: uppercase;
    top: 18px;
    vertical-align: middle;
    width: auto;
}

    .Modal-close:active, .Modal-close:focus, .Modal-close:hover {
        background-color: transparent;
    }

.fonts-loaded .Modal-close {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Modal-close:active, .Modal-close:focus, .Modal-close:hover {
    background-color: transparent;
    color: #08a7e7;
}

.Modal-close svg {
    fill: currentColor;
    height: 25px;
    margin-left: 3px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.ActionNav {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
}

@media print {
    .ActionNav {
        display: none !important;
    }
}

.fonts-loaded .ActionNav {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (max-width:47.9375em) {
    .ActionNav {
        display: table;
        width: 100%;
    }
}

@media screen and (min-width:48em) {
    .ActionNav {
        display: block;
    }
}

@media screen and (max-width:47.9375em) {
    .ActionNav-item {
        display: table-cell;
        width: 50%;
        vertical-align: bottom;
    }
}

@media screen and (min-width:48em) {
    .ActionNav-item {
        display: block;
        height: 50px;
    }
}

@media screen and (min-width:66.75em) {
    .ActionNav-item {
        height: 55px;
    }
}

.no-js .ActionNav-item:last-child {
    display: none;
}

.ActionNav-link {
    display: block;
    font-size: .941176em;
    font-weight: 600;
    height: 100%;
    padding: 14px 0 12px;
    text-align: center;
}

@media screen and (min-width:48em) {
    .ActionNav-link {
        font-size: .823529em;
        padding: 0 13px;
    }
}

@media screen and (min-width:69.375em) {
    .ActionNav-link {
        font-size: .941176em;
    }
}

@media screen and (min-width:48em) {
    .ActionNav-link-text {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
    }

    .no-csstransforms .ActionNav-link-text {
        top: auto;
        position: static;
    }

    .no-csstransforms .ActionNav-link-text {
        padding-bottom: 15px;
        padding-top: 15px;
    }
}

.ActionNav-link, .ActionNav-link:active, .ActionNav-link:focus, .ActionNav-link:hover, .ActionNav-link:link, .ActionNav-link:visited {
    color: #fff;
}

    .ActionNav-link:active, .ActionNav-link:focus, .ActionNav-link:hover {
        text-decoration: none;
    }

.ActionNav-link--donate, .ActionNav-link--donate:link, .ActionNav-link--donate:visited {
    background-color: #006694;
    color: #fff;
    text-shadow: 1px 1px 1px #004361;
}

    .ActionNav-link--donate:active, .ActionNav-link--donate:focus, .ActionNav-link--donate:hover {
        background-color: #00547b;
    }

@media screen and (max-width:47.9375em) {
    .ActionNav-link--donate {
        border-right: 1px solid #fff;
    }
}

@media screen and (min-width:48em) {
    .ActionNav-link--donate, .ActionNav-link--donate:link, .ActionNav-link--donate:visited {
        background-color: #08a7e7;
        color: #fff;
        text-shadow: 1px 1px 1px #0683b6;
    }

        .ActionNav-link--donate:active, .ActionNav-link--donate:focus, .ActionNav-link--donate:hover {
            background-color: #0795ce;
        }
}

.ActionNav-link--action {
    position: relative;
}

    .ActionNav-link--action, .ActionNav-link--action:link, .ActionNav-link--action:visited {
        background-color: #006694;
        color: #fff;
        text-shadow: 1px 1px 1px #004361;
    }

        .ActionNav-link--action:active, .ActionNav-link--action:focus, .ActionNav-link--action:hover {
            background-color: #00547b;
        }

        .ActionNav-link--action .ActionNav-link-text:after {
            background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M16%2011q0%200.4-0.3%200.7l-7%207q-0.3%200.3-0.7%200.3t-0.7-0.3l-7-7q-0.3-0.3-0.3-0.7t0.3-0.7%200.7-0.3h14q0.4%200%200.7%200.3t0.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
            background-position: center center;
            -webkit-background-size: 100% auto;
            background-size: 100% auto;
            content: '\0020';
            display: inline-block;
            height: 6px;
            margin-left: 6px;
            vertical-align: middle;
            width: 10px;
        }

.no-inlinesvg .ActionNav-link--action .ActionNav-link-text:after {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -184px;
    width: 8px;
    height: 14px;
}

.ActionNav-link--action.js-active {
    background-color: #00547b;
}

    .ActionNav-link--action.js-active .ActionNav-link-text:after {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M16%2019q0%200.4-0.3%200.7t-0.7%200.3h-14q-0.4%200-0.7-0.3t-0.3-0.7%200.3-0.7l7-7q0.3-0.3%200.7-0.3t0.7%200.3l7%207q0.3%200.3%200.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
    }

.ActionNav-panel {
    background-color: #006694;
    position: absolute;
    right: 0;
    width: 100%;
}

@media screen and (min-width:48em) {
    .ActionNav-panel {
        width: 240px;
    }
}

.ActionNav-sublist a, .ActionNav-sublist a:active, .ActionNav-sublist a:focus, .ActionNav-sublist a:hover, .ActionNav-sublist a:link, .ActionNav-sublist a:visited {
    color: #fff;
}

.ActionNav-sublist svg {
    fill: #2fbef8;
}

.ActionNav-sublist-item {
    font-weight: 400;
    font-size: .823529em;
    line-height: 1.29;
}

.fonts-loaded .ActionNav-sublist-item {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:48em) {
    .ActionNav-sublist-item:first-child .ActionNav-sublist-link {
        border-top: none;
    }
}

.ActionNav-sublist-link {
    border-top: 1px solid rgba(255,255,255,.4);
    display: block;
    padding: 18px 20px;
}

    .ActionNav-sublist-link:active, .ActionNav-sublist-link:focus, .ActionNav-sublist-link:hover {
        background-color: #00547b;
    }

.ActionNav-sublist-icon {
    float: left;
    margin-right: 20px;
    vertical-align: middle;
}

.ActionNav-sublist-text {
    display: block;
    overflow: hidden;
}

.Nav {
    font-weight: 400;
}

@media print {
    .Nav {
        display: none !important;
    }
}

.Nav:after, .Nav:before {
    content: " ";
    display: table;
}

.Nav:after {
    clear: both;
}

.fonts-loaded .Nav {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Nav svg {
    fill: currentColor;
}

.Nav .Nav-promo-description, .Nav .Nav-sublist-item-link {
    font-size: .941176em;
}

@media screen and (min-width:48em) {
    .Nav .Nav-promo-description, .Nav .Nav-sublist-item-link {
        font-size: .882353em;
    }
}

@media screen and (min-width:50.625em) {
    .Nav .Nav-promo-description, .Nav .Nav-sublist-item-link {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:51.6875em) {
    .Nav .Nav-promo-description, .Nav .Nav-sublist-item-link {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:52.75em) {
    .Nav .Nav-promo-description, .Nav .Nav-sublist-item-link {
        font-size: 100%;
    }
}

.Nav-toggle {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    bottom: 11px;
    font-size: .941176em;
    font-weight: 700;
    line-height: 1;
    padding: 9px 15px;
    position: absolute;
    right: 15px;
    text-shadow: none;
    text-transform: uppercase;
    vertical-align: middle;
}

@media print {
    .Nav-toggle {
        display: none !important;
    }
}

.Nav-toggle, .Nav-toggle:focus {
    background-color: transparent;
    color: #006694;
}

    .Nav-toggle:active, .Nav-toggle:hover {
        background-color: #f2f2f2;
        color: #08a7e7;
    }

@media screen and (min-width:62em) {
    .Nav-toggle {
        display: none;
    }
}

.no-js .Nav-toggle {
    display: none;
}

.Nav-toggle svg {
    fill: #666;
    vertical-align: middle;
}

.Nav-toggle-text {
    padding-left: 2px;
    vertical-align: middle;
}

.Nav-toggle-icon--open {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}

.Nav-toggle[aria-expanded=true] .Nav-toggle-icon--open {
    opacity: 0;
}

.Nav-toggle-icon--close {
    opacity: 0;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}

.Nav-toggle[aria-expanded=true] .Nav-toggle-icon--close {
    opacity: 1;
}

.Nav-primary {
    position: relative;
}

    .Nav-primary:after, .Nav-primary:before {
        content: " ";
        display: table;
    }

    .Nav-primary:after {
        clear: both;
    }

@media screen and (min-width:64em) {
    .Nav-primary {
        position: static;
    }
}

@media screen and (min-width:71.875em) {
    .Nav-primary {
        display: table;
        width: 100%;
    }
}

.Nav-primary-item {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width:60em) {
    .Nav-primary-item:first-child a {
        border-top: none;
    }
}

@media screen and (min-width:62em) {
    .Nav-primary-item {
        float: left;
        line-height: 1.2;
        margin-left: 4%;
    }

        .Nav-primary-item:first-child {
            margin-left: 0;
        }

        .Nav-primary-item .u-nowrap {
            display: block;
        }
}

@media screen and (min-width:62em) {
    .Nav-primary-item {
        margin-left: 5%;
    }
}

@media screen and (min-width:51.25em) {
    .Nav-primary-item {
        margin-left: 6%;
    }
}

@media screen and (min-width:53.125em) {
    .Nav-primary-item {
        margin-left: 7%;
    }
}

@media screen and (min-width:66.75em) {
    .Nav-primary-item {
        margin-left: 3%;
    }
}

@media screen and (min-width:66.75em) {
    .Nav-primary-item {
        margin-left: 4%;
    }

        .Nav-primary-item .u-nowrap {
            display: inline;
        }
}

@media screen and (min-width:71.875em) {
    .Nav-primary-item {
        display: table-cell;
        float: none;
        line-height: 1;
        margin: 0;
        text-align: center;
    }

        .Nav-primary-item:first-child {
            text-align: left;
            padding-left: 0;
        }

        .Nav-primary-item:last-child {
            text-align: right;
        }
}

.Nav-primary-item-link {
    display: block;
    font-size: .941176em;
    font-weight: 600;
    text-transform: uppercase;
}

    .Nav-primary-item-link, .Nav-primary-item-link:link {
        color: #006694;
    }

        .Nav-primary-item-link:visited {
            color: #006694;
        }

        .Nav-primary-item-link:active, .Nav-primary-item-link:focus, .Nav-primary-item-link:hover {
            color: #12b5f7;
        }

        .Nav-primary-item-link:focus {
            color: #006694;
        }

@media screen and (max-width:60em) {
    .Nav-primary-item-link {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-top: 1px solid #ccc;
        padding: 16px 0;
    }

    .js-Nav-init .Nav-primary-item-link {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23006694%22%3E%3Cpath%20d=%22M129.2%20295.7c3.8%200%207.2%201.5%2010.2%204.4l173.7%20173.7%20173.7-173.7c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l22.1%2022.1c2.9%202.9%204.4%206.3%204.4%2010.2%200%203.8-1.5%207.2-4.4%2010.2L323.3%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4s-7.2-1.5-10.2-4.4L97%20342.5c-2.9-2.9-4.4-6.3-4.4-10.2%200-3.8%201.5-7.2%204.4-10.2l22.1-22.1C122%20297.2%20125.4%20295.7%20129.2%20295.7L129.2%20295.7z%22%2F%3E%3C%2Fsvg%3E);
        background-position: right center;
        -webkit-background-size: 17px auto;
        background-size: 17px auto;
        background-repeat: no-repeat;
    }

        .js-Nav-init .Nav-primary-item-link.js-active {
            background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%20612%20792%22%20width=%22612%22%20height=%22792%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23006694%22%3E%3Cpath%20d=%22M497%20552.9c-3.8%200-7.2-1.5-10.2-4.4L313.1%20374.8%20139.4%20548.5c-2.9%202.9-6.3%204.4-10.2%204.4%20-3.8%200-7.2-1.5-10.2-4.4L97%20526.4c-2.9-2.9-4.4-6.3-4.4-10.2s1.5-7.2%204.4-10.2l205.9-205.9c2.9-2.9%206.3-4.4%2010.2-4.4%203.8%200%207.2%201.5%2010.2%204.4l205.9%20205.9c2.9%202.9%204.4%206.3%204.4%2010.2s-1.5%207.2-4.4%2010.2l-22.1%2022.1C504.2%20551.4%20500.8%20552.9%20497%20552.9L497%20552.9z%22%2F%3E%3C%2Fsvg%3E);
            color: #006694;
        }

            .js-Nav-init .Nav-primary-item-link.js-active:hover {
                color: #08a7e7;
            }
}

@media screen and (min-width:64em) {
    .js-Nav-init .Nav-primary-item-link {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23b0b0b0%22%3E%3Cpath%20d=%22M16%2011q0%200.4-0.3%200.7l-7%207q-0.3%200.3-0.7%200.3t-0.7-0.3l-7-7q-0.3-0.3-0.3-0.7t0.3-0.7%200.7-0.3h14q0.4%200%200.7%200.3t0.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
        background-position: right bottom;
        background-position: right bottom 3px;
        background-repeat: no-repeat;
        -webkit-background-size: 8px auto;
        background-size: 8px auto;
        display: inline-block;
        padding-right: 13px;
        position: relative;
    }

    .no-inlinesvg .js-Nav-init .Nav-primary-item-link:before {
        background-image: url(../images/icons/icon-sprite.png);
        background-position: -402px -160px;
        width: 8px;
        height: 14px;
        bottom: 3px;
        content: '\0020';
        position: absolute;
        right: 0;
    }

    .js-Nav-init .Nav-primary-item-link.js-active:after, .js-Nav-init .Nav-primary-item-link.js-active:before {
        content: '\0020';
        left: 50%;
        margin-left: -25.5px;
        position: absolute;
        z-index: 600;
    }

    .js-Nav-init .Nav-primary-item-link.js-active:before {
        height: 0;
        width: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #a6a6a6;
        bottom: -18px;
    }
}

@media screen and (min-width:64em) and (min-width:66.75em) {
    .js-Nav-init .Nav-primary-item-link.js-active:before {
        bottom: -26px;
    }
}

@media screen and (min-width:64em) {
    .js-Nav-init .Nav-primary-item-link.js-active:after {
        height: 0;
        width: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #fff;
        bottom: -19px;
    }
}

@media screen and (min-width:64em) and (min-width:66.75em) {
    .js-Nav-init .Nav-primary-item-link.js-active:after {
        bottom: -27px;
    }
}

@media screen and (min-width:64em) {
    .Nav-primary-item-link {
        font-size: .764706em;
    }
}

@media screen and (min-width:55.8125em) {
    .Nav-primary-item-link {
        font-size: .823529em;
    }
}

@media screen and (min-width:61.5em) {
    .Nav-primary-item-link {
        font-size: .882353em;
    }
}

@media screen and (min-width:65.875em) {
    .Nav-primary-item-link {
        font-size: .823529em;
    }
}

@media screen and (min-width:71em) {
    .Nav-primary-item-link {
        font-size: .882353em;
    }
}

@media screen and (min-width:71.875em) {
    .Nav-primary-item-link {
        font-size: .941176em;
    }
}

@media screen and (min-width:76.5625em) {
    .Nav-primary-item-link {
        font-size: 1em;
    }
}

.Nav-secondary-item {
    font-size: .941176em;
}

@media screen and (min-width:62em) {
    .Nav-secondary-item {
        font-size: 70.588235%;
    }
}

@media screen and (min-width:54.25em) {
    .Nav-secondary-item {
        font-size: 76.470588%;
    }
}

@media screen and (min-width:60.5em) {
    .Nav-secondary-item {
        font-size: 82.352941%;
    }
}

@media screen and (min-width:71.875em) {
    .Nav-secondary-item {
        font-size: 82.352941%;
    }
}

@media screen and (min-width:80em) {
    .Nav-secondary-item {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:62em) {
    .Nav-secondary-item {
        float: left;
        margin-left: 15px;
    }

        .Nav-secondary-item:first-child {
            margin-left: 0;
        }
}

@media screen and (min-width:71.875em) {
    .Nav-secondary-item {
        margin-left: 25px;
    }
}

.Nav-secondary-item-link, .Nav-secondary-item-link:link {
    color: #454545;
}

    .Nav-secondary-item-link:visited {
        color: #454545;
    }

    .Nav-secondary-item-link:active, .Nav-secondary-item-link:focus, .Nav-secondary-item-link:hover {
        color: #12b5f7;
    }

@media screen and (max-width:60em) {
    .Nav-secondary-item-link {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-top: 1px solid #ccc;
        display: block;
        font-weight: 600;
        padding: 16px 0;
    }
}

.Nav-panel {
    background-color: #fff;
    overflow: hidden;
    text-align: left;
    -webkit-transition: all .5s cubic-bezier(.215,.61,.355,1);
    transition: all .5s cubic-bezier(.215,.61,.355,1);
}

@media screen and (min-width:64em) {
    .Nav-panel {
        margin-top: 10px;
        padding-top: 10px;
    }
}

@media screen and (min-width:66.75em) {
    .Nav-panel {
        margin-top: 21px;
        padding-top: 0;
    }
}

.no-js .Nav-panel {
    display: none;
}

.Nav-panel[data-height] {
    max-height: 0;
}

@media screen and (min-width:64em) {
    .Nav-panel[data-height] {
        max-height: none;
    }
}

[data-whatinput=mouse] .Nav-panel:focus, [data-whatinput=touch] .Nav-panel:focus {
    outline: 0;
}

@media screen and (min-width:64em) {
    .Nav-panel {
        border-top: 1px solid #ccc;
        -webkit-box-shadow: 3px 3px 3px 0 rgba(0,0,0,.11);
        box-shadow: 3px 3px 3px 0 rgba(0,0,0,.11);
        height: 0;
        left: 0;
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: 100%;
        -webkit-transition: none;
        transition: none;
        visibility: hidden;
        width: 100%;
    }

        .Nav-panel.js-show {
            height: auto;
            visibility: visible;
        }

            .Nav-panel.js-show .Nav-panel-wrap {
                opacity: 1;
            }

        .Nav-panel:before {
            background-color: #fff;
            content: '\0020';
            height: 10px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 700;
        }
}

.Nav-panel-wrap:after, .Nav-panel-wrap:before {
    content: " ";
    display: table;
}

.Nav-panel-wrap:after {
    clear: both;
}

@media screen and (max-width:64em) {
    .Nav-panel-wrap {
        padding-bottom: 15px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media screen and (min-width:64em) {
    .Nav-panel-wrap {
        opacity: 0;
        padding-bottom: 30px;
        padding-top: 20px;
        position: relative;
        -webkit-transition: opacity 450ms ease-out;
        transition: opacity 450ms ease-out;
    }
}

.Nav-panel-content:after, .Nav-panel-content:before {
    content: " ";
    display: table;
}

.Nav-panel-content:after {
    clear: both;
}

@media screen and (min-width:64em) {
    .Nav-panel-content {
        float: left;
        padding-right: 210px;
        margin-right: -210px;
        width: 100%;
    }
}

.no-js .Nav-sublist {
    display: none;
}

@media screen and (min-width:64em) {
    .Nav-sublist {
        margin-left: -30px;
        overflow: hidden;
        position: relative;
    }

        .Nav-sublist:after {
            background-color: #fff;
            bottom: 0;
            content: '\0020';
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
}

@media screen and (min-width:64em) {
    .Nav-sublist-item {
        float: left;
        padding-left: 30px;
        width: 48%;
    }

        .Nav-sublist-item:nth-child(odd) {
            clear: left;
        }
}

.Nav-sublist-item-link {
    display: inline-block;
    padding: 8px 0;
}

    .Nav-sublist-item-link, .Nav-sublist-item-link:link {
        color: #006694;
    }

        .Nav-sublist-item-link:visited {
            color: #006694;
        }

        .Nav-sublist-item-link:active, .Nav-sublist-item-link:focus, .Nav-sublist-item-link:hover {
            color: #12b5f7;
        }

@media screen and (min-width:64em) {
    .Nav-sublist-item-link {
        border-bottom: 1px solid #ccc;
        display: block;
        padding: 13px 0;
    }

        .Nav-sublist-item-link, .Nav-sublist-item-link:link {
            color: #454545;
        }

            .Nav-sublist-item-link:visited {
                color: #454545;
            }

            .Nav-sublist-item-link:active, .Nav-sublist-item-link:focus, .Nav-sublist-item-link:hover {
                color: #12b5f7;
            }
}

.Nav-promo {
    display: none;
    float: left;
    padding-left: 30px;
    width: 210px;
}

.aos .Nav-promo, .ios .Nav-promo {
    border-left: 1px solid #ccc;
}

    .aos .Nav-promo:before, .ios .Nav-promo:before {
        display: none;
    }

.Nav-promo:before {
    background-image: -webkit-radial-gradient(right center,15px 50%,#dfdfdf 0,#f4f4f4 50%,transparent 100%);
    background-image: radial-gradient(to left center,15px 50%,#dfdfdf 0,#f4f4f4 50%,transparent 100%);
    content: '\0020';
    height: 100%;
    margin-left: -30px;
    position: absolute;
    top: 0;
    width: 20px;
}

.Nav-promo a, .Nav-promo a:link {
    color: #08a7e7;
}

    .Nav-promo a:visited {
        color: #08a7e7;
    }

    .Nav-promo a:active, .Nav-promo a:focus, .Nav-promo a:hover {
        color: #078ac0;
    }

@media screen and (min-width:62em) {
    .Nav-promo {
        display: block;
    }
}

.Nav-promo-media {
    margin-bottom: 10px;
}

.Nav-promo-media-icon {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    height: 104px;
    text-align: center;
    width: 104px;
}

    .Nav-promo-media-icon svg {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        fill: #fff;
    }

.no-csstransforms .Nav-promo-media-icon svg {
    top: auto;
    position: static;
}

.Nav-promo-media img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.Nav-promo-description {
    line-height: 1.4;
    margin-bottom: 10px;
}

.Nav-footer {
    font-weight: 600;
    font-size: .882353em;
}

.fonts-loaded .Nav-footer {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.no-js .Nav-footer {
    display: none;
}

.Nav-footer a {
    display: block;
    padding: 8px 0;
}

    .Nav-footer a, .Nav-footer a:link {
        color: #08a7e7;
    }

        .Nav-footer a:visited {
            color: #08a7e7;
        }

        .Nav-footer a:active, .Nav-footer a:focus, .Nav-footer a:hover {
            color: #078ac0;
        }

@media screen and (min-width:64em) {
    .Nav-footer {
        border-top: 1px solid #ccc;
        margin-right: 4%;
        padding-top: 12px;
    }

        .Nav-footer a {
            display: inline-block;
        }
}

@media screen and (min-width:60em) {
    .FeaturedStory {
        border: 1px solid #ccc;
        padding: 20px;
    }
}

.Pagination:after, .Pagination:before {
    content: " ";
    display: table;
}

.Pagination:after {
    clear: both;
}

@media print {
    .Pagination {
        display: none !important;
    }
}

.Pagination-list {
    font-size: .882353em;
    text-align: center;
}

    .Pagination-list:after, .Pagination-list:before {
        content: " ";
        display: table;
    }

    .Pagination-list:after {
        clear: both;
    }

@media screen and (min-width:32.5em) {
    .Pagination-list {
        text-align: left;
    }
}

.Pagination-item {
    border: 1px solid transparent;
    display: inline-block;
}

.Pagination-item--active {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #006694;
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}

.Pagination-item--next, .Pagination-item--prev {
    border-color: #ccc;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

    .Pagination-item--next:active, .Pagination-item--next:focus, .Pagination-item--next:hover, .Pagination-item--prev:active, .Pagination-item--prev:focus, .Pagination-item--prev:hover {
        border-color: #08a7e7;
    }

.Pagination-item--first, .Pagination-item--last {
    display: none;
}

@media screen and (min-width:24.375em) {
    .Pagination-item--first, .Pagination-item--last {
        display: inline-block;
    }
}

.Pagination-link {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}

    .Pagination-link:active, .Pagination-link:focus, .Pagination-link:hover {
        background-color: #f2f2f2;
    }

    .Pagination-link svg {
        fill: currentColor;
        margin-top: -3px;
        -webkit-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
        vertical-align: middle;
    }

.Pagination-ellip {
    color: #999;
    display: inline-block;
}

.PromoStrata-heading {
    font-weight: 400;
    font-size: 1.294118rem;
    line-height: 1.1;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fonts-loaded .PromoStrata-heading {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .PromoStrata-heading {
        font-size: 129.411765%;
    }
}

@media screen and (min-width:25.125em) {
    .PromoStrata-heading {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:30.25em) {
    .PromoStrata-heading {
        font-size: 152.941176%;
    }
}

@media screen and (min-width:35.375em) {
    .PromoStrata-heading {
        font-size: 164.705882%;
    }
}

@media screen and (min-width:40.5em) {
    .PromoStrata-heading {
        font-size: 176.470588%;
    }
}

@media screen and (min-width:45.625em) {
    .PromoStrata-heading {
        font-size: 188.235294%;
    }
}

@media screen and (min-width:50.75em) {
    .PromoStrata-heading {
        font-size: 200%;
    }
}

@media screen and (min-width:55.875em) {
    .PromoStrata-heading {
        font-size: 211.764706%;
    }
}

.PromoStrata-heading b {
    font-weight: 600;
}

.PromoStrata-list:after, .PromoStrata-list:before {
    content: " ";
    display: table;
}

.PromoStrata-list:after {
    clear: both;
}

@media screen and (min-width:41.25em) {
    .PromoStrata-list {
        margin-left: -20px;
    }
}

.PromoStrata-item {
    margin-bottom: 20px;
}

    .PromoStrata-item:after, .PromoStrata-item:before {
        content: " ";
        display: table;
    }

    .PromoStrata-item:after {
        clear: both;
    }

@media screen and (min-width:26.25em) {
    .PromoStrata-item {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:33.6875em) {
    .PromoStrata-item {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:41.125em) {
    .PromoStrata-item {
        font-size: 100%;
    }
}

@media screen and (min-width:41.25em) {
    .PromoStrata-item {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:48.75em) {
    .PromoStrata-item {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:56.25em) {
    .PromoStrata-item {
        font-size: 100%;
    }
}

@media screen and (min-width:41.25em) {
    .PromoStrata-item {
        float: left;
        padding-left: 20px;
        width: 33.333333%;
    }
}

.PromoStrata-wrap {
    position: relative;
}

    .PromoStrata-wrap:active .PromoStrata-title-link, .PromoStrata-wrap:focus .PromoStrata-title-link, .PromoStrata-wrap:hover .PromoStrata-title-link {
        color: #08a7e7;
    }

    .PromoStrata-wrap:active .PromoStrata-description, .PromoStrata-wrap:focus .PromoStrata-description, .PromoStrata-wrap:hover .PromoStrata-description {
        color: #999;
    }

.PromoStrata-media {
    margin-bottom: .882353em;
    overflow: hidden;
    position: relative;
}

    .PromoStrata-media:before {
        content: '';
        display: block;
        padding-top: 56.25%;
    }

@media screen and (min-width:26.25em) and (max-width:41.1875em) {
    .PromoStrata-media {
        float: left;
        margin-right: 10px;
        width: 40%;
    }
}

.PromoStrata-media-inner {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.PromoStrata-media img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.no-objectfit .PromoStrata-media img {
    height: auto;
    position: absolute;
    top: 0;
}

@media screen and (min-width:26.25em) and (max-width:41.1875em) {
    .PromoStrata-content {
        overflow: hidden;
    }
}

.PromoStrata-title {
    font-size: 1.058824em;
    font-weight: 600;
    line-height: 1.3;
}

.PromoStrata-title-link, .PromoStrata-title-link:link {
    color: #454545;
}

    .PromoStrata-title-link:visited {
        color: #454545;
    }

    .PromoStrata-title-link:active, .PromoStrata-title-link:focus, .PromoStrata-title-link:hover {
        color: #12b5f7;
    }

.PromoStrata-description {
    font-size: .941176em;
    margin-top: .294118em;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.PromoStrata-meta {
    color: #999;
    font-size: .882353em;
    margin-top: .294118em;
}

.PromoStrata-more {
    display: inline-block;
    font-size: .941176em;
    margin-top: .294118em;
    position: relative;
    z-index: 1;
}

    .PromoStrata-more:active, .PromoStrata-more:focus, .PromoStrata-more:hover {
        color: #078ac0;
    }

[data-set] {
    display: none;
}

.u-show {
    display: block;
}

@media screen and (min-width:20em) {
    .js .u-hide--narrow {
        display: none !important;
    }

    .js .u-show--narrow {
        display: block;
    }
}

@media screen and (min-width:30em) {
    .js .u-hide--small {
        display: none !important;
    }

    .js .u-show--small {
        display: block;
    }
}

@media screen and (min-width:40em) {
    .js .u-hide--medium {
        display: none !important;
    }

    .js .u-show--medium {
        display: block;
    }
}

@media screen and (min-width:48em) {
    .js .u-hide--med-wide {
        display: none !important;
    }

    .js .u-show--med-wide {
        display: block;
    }
}

@media screen and (min-width:62em) {
    .js .u-hide--wide {
        display: none !important;
    }

    .js .u-show--wide {
        display: block;
    }
}

@media screen and (min-width:64em) {
    .js .u-hide--x-wide {
        display: none !important;
    }

    .js .u-show--x-wide {
        display: block;
    }
}

@media screen and (min-width:51.875em) {
    .js .u-hide--sidebar {
        display: none !important;
    }

    .js .u-show--sidebar {
        display: block;
    }
}

.SearchBox:after, .SearchBox:before {
    content: " ";
    display: table;
}

.SearchBox:after {
    clear: both;
}

@media print {
    .SearchBox {
        display: none !important;
    }
}

.SearchBox-input[type=search] {
    float: left;
    font-size: .823529em;
    height: 2.647059rem;
    margin-right: -45px;
    padding-right: 55px;
}

    .SearchBox-input[type=search]::-webkit-input-placeholder {
        color: #999;
    }

    .SearchBox-input[type=search]::-moz-placeholder {
        color: #999;
    }

    .SearchBox-input[type=search]:-ms-input-placeholder {
        color: #999;
    }

    .SearchBox-input[type=search]::placeholder {
        color: #999;
    }

@media screen and (min-width:64em) {
    .Header .SearchBox-input[type=search] {
        height: 1.764706rem;
        margin-right: -30px;
        padding-bottom: 4px;
        padding-left: 10px;
        padding-right: 40px;
        padding-top: 4px;
    }
}

.SearchBox-submit {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2026%2028%22%20width=%2226%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M18%2013q0-2.9-2.06-4.95T11%206%206.05%208.06%204%2013t2.05%204.95T11%2020t4.95-2.06T18%2013zm8%2013q0%20.8-.6%201.4T24%2028q-.84%200-1.4-.6l-5.37-5.34Q14.43%2024%2011%2024q-2.23%200-4.27-.87T3.2%2020.8.88%2017.26%200%2013t.87-4.27T3.2%205.2t3.53-2.33T11%202t4.27.87T18.8%205.2t2.33%203.53T22%2013q0%203.44-1.94%206.23l5.36%205.36q.58.57.58%201.4z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 18px auto;
    background-size: 18px auto;
    color: transparent;
    float: left;
    height: 2.647059rem;
    padding: 0;
    text-shadow: none;
    width: 45px;
}

.no-svg .SearchBox-submit {
    background-image: url(../images/icons/png/search-white.png);
}

@media screen and (min-width:64em) {
    .Header .SearchBox-submit {
        background-color: transparent;
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2026%2028%22%20width=%2226%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23b0b0b0%22%3E%3Cpath%20d=%22M18%2013q0-2.9-2.06-4.95T11%206%206.05%208.06%204%2013t2.05%204.95T11%2020t4.95-2.06T18%2013zm8%2013q0%20.8-.6%201.4T24%2028q-.84%200-1.4-.6l-5.37-5.34Q14.43%2024%2011%2024q-2.23%200-4.27-.87T3.2%2020.8.88%2017.26%200%2013t.87-4.27T3.2%205.2t3.53-2.33T11%202t4.27.87T18.8%205.2t2.33%203.53T22%2013q0%203.44-1.94%206.23l5.36%205.36q.58.57.58%201.4z%22%2F%3E%3C%2Fsvg%3E);
        -webkit-background-size: 13px auto;
        background-size: 13px auto;
        height: 1.764706rem;
        width: 30px;
    }

        .Header .SearchBox-submit:active, .Header .SearchBox-submit:focus, .Header .SearchBox-submit:hover {
            background-color: transparent;
        }
}

.SearchBox--large .SearchBox-submit {
    background-color: transparent;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2026%2028%22%20width=%2226%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M18%2013q0-2.9-2.06-4.95T11%206%206.05%208.06%204%2013t2.05%204.95T11%2020t4.95-2.06T18%2013zm8%2013q0%20.8-.6%201.4T24%2028q-.84%200-1.4-.6l-5.37-5.34Q14.43%2024%2011%2024q-2.23%200-4.27-.87T3.2%2020.8.88%2017.26%200%2013t.87-4.27T3.2%205.2t3.53-2.33T11%202t4.27.87T18.8%205.2t2.33%203.53T22%2013q0%203.44-1.94%206.23l5.36%205.36q.58.57.58%201.4z%22%2F%3E%3C%2Fsvg%3E);
    -webkit-background-size: 23px auto;
    background-size: 23px auto;
}

.no-inlinesvg .SearchBox--large .SearchBox-submit {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -35px -305px;
    width: 23px;
    height: 25px;
}

.SearchBox--large .SearchBox-input[type=search] {
    font-size: 1.411765em;
    padding-bottom: 10px;
    padding-left: 20px;
}

@media screen and (min-width:64em) {
    .SearchBox--large .SearchBox-input[type=search] {
        font-size: 1.647059em;
    }
}

.SectionHeading {
    margin-left: auto;
    margin-right: auto;
    max-width: 780px;
    text-align: center;
}

@media screen and (min-width:37.5em) {
    .SectionHeading {
        width: 90%;
    }
}

.SectionHeading-title {
    font-weight: 600;
    color: #006694;
    font-size: 1.647059em;
    line-height: 1.25;
    margin-bottom: 15px;
}

.fonts-loaded .SectionHeading-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .SectionHeading-title {
        font-size: 176.470588%;
    }
}

@media screen and (min-width:33.3125em) {
    .SectionHeading-title {
        font-size: 188.235294%;
    }
}

@media screen and (min-width:46.625em) {
    .SectionHeading-title {
        font-size: 200%;
    }
}

@media screen and (min-width:59.9375em) {
    .SectionHeading-title {
        font-size: 211.764706%;
    }
}

.SectionHeading-title svg {
    fill: #08a7e7;
    margin-left: 4px;
    vertical-align: baseline;
}

.SectionHeading-subtitle {
    font-weight: 400;
    color: #08a7e7;
    font-size: 1.058824em;
    line-height: 1.5;
}

.fonts-loaded .SectionHeading-subtitle {
    font-family: "Rock Salt","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .SectionHeading-subtitle {
        font-size: 111.764706%;
        line-height: 1.6;
    }
}

@media screen and (min-width:40em) {
    .SectionHeading-subtitle {
        font-size: 123.529412%;
        line-height: 1.45;
    }
}

@media print {
    .ShareButtons {
        display: none !important;
    }
}

.ShareButtons-list:after, .ShareButtons-list:before {
    content: " ";
    display: table;
}

.ShareButtons-list:after {
    clear: both;
}

@media screen and (min-width:21.25em) {
    .ShareButtons-list {
        margin-left: -12px;
    }
}

.ShareButtons-item {
    margin-bottom: 12px;
}

@media screen and (min-width:21.25em) {
    .ShareButtons-item {
        float: left;
        padding-left: 12px;
        width: 50%;
    }
}

@media screen and (min-width:40.625em) {
    .ShareButtons-item {
        width: 25%;
    }
}

@media screen and (min-width:51.875em) {
    .ShareButtons-item {
        width: 50%;
    }
}

@media screen and (min-width:61.25em) {
    .ShareButtons-item {
        width: 25%;
    }
}

.ShareButtons-link {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: .823529rem;
    letter-spacing: 1px;
    line-height: 1;
    padding: .882353rem 1.764706rem .823529rem;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all 150ms ease-out;
    transition: all 150ms ease-out;
    word-spacing: 2px;
    display: block;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    width: 100%;
}

.fonts-loaded .ShareButtons-link {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ShareButtons-link.is-disabled, .ShareButtons-link:disabled {
    background-color: #d8d8d8 !important;
    color: rgba(51,51,51,.5) !important;
    cursor: not-allowed !important;
    text-shadow: none !important;
}

.ShareButtons-link svg {
    fill: #fff;
    position: relative;
    top: 1px;
    vertical-align: text-bottom;
}

.ShareButtons-link--facebook, .ShareButtons-link--facebook:link, .ShareButtons-link--facebook:visited {
    background-color: #3b5998;
    color: #fff;
    text-shadow: 1px 1px 1px #2d4373;
}

    .ShareButtons-link--facebook:active, .ShareButtons-link--facebook:focus, .ShareButtons-link--facebook:hover {
        background-color: #344e86;
    }

.ShareButtons-link--twitter, .ShareButtons-link--twitter:link, .ShareButtons-link--twitter:visited {
    background-color: #55acee;
    color: #fff;
    text-shadow: 1px 1px 1px #2795e9;
}

    .ShareButtons-link--twitter:active, .ShareButtons-link--twitter:focus, .ShareButtons-link--twitter:hover {
        background-color: #3ea1ec;
    }

.ShareButtons-link--email, .ShareButtons-link--email:link, .ShareButtons-link--email:visited {
    background-color: #f0b800;
    color: #fff;
    text-shadow: 1px 1px 1px #bd9100;
}

    .ShareButtons-link--email:active, .ShareButtons-link--email:focus, .ShareButtons-link--email:hover {
        background-color: #d7a400;
    }

.no-js .ShareButtons-link--email {
    display: none;
}

.ShareButtons-link--more, .ShareButtons-link--more:link, .ShareButtons-link--more:visited {
    background-color: #666;
    color: #fff;
    text-shadow: 1px 1px 1px #4d4d4d;
}

    .ShareButtons-link--more:active, .ShareButtons-link--more:focus, .ShareButtons-link--more:hover {
        background-color: #595959;
    }

.no-js .ShareButtons-link--more {
    display: none;
}

@media print {
    .ShareLinks {
        display: none !important;
    }
}

.ShareLinks:after, .ShareLinks:before {
    content: " ";
    display: table;
}

.ShareLinks:after {
    clear: both;
}

.no-js .ShareLinks {
    display: none;
}

.ShareLinks-label {
    float: left;
    margin-top: 2px;
}

.ShareLinks-list {
    float: left;
}

.ShareLinks-item {
    float: left;
    margin-left: 8px;
}

.ShareLinks-item--twitter {
    margin-left: 5px;
}

.aos .ShareLinks-item--print {
    display: none;
}

.ShareLinks-link {
    display: inline-block;
    padding: 0 3px;
    vertical-align: middle;
}

@media screen and (min-width:20em) {
    .ShareLinks-link {
        padding-left: 4px;
        padding-right: 4px;
    }
}

button.ShareLinks-link {
    background-color: transparent;
}

    button.ShareLinks-link, button.ShareLinks-link:link {
        color: #08a7e7;
    }

        button.ShareLinks-link:visited {
            color: #08a7e7;
        }

        button.ShareLinks-link:active, button.ShareLinks-link:focus, button.ShareLinks-link:hover {
            color: #078ac0;
        }

.ShareLinks-link svg {
    display: block;
    fill: currentColor;
}

.ShareLinks-link.has-label {
    padding: 0;
}

    .ShareLinks-link.has-label svg {
        float: left;
        margin-left: 10px;
        position: relative;
        top: 2px;
    }

.ShareLinks-link-label {
    color: #454545;
    border-left: 1px solid #ccc;
    float: left;
    margin-left: 5px;
    padding-left: 15px;
    position: relative;
}

@media screen and (min-width:40em) {
    .ShareLinks-link-label {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.ShareLinks-item--email:first-child {
    margin-left: 0;
}

    .ShareLinks-item--email:first-child .has-label .ShareLinks-link-label {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
    }

.ShareModal {
    font-size: .941176rem;
}

.ShareModal-title {
    font-weight: 700;
    font-size: .823529em;
    margin-bottom: 15px;
    padding-right: 93px;
    text-transform: uppercase;
}

.fonts-loaded .ShareModal-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ShareModal a, .ShareModal a:link, .ShareModal a:visited {
    color: #454545;
}

@media screen and (min-width:40em) {
    .ShareModal a, .ShareModal a:link, .ShareModal a:visited {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.ShareModal a:active, .ShareModal a:focus, .ShareModal a:hover {
    color: #12b5f7;
}

.ShareModal-item {
    float: left;
    margin-bottom: 10px;
    width: 50%;
}

    .ShareModal-item svg {
        fill: #08a7e7;
        margin-right: 5px;
        vertical-align: middle;
    }

    .ShareModal-item:nth-child(odd) {
        clear: left;
    }

.ShareModal-item--permalink {
    clear: left;
    margin-top: 10px;
    width: 100%;
}

    .ShareModal-item--permalink label {
        display: block;
        margin-top: 8px;
    }

@media screen and (min-width:20em) {
    .ShareModal-item--permalink label {
        float: left;
        width: 27%;
    }
}

@media screen and (min-width:20em) {
    .ShareModal-item--permalink input {
        float: right;
        width: 66%;
    }
}

@media screen and (min-width:25em) {
    .ShareModal-item--permalink input {
        float: left;
        width: 73%;
    }
}

.ContentColumns-col > .ShareableFact {
    border-bottom: 1px solid #ccc;
    margin-bottom: 23px;
    padding-bottom: 25px;
    padding-top: 11px;
}

@media screen and (min-width:48em) {
    .ContentColumns-col > .ShareableFact {
        margin-bottom: 28px;
    }
}

@media screen and (min-width:48em) {
    .ContentColumns-col > .ShareableFact:first-child {
        padding-top: 0;
    }
}

.ContentColumns-col > .ShareableFact:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.ShareableFact-label {
    font-weight: 700;
    color: #999;
    font-size: .764706em;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.fonts-loaded .ShareableFact-label {
    font-family: "Open Sans","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ShareableFact-text {
    font-weight: 600;
    color: #006694;
    font-size: 1.294118em;
    line-height: 1.36;
    margin-bottom: 6px;
    text-transform: uppercase;
    word-spacing: 1px;
}

.fonts-loaded .ShareableFact-text {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.ShareableFact-text b, .ShareableFact-text strong {
    color: #08a7e7;
    font-weight: 600;
}

@media print {
    .ShareableFact-cta {
        display: none !important;
    }
}

.ShareableFact-cta:after, .ShareableFact-cta:before {
    content: " ";
    display: table;
}

.ShareableFact-cta:after {
    clear: both;
}

.ShareableFact-cta-label {
    color: #666;
    float: left;
    font-size: .882353em;
}

.u-richtext .ShareableFact-cta-label {
    margin-bottom: 0;
}

.ShareableFact-cta-list {
    float: left;
}

.u-richtext .ShareableFact-cta-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ShareableFact-cta-item {
    float: left;
    margin-left: 10px;
}

.u-richtext .ShareableFact-cta-item {
    margin-top: 0;
}

.ShareableFact-cta-link {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 15px;
    background-size: auto 15px;
    display: block;
    height: 24px;
    padding: 2px;
    text-align: center;
    width: 24px;
}

.no-inlinesvg .ShareableFact-cta-link {
    -webkit-background-size: auto auto;
    background-size: auto auto;
}

.ShareableFact-cta-link svg {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    fill: #fff;
    height: 72%;
    width: 100%;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.no-csstransforms .ShareableFact-cta-link svg {
    top: auto;
    position: static;
}

.ShareableFact-cta-link--facebook {
    background-color: #3b5998;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2027%2052%22%20width=%2227%22%20height=%2252%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M27%20.4v8.3h-4.9c-1.8%200-3%20.4-3.6%201.1s-.9%201.9-.9%203.4V19h9.2l-1.2%209.3h-7.9V52H8V28.3H0V19h8v-6.8c0-3.9%201.1-6.9%203.3-9s5-3.2%208.6-3.2c3%200%205.4.1%207.1.4z%22%2F%3E%3C%2Fsvg%3E);
}

.no-inlinesvg .ShareableFact-cta-link--facebook {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -283px -305px;
    width: 20px;
    height: 20px;
}

.ShareableFact-cta-link--twitter {
    background-color: #55acee;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2028%2026%22%20width=%2228%22%20height=%2226%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M25.3%206.38q-1.04%201.53-2.52%202.6.02.23.02.67%200%202.03-.6%204.06t-1.8%203.9-2.87%203.28-4.03%202.28-5.05.85Q4.22%2024%20.7%2021.75q.55.06%201.22.06%203.52%200%206.27-2.16-1.65-.03-2.95-1t-1.78-2.5q.52.08.95.08.67%200%201.33-.17Q4%2015.7%202.85%2014.3T1.7%2011.1v-.06q1.06.6%202.28.64-1.03-.7-1.64-1.8t-.6-2.4q0-1.38.68-2.56%201.9%202.33%204.6%203.73t5.8%201.56q-.1-.58-.1-1.15%200-2.1%201.47-3.57T17.75%204q2.2%200%203.7%201.6%201.7-.34%203.2-1.23-.6%201.8-2.23%202.78%201.45-.16%202.9-.78z%22%2F%3E%3C%2Fsvg%3E);
}

.no-inlinesvg .ShareableFact-cta-link--twitter {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -223px -305px;
    width: 20px;
    height: 20px;
}

.ShareableFact-cta-link--more {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2028%2028%22%20width=%2228%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M28%2010q0%20.4-.3.7l-8%208q-.3.3-.7.3t-.7-.3-.3-.7v-4h-3.5q-1.53%200-2.74.1t-2.4.33-2.1.66-1.63%201.08-1.25%201.58-.76%202.16-.28%202.83q0%20.86.08%201.92%200%20.1.04.37t.04.4q0%20.25-.13.4T3%2026q-.25%200-.44-.27-.1-.14-.2-.34t-.2-.48-.18-.37Q0%2020.1%200%2017.5q0-3.1.83-5.2Q3.36%206%2014.5%206H18V2q0-.4.3-.7T19%201t.7.3l8%208q.3.3.3.7z%22%2F%3E%3C%2Fsvg%3E);
}

    .ShareableFact-cta-link--more svg {
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

.no-inlinesvg .ShareableFact-cta-link--more {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -166px -197px;
    width: 18px;
    height: 18px;
}

.ShareableFact-cta-link:active, .ShareableFact-cta-link:focus, .ShareableFact-cta-link:hover {
    background-color: #f39c23;
}

.ShareableFact--fill {
    background-color: #f39c23;
    padding: 28px 20px 23px;
}

    .ShareableFact--fill svg {
        height: 100%;
    }

    .ShareableFact--fill .ShareableFact-label {
        color: #fff;
    }

    .ShareableFact--fill .ShareableFact-text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #fff;
        font-size: 1.294118em;
        font-weight: 400;
    }

@media screen and (min-width:32.5em) {
    .ShareableFact--fill .ShareableFact-text {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:37.3125em) {
    .ShareableFact--fill .ShareableFact-text {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:42.125em) {
    .ShareableFact--fill .ShareableFact-text {
        font-size: 117.647059%;
    }
}

@media screen and (min-width:46.9375em) {
    .ShareableFact--fill .ShareableFact-text {
        font-size: 123.529412%;
    }
}

@media screen and (min-width:51.75em) {
    .ShareableFact--fill .ShareableFact-text {
        font-size: 129.411765%;
    }
}

.ShareableFact--fill .ShareableFact-text b {
    color: #fff;
    font-weight: 700;
}

.ShareableFact--fill .ShareableFact-cta-item {
    margin-left: 0;
}

.ShareableFact--fill .ShareableFact-cta-label {
    color: #fff;
    font-size: .764706em;
    margin-right: 6px;
}

.ShareableFact--fill .ShareableFact-cta-link {
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
    height: 20px;
    padding: 0;
    width: 20px;
}

.ShareableFact--fill .ShareableFact-cta-link--facebook, .ShareableFact--fill .ShareableFact-cta-link--more, .ShareableFact--fill .ShareableFact-cta-link--twitter {
    background-color: transparent;
}

    .ShareableFact--fill .ShareableFact-cta-link--facebook svg, .ShareableFact--fill .ShareableFact-cta-link--more svg, .ShareableFact--fill .ShareableFact-cta-link--twitter svg {
        fill: #fff;
    }

    .ShareableFact--fill .ShareableFact-cta-link--facebook:active svg, .ShareableFact--fill .ShareableFact-cta-link--facebook:focus svg, .ShareableFact--fill .ShareableFact-cta-link--facebook:hover svg, .ShareableFact--fill .ShareableFact-cta-link--more:active svg, .ShareableFact--fill .ShareableFact-cta-link--more:focus svg, .ShareableFact--fill .ShareableFact-cta-link--more:hover svg, .ShareableFact--fill .ShareableFact-cta-link--twitter:active svg, .ShareableFact--fill .ShareableFact-cta-link--twitter:focus svg, .ShareableFact--fill .ShareableFact-cta-link--twitter:hover svg {
        fill: #08a7e7;
    }

.ShareableFact--fill .ShareableFact-cta-link--facebook {
    margin-right: 8px;
}

    .ShareableFact--fill .ShareableFact-cta-link--facebook svg {
        height: 91%;
    }

.ShareableFact--fill .ShareableFact-cta-link--twitter {
    margin-right: 12px;
}

.Component {
    border-top: 1px solid #ccc;
    margin-top: 30px;
    padding-top: 20px;
}

.Main-primary .Component {
    border-top: none;
    padding-top: 0;
}

.Component--textSize {
    font-size: .823529em;
}

@media screen and (min-width:51.875em) {
    .Component {
        border-top: none;
        padding-top: 0;
    }

        .Component:first-child {
            margin-top: 0;
        }
}

.Component a {
    font-weight: 600;
}

.Component .Button {
    font-weight: 700;
}

.Component-header {
    font-weight: 700;
    color: #454545;
    font-size: .823529rem;
    line-height: 1.285;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fonts-loaded .Component-header {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.Component-header--large {
    font-size: .941176rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: none;
}

@media screen and (min-width:20em) {
    .Component-header--large {
        font-size: .941176rem;
    }
}

@media screen and (min-width:40em) {
    .Component-header--large {
        font-size: 1rem;
    }
}

.fonts-loaded .Component-header--large {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .Component-header--large {
    text-rendering: auto;
}

.Component-item {
    margin-top: 10px;
}

    .Component-item:first-child {
        margin-top: 0;
    }

.Component-list--bullets {
    text-transform: uppercase;
}

    .Component-list--bullets > li {
        padding-left: .941176rem;
        position: relative;
    }

@media print {
    .Component-list--bullets > li {
        list-style: disc;
        padding-left: 0;
    }
}

.Component-list--bullets > li:before {
    background-color: #08a7e7;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '\0020';
    height: 6px;
    left: 0;
    position: absolute;
    top: .5em;
    width: 6px;
}

@media print {
    .Component-list--bullets > li:before {
        display: none;
    }
}

.SkipLinks a {
    font-size: .647059em;
    position: absolute;
    right: 20px;
    top: -200px;
    z-index: -1;
}

@media screen and (min-width:48em) {
    .SkipLinks a {
        left: 20px;
        right: auto;
    }
}

.SkipLinks a:focus {
    top: 10px;
    z-index: 999;
}

.SocialButtons {
    margin-left: -10px;
}

    .SocialButtons:after, .SocialButtons:before {
        content: " ";
        display: table;
    }

    .SocialButtons:after {
        clear: both;
    }

.SocialButtons-item {
    float: left;
    padding-left: 10px;
    width: 20%;
}

@media screen and (min-width:51.875em) {
    .SocialButtons {
        margin-left: -5px;
    }

    .SocialButtons-item {
        padding-left: 5px;
    }
}

.SocialButtons-link {
    display: block;
    height: 50px;
    text-align: center;
    width: 100%;
}

@media screen and (min-width:48em) {
    .ExplainerSection .SocialButtons-link {
        height: 40px;
    }
}

.SocialButtons-link svg {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    fill: #fff;
    margin: 0 auto;
    vertical-align: middle;
}

.no-csstransforms .SocialButtons-link svg {
    top: auto;
    position: static;
}

.SocialButtons-link--facebook, .SocialButtons-link--facebook:link, .SocialButtons-link--facebook:visited {
    background-color: #3b5998;
    color: #fff;
    text-shadow: 1px 1px 1px #2d4373;
}

    .SocialButtons-link--facebook:active, .SocialButtons-link--facebook:focus, .SocialButtons-link--facebook:hover {
        background-color: #344e86;
    }

.SocialButtons-link--twitter, .SocialButtons-link--twitter:link, .SocialButtons-link--twitter:visited {
    background-color: #55acee;
    color: #fff;
    text-shadow: 1px 1px 1px #2795e9;
}

    .SocialButtons-link--twitter:active, .SocialButtons-link--twitter:focus, .SocialButtons-link--twitter:hover {
        background-color: #3ea1ec;
    }

.SocialButtons-link--youtube, .SocialButtons-link--youtube:link, .SocialButtons-link--youtube:visited {
    background-color: #d62424;
    color: #fff;
    text-shadow: 1px 1px 1px #aa1d1d;
}

    .SocialButtons-link--youtube:active, .SocialButtons-link--youtube:focus, .SocialButtons-link--youtube:hover {
        background-color: #c02020;
    }

.SocialButtons-link--instagram, .SocialButtons-link--instagram:link, .SocialButtons-link--instagram:visited {
    background-color: #2a5b83;
    color: #fff;
    text-shadow: 1px 1px 1px #1e405c;
}

    .SocialButtons-link--instagram:active, .SocialButtons-link--instagram:focus, .SocialButtons-link--instagram:hover {
        background-color: #244e70;
    }

.SocialButtons-link--email, .SocialButtons-link--email:link, .SocialButtons-link--email:visited {
    background-color: #f0b800;
    color: #fff;
    text-shadow: 1px 1px 1px #bd9100;
}

    .SocialButtons-link--email:active, .SocialButtons-link--email:focus, .SocialButtons-link--email:hover {
        background-color: #d7a400;
    }

.SocialNetworks {
    float: right;
}

.SocialNetworks-item {
    float: left;
    margin-left: 10px;
}

    .SocialNetworks-item:first-child {
        margin-left: 0;
    }

.SocialNetworks-link {
    display: block;
}

.SocialNetworks svg {
    fill: currentColor;
}

.SortLinks:after, .SortLinks:before {
    content: " ";
    display: table;
}

.SortLinks:after {
    clear: both;
}

.SortLinks-label {
    float: left;
}

.SortLinks-list {
    float: left;
}

    .SortLinks-list:after, .SortLinks-list:before {
        content: " ";
        display: table;
    }

    .SortLinks-list:after {
        clear: both;
    }

.SortLinks-item {
    border-left: 1px solid #ccc;
    float: left;
    font-weight: 600;
    margin-left: 8px;
    padding-left: 8px;
}

    .SortLinks-item:first-child {
        border-left: none;
        padding-left: 0;
    }

.SortLinks-link--active, .SortLinks-link--active:link {
    color: #454545;
}

    .SortLinks-link--active:visited {
        color: #454545;
    }

    .SortLinks-link--active:active, .SortLinks-link--active:focus, .SortLinks-link--active:hover {
        color: #12b5f7;
    }

.SubtitleBlock {
    max-width: 820px;
}

.SubtitleBlock-title {
    font-weight: 700;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    font-size: 1.176471rem;
    margin-bottom: 20px;
}

.fonts-loaded .SubtitleBlock-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .SubtitleBlock-title {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .SubtitleBlock-title {
        font-size: 1.294118rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .SubtitleBlock-title {
        font-size: 1.411765rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .SubtitleBlock-title {
        font-size: 1.529412rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .SubtitleBlock-title {
        font-size: 1.647059rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .SubtitleBlock-title {
        font-size: 1.764706rem;
        line-height: 1.2;
    }
}

.SubtitleBlock-title + .SubtitleBlock-description {
    margin-top: -7px;
}

.Taglist-label {
    display: inline-block;
    padding-right: .294118rem;
}

.Taglist-tags {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    display: inline-block;
}

    .Taglist-tags > li {
        display: inline;
    }

        .Taglist-tags > li:after {
            content: ', ';
            display: inline-block;
        }

        .Taglist-tags > li:last-child:after {
            display: none;
        }

.Taglist-tag {
    color: #999;
    font-weight: 600;
}

.TextPromo {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding-bottom: 25px;
    padding-top: 25px;
}

@media print {
    .TextPromo {
        display: none !important;
    }
}

.TextPromo:after, .TextPromo:before {
    content: " ";
    display: table;
}

.TextPromo:after {
    clear: both;
}

.TextPromo-link {
    display: block;
}

    .TextPromo-link svg, .TextPromo-link:active svg, .TextPromo-link:focus svg, .TextPromo-link:hover svg, .TextPromo-link:link svg, .TextPromo-link:visited svg {
        fill: #fff;
    }

    .TextPromo-link:active, .TextPromo-link:focus, .TextPromo-link:hover {
        text-decoration: none;
    }

        .TextPromo-link:active .TextPromo-title, .TextPromo-link:focus .TextPromo-title, .TextPromo-link:hover .TextPromo-title {
            color: #08a7e7;
        }

.TextPromo-thumbnail {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 70px;
    float: left;
    margin-right: 15px;
    padding: 10px;
    width: 70px;
}

    .TextPromo-thumbnail svg {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        width: 100%;
    }

.no-csstransforms .TextPromo-thumbnail svg {
    top: auto;
    position: static;
}

.TextPromo-content {
    overflow: hidden;
}

.TextPromo-title {
    font-weight: 600;
    color: #454545;
    font-size: 1.058824em;
    line-height: 1.333;
    margin-bottom: .294118rem;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.fonts-loaded .TextPromo-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.TextPromo-description {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666;
    font-size: .882353em;
}

.TitleBlock-label {
    font-weight: 700;
    color: #006694;
    font-size: .882353em;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.fonts-loaded .TitleBlock-label {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:48em) {
    .TitleBlock-label {
        margin-bottom: 14px;
    }
}

.TitleBlock-title {
    font-size: 1.764706rem;
    font-weight: 700;
    color: #454545;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .TitleBlock-title {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:24.625em) {
    .TitleBlock-title {
        font-size: 2.058824rem;
    }
}

@media screen and (min-width:29.25em) {
    .TitleBlock-title {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:33.875em) {
    .TitleBlock-title {
        font-size: 2.411765rem;
    }
}

@media screen and (min-width:38.5em) {
    .TitleBlock-title {
        font-size: 2.588235rem;
    }
}

@media screen and (min-width:43.125em) {
    .TitleBlock-title {
        font-size: 2.764706rem;
    }
}

@media screen and (min-width:47.75em) {
    .TitleBlock-title {
        font-size: 2.941176rem;
    }
}

.fonts-loaded .TitleBlock-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .TitleBlock-title {
    text-rendering: auto;
}

@media screen and (min-width:20em) {
    .Main-primary .TitleBlock-title {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:27em) {
    .Main-primary .TitleBlock-title {
        font-size: 2rem;
    }
}

@media screen and (min-width:34em) {
    .Main-primary .TitleBlock-title {
        font-size: 2.117647rem;
    }
}

@media screen and (min-width:41em) {
    .Main-primary .TitleBlock-title {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:48em) {
    .Main-primary .TitleBlock-title {
        font-size: 2.352941rem;
    }
}

.TitleBlock-subtitle {
    font-size: 1.176471rem;
    font-weight: 600;
    color: #454545;
    line-height: 1.4;
    margin-top: 20px;
}

@media screen and (min-width:20em) {
    .TitleBlock-subtitle {
        font-size: 1.176471rem;
        line-height: 1.4;
    }
}

@media screen and (min-width:34em) {
    .TitleBlock-subtitle {
        font-size: 1.294118rem;
        line-height: 1.35;
    }
}

@media screen and (min-width:48em) {
    .TitleBlock-subtitle {
        font-size: 1.411765rem;
        line-height: 1.3;
    }
}

.fonts-loaded .TitleBlock-subtitle {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.TitleBlock--alt .TitleBlock-title {
    font-size: 1.647059rem;
}

@media screen and (min-width:20em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 1.647059rem;
    }
}

@media screen and (min-width:24.625em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 1.764706rem;
    }
}

@media screen and (min-width:29.25em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 1.882353rem;
    }
}

@media screen and (min-width:33.875em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 2rem;
    }
}

@media screen and (min-width:38.5em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 2.117647rem;
    }
}

@media screen and (min-width:43.125em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 2.235294rem;
    }
}

@media screen and (min-width:47.75em) {
    .TitleBlock--alt .TitleBlock-title {
        font-size: 2.352941rem;
    }
}

.TitleBlock--small .TitleBlock-title {
    font-size: 1.176471rem;
    font-weight: 700;
    color: #454545;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
}

@media screen and (min-width:20em) {
    .TitleBlock--small .TitleBlock-title {
        font-size: 1.294118rem;
        line-height: 1.36;
    }
}

@media screen and (min-width:30em) {
    .TitleBlock--small .TitleBlock-title {
        font-size: 1.411765rem;
        line-height: 1.32;
    }
}

@media screen and (min-width:40em) {
    .TitleBlock--small .TitleBlock-title {
        font-size: 1.529412rem;
        line-height: 1.28;
    }
}

@media screen and (min-width:50em) {
    .TitleBlock--small .TitleBlock-title {
        font-size: 1.647059rem;
        line-height: 1.24;
    }
}

@media screen and (min-width:60em) {
    .TitleBlock--small .TitleBlock-title {
        font-size: 1.764706rem;
        line-height: 1.2;
    }
}

.fonts-loaded .TitleBlock--small .TitleBlock-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.webos .TitleBlock--small .TitleBlock-title {
    text-rendering: auto;
}

.TitleBlock-content, .TitleBlock-intro {
    margin-top: 23px;
    max-width: 820px;
}

.TitleBlock-intro {
    font-size: .941176em;
    line-height: 1.4;
}

@media screen and (min-width:20em) {
    .TitleBlock-intro {
        font-size: 105.882353%;
        line-height: 1.4;
    }
}

@media screen and (min-width:25em) {
    .TitleBlock-intro {
        font-size: 111.764706%;
        line-height: 1.45;
    }
}

@media screen and (min-width:30em) {
    .TitleBlock-intro {
        font-size: 117.647059%;
        line-height: 1.5;
    }
}

@media screen and (min-width:35em) {
    .TitleBlock-intro {
        font-size: 123.529412%;
        line-height: 1.55;
    }
}

@media screen and (min-width:40em) {
    .TitleBlock-intro {
        font-size: 129.411765%;
        line-height: 1.6;
    }
}

.TitleBlock .ShareLinks {
    margin-top: 12px;
}

    .TitleBlock .ShareLinks + .TitleBlock-intro {
        margin-top: 15px;
    }

.TitleBlock--author {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 37px;
}

    .TitleBlock--author .TitleBlock-content, .TitleBlock--author .TitleBlock-intro {
        font-size: .882353em;
        line-height: 1.333;
    }

    .TitleBlock--author .TitleBlock-content {
        margin-top: 1em;
    }

[data-tooltip] {
    border-bottom: 2px dotted #ccc;
    cursor: help;
    display: inline-block;
    -webkit-transition: border-color 150ms ease-in-out;
    transition: border-color 150ms ease-in-out;
    z-index: 10;
}

@media screen and (min-width:22.5em) {
    [data-tooltip] {
        position: relative;
    }
}

[data-tooltip]:hover {
    border-color: #777;
    color: #454545;
}

[data-whatinput=mouse] [data-tooltip]:focus, [data-whatinput=touch] [data-tooltip]:focus {
    outline: 0;
}

[data-tooltip]:before {
    height: 0;
    width: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #f2f2f2;
    display: none;
    content: '\0020';
    margin-left: 10px;
    margin-top: -5px;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    z-index: -1;
}

@media screen and (min-width:22.5em) {
    [data-tooltip]:before {
        left: 50%;
        margin-left: -8px;
        margin-top: 0;
        top: -5px;
    }
}

[data-tooltip].js-active {
    cursor: pointer;
}

    [data-tooltip].js-active:before {
        display: block;
        opacity: 1;
        z-index: 301;
    }

.Tooltip {
    background-color: #f2f2f2;
    -webkit-box-shadow: 0 2px 6px 5px #fff;
    box-shadow: 0 2px 6px 5px #fff;
    color: #454545;
    cursor: auto;
    font-size: .764706rem;
    left: 0;
    line-height: 1.5;
    margin-top: -5px;
    opacity: 0;
    padding: 15px 20px;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: visibility 0s linear 250ms,opacity 250ms;
    transition: visibility 0s linear 250ms,opacity 250ms;
    visibility: hidden;
}

@media screen and (min-width:22.5em) {
    .Tooltip {
        left: 0;
        right: auto;
        top: 0;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        width: 275px;
    }

        .Tooltip[data-align=center] {
            left: 0;
            margin-left: 50%;
            -webkit-transform: translateX(-50%) translateY(-100%);
            -ms-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
        }

        .Tooltip[data-align=right] {
            left: auto;
            margin-left: 0;
            right: 0;
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
        }
}

.Tooltip.js-show {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s linear 0s,opacity 250ms;
    transition: visibility 0s linear 0s,opacity 250ms;
}

.Tooltip p {
    margin-top: .294118rem;
}

    .Tooltip p:first-child {
        margin-top: 0;
    }

.Tooltip a {
    font-weight: 600;
}

.TopicBillboard {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #b0b0b0;
    color: #fff;
    overflow: hidden;
    position: relative;
}

    .TopicBillboard a, .TopicBillboard a:link {
        color: #fff;
    }

        .TopicBillboard a:visited {
            color: #fff;
        }

        .TopicBillboard a:active, .TopicBillboard a:focus, .TopicBillboard a:hover {
            color: #12b5f7;
        }

.TopicBillboard-wrap {
    position: relative;
}

@media screen and (min-width:43.75em) {
    .TopicBillboard-wrap {
        display: block;
        position: relative;
        max-height: 600px;
    }

        .TopicBillboard-wrap:before {
            content: "\0020";
            display: block;
        }

        .TopicBillboard-wrap:before {
            padding-top: 56.25%;
        }
}

.TopicBillboard-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

@media screen and (max-width:43.6875em) {
    .TopicBillboard-image {
        display: block;
        position: relative;
    }

        .TopicBillboard-image:before {
            content: "\0020";
            display: block;
        }

        .TopicBillboard-image:before {
            padding-top: 56.25%;
        }
}

@media screen and (min-width:43.75em) {
    .TopicBillboard-image {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

@media screen and (min-width:43.75em) {
    .TopicBillboard-content {
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        max-width: 550px;
        width: 75%;
    }
}

@media screen and (min-width:67.5em) {
    .TopicBillboard-content {
        bottom: 95px;
    }
}

.TopicBillboard .TitleBlock {
    background-color: #3b3b3b;
    padding: 25px 15px;
    z-index: 1;
}

@media screen and (min-width:43.75em) {
    .TopicBillboard .TitleBlock {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: rgba(51,51,51,.8);
        padding: 25px;
    }

    .no-csstransforms .TopicBillboard .TitleBlock {
        top: auto;
        position: static;
    }
}

@media screen and (min-width:55em) {
    .TopicBillboard .TitleBlock {
        padding: 30px;
    }
}

.TopicBillboard .TitleBlock-label {
    color: #fff;
}

.TopicBillboard .TitleBlock-title {
    color: #fff;
    font-size: 1.764706em;
}

@media screen and (min-width:43.75em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 247.058824%;
    }
}

@media screen and (min-width:47.6875em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 264.705882%;
    }
}

@media screen and (min-width:51.625em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 282.352941%;
    }
}

@media screen and (min-width:55.5625em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 300%;
    }
}

@media screen and (min-width:59.5em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 317.647059%;
    }
}

@media screen and (min-width:63.4375em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 335.294118%;
    }
}

@media screen and (min-width:67.375em) {
    .TopicBillboard .TitleBlock-title {
        font-size: 352.941176%;
    }
}

.TopicBillboard .TitleBlock-intro {
    font-size: .941176em;
    margin-bottom: 10px;
}

@media screen and (min-width:20em) {
    .TopicBillboard .TitleBlock-intro {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:25em) {
    .TopicBillboard .TitleBlock-intro {
        font-size: 100%;
    }
}

@media screen and (min-width:30em) {
    .TopicBillboard .TitleBlock-intro {
        font-size: 105.882353%;
    }
}

.TopicBillboard-secondary {
    background-color: #5c5c5c;
    padding: 25px 15px;
}

@media screen and (min-width:67.5em) {
    .TopicBillboard-secondary {
        background-color: rgba(51,51,51,.8);
        bottom: 0;
        display: table;
        height: 95px;
        left: 0;
        padding: 20px 40px;
        position: absolute;
        width: 100%;
    }
}

.TopicBillboard-quote {
    margin-bottom: 10px;
}

@media screen and (min-width:67.5em) {
    .TopicBillboard-quote {
        display: table-cell;
        padding-right: 40px;
        vertical-align: middle;
        width: 65%;
    }
}

.TopicBillboard-quote-text {
    font-size: .941176em;
    margin-bottom: 5px;
}

    .TopicBillboard-quote-text:before {
        content: '\201C';
    }

    .TopicBillboard-quote-text:after {
        content: '\201D';
    }

.TopicBillboard-quote-cite {
    font-size: .764706em;
}

    .TopicBillboard-quote-cite:before {
        content: '\2013\0020';
    }

.TopicBillboard-cta {
    font-size: .941176em;
    font-weight: 700;
}

    .TopicBillboard-cta svg {
        margin-left: 5px;
        vertical-align: middle;
    }

@media screen and (min-width:67.5em) {
    .TopicBillboard-cta {
        display: table-cell;
        text-align: right;
        vertical-align: middle;
        width: 35%;
    }
}

.TopicList {
    text-align: center;
}

@media screen and (min-width:30em) {
    .TopicList {
        text-align: left;
    }
}

.TopicList-item {
    margin-top: 40px;
}

    .TopicList-item:first-child {
        margin-top: 0;
    }

@media screen and (min-width:30em) {
    .TopicList-item {
        margin-top: 60px;
    }
}

.TopicList-title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.294118em;
    line-height: 1.3;
    margin-bottom: 15px;
}

.fonts-loaded .TopicList-title {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

@media screen and (min-width:20em) {
    .TopicList-title {
        font-size: 129.411765%;
    }
}

@media screen and (min-width:25em) {
    .TopicList-title {
        font-size: 135.294118%;
    }
}

@media screen and (min-width:30em) {
    .TopicList-title {
        font-size: 141.176471%;
    }
}

@media screen and (min-width:35em) {
    .TopicList-title {
        font-size: 147.058824%;
    }
}

@media screen and (min-width:40em) {
    .TopicList-title {
        font-size: 152.941176%;
    }
}

.TopicList-title a, .TopicList-title a:link {
    color: #454545;
}

    .TopicList-title a:visited {
        color: #454545;
    }

    .TopicList-title a:active, .TopicList-title a:focus, .TopicList-title a:hover {
        color: #12b5f7;
    }

@media screen and (min-width:30em) {
    .TopicList-title {
        float: left;
    }
}

.TopicList-media {
    margin: 0 auto 20px;
    width: 130px;
}

@media screen and (min-width:30em) {
    .TopicList-media {
        float: right;
        margin-left: 7%;
    }
}

@media screen and (min-width:34.375em) {
    .TopicList-media {
        width: 160px;
    }
}

@media screen and (min-width:48em) {
    .TopicList-media {
        width: 200px;
    }
}

@media screen and (min-width:60em) {
    .TopicList-media {
        width: 234px;
    }
}

.TopicList-media img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    width: 100;
}

.aos .TopicList-media img {
    -webkit-border-radius: 0;
    border-radius: 0;
}

@media screen and (min-width:46.25em) {
    .TopicList-content {
        clear: left;
        overflow: hidden;
    }
}

.TopicList-description {
    clear: left;
    font-size: .882353em;
    margin-bottom: 20px;
    overflow: hidden;
}

@media screen and (min-width:20em) {
    .TopicList-description {
        font-size: 88.235294%;
    }
}

@media screen and (min-width:24em) {
    .TopicList-description {
        font-size: 94.117647%;
    }
}

@media screen and (min-width:28em) {
    .TopicList-description {
        font-size: 100%;
    }
}

@media screen and (min-width:32em) {
    .TopicList-description {
        font-size: 105.882353%;
    }
}

@media screen and (min-width:36em) {
    .TopicList-description {
        font-size: 111.764706%;
    }
}

@media screen and (min-width:40em) {
    .TopicList-description {
        font-size: 117.647059%;
    }
}

.TopicList-featContent {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    clear: both;
    padding-bottom: 15px;
    padding-top: 20px;
    text-align: left;
}

    .TopicList-featContent:after, .TopicList-featContent:before {
        content: " ";
        display: table;
    }

    .TopicList-featContent:after {
        clear: both;
    }

@media screen and (min-width:46.25em) {
    .TopicList-featContent {
        clear: none;
    }
}

.TopicList-featContent-item {
    padding-left: 1.352941rem;
    position: relative;
    font-size: .882353em;
    font-weight: 600;
    margin-bottom: 10px;
}

@media print {
    .TopicList-featContent-item {
        list-style: disc;
        padding-left: 0;
    }
}

.TopicList-featContent-item:before {
    background-color: #ccc;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '\0020';
    height: 6px;
    left: 0;
    position: absolute;
    top: .5em;
    width: 6px;
}

@media print {
    .TopicList-featContent-item:before {
        display: none;
    }
}

@media screen and (min-width:30em) {
    .TopicList-featContent-item {
        float: left;
        font-size: .941176em;
        padding-right: 20px;
        width: 50%;
    }

        .TopicList-featContent-item:nth-child(odd) {
            clear: left;
        }

        .TopicList-featContent-item:nth-child(even) {
            clear: right;
            float: right;
            margin-left: -50%;
        }
}

.TopicList-cta {
    font-size: .882353em;
    font-weight: 600;
    margin-top: 20px;
    text-align: left;
}

@media screen and (min-width:25em) {
    .TopicList-cta {
        font-size: .941176em;
    }
}

.TopicList-cta svg {
    fill: currentColor;
    margin-left: 6px;
    vertical-align: middle;
}

.TopicSubnav {
    text-align: center;
}

.TopicSubnav-toggle {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    background-image: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    text-shadow: none;
    text-transform: none;
    font-weight: 700;
    background-color: #a2b01f;
    display: block;
    font-size: .941176em;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

    .TopicSubnav-toggle:active, .TopicSubnav-toggle:focus, .TopicSubnav-toggle:hover {
        background-color: transparent;
    }

.fonts-loaded .TopicSubnav-toggle {
    font-family: "Avenir Next W01","Helvetica Neue",HelveticaNeue,Arial,Helvetica,Roboto,sans-serif;
}

.TopicSubnav-toggle.js-active, .TopicSubnav-toggle:active, .TopicSubnav-toggle:focus, .TopicSubnav-toggle:hover {
    background-color: #8e9a1b;
}

.TopicSubnav-toggle-text {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M16%2011q0%200.4-0.3%200.7l-7%207q-0.3%200.3-0.7%200.3t-0.7-0.3l-7-7q-0.3-0.3-0.3-0.7t0.3-0.7%200.7-0.3h14q0.4%200%200.7%200.3t0.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: right center;
    -webkit-background-size: 10px auto;
    background-size: 10px auto;
    background-repeat: no-repeat;
    padding-right: 22px;
    position: relative;
}

.no-inlinesvg .TopicSubnav-toggle-text:after {
    background-image: url(../images/icons/icon-sprite.png);
    background-position: -402px -28px;
    width: 10px;
    height: 18px;
    content: '\0020';
    position: absolute;
    right: 0;
    top: 0;
}

.TopicSubnav-toggle.js-active .TopicSubnav-toggle-text {
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23fff%22%3E%3Cpath%20d=%22M16%2019q0%200.4-0.3%200.7t-0.7%200.3h-14q-0.4%200-0.7-0.3t-0.3-0.7%200.3-0.7l7-7q0.3-0.3%200.7-0.3t0.7%200.3l7%207q0.3%200.3%200.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
}

.TopicSubnav-content {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #a2b01f;
    position: absolute;
    width: 100%;
    z-index: 200;
}

.TopicSubnav-item:first-child a {
    border-top: none;
}

.TopicSubnav-link {
    border-top: 1px solid rgba(255,255,255,.4);
    display: block;
    font-size: 1.058824em;
    font-weight: 600;
    padding: 12px;
}

    .TopicSubnav-link, .TopicSubnav-link:active, .TopicSubnav-link:focus, .TopicSubnav-link:hover, .TopicSubnav-link:link, .TopicSubnav-link:visited {
        color: #fff;
    }

        .TopicSubnav-link:active, .TopicSubnav-link:focus, .TopicSubnav-link:hover {
            background-color: #8e9a1b;
        }

.Video {
    background: #000;
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
}

    .Video:before {
        content: '';
        display: block;
        padding-top: 56.25%;
    }

.Video-link {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
}

.no-csstransforms .Video-link {
    top: auto;
    position: static;
}

.Video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.chosen-select {
    color: #454545;
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2016%2028%22%20width=%2216%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%2308a7e7%22%3E%3Cpath%20d=%22M16%2011q0%200.4-0.3%200.7l-7%207q-0.3%200.3-0.7%200.3t-0.7-0.3l-7-7q-0.3-0.3-0.3-0.7t0.3-0.7%200.7-0.3h14q0.4%200%200.7%200.3t0.3%200.7z%22%2F%3E%3C%2Fsvg%3E);
    background-position: 95% center;
    -webkit-background-size: 12px auto;
    background-size: 12px auto;
    background-repeat: no-repeat;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
    display: block;
    width: 100%;
}

@media screen and (min-width:40em) {
    .chosen-select {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.js .chosen-select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.chosen-container {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.chosen-container-active .chosen-single.chosen-single {
    color: #08a7e7;
    outline: 1px solid #08a7e7;
}

.chosen-container .chosen-drop {
    background-color: #fff;
    -webkit-box-shadow: 0 6px 18px -5px rgba(0,0,0,.4);
    box-shadow: 0 6px 18px -5px rgba(0,0,0,.4);
    left: -9999px;
    position: absolute;
    top: 100%;
    z-index: 200;
}

.chosen-container.chosen-with-drop .chosen-drop {
    left: -1px;
    right: -1px;
}

.chosen-container .chosen-single .group-name, .chosen-container .search-choice .group-name {
    color: #999;
    font-weight: 400;
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

    .chosen-container .chosen-single .group-name:after, .chosen-container .search-choice .group-name:after {
        content: ":";
        padding-left: 2px;
        vertical-align: top;
    }

.chosen-container-single .chosen-single {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    cursor: pointer;
    display: block;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

    .chosen-container-single .chosen-single, .chosen-container-single .chosen-single:link {
        color: #08a7e7;
    }

        .chosen-container-single .chosen-single:visited {
            color: #08a7e7;
        }

        .chosen-container-single .chosen-single:active, .chosen-container-single .chosen-single:focus, .chosen-container-single .chosen-single:hover {
            color: #078ac0;
        }

        .chosen-container-single .chosen-single span {
            display: block;
            margin-right: 26px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

.chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px;
}

.chosen-container-single .chosen-single abbr {
    display: block;
    font-size: 1px;
    height: 12px;
    position: absolute;
    right: 26px;
    top: 6px;
    width: 12px;
}

    .chosen-container-single .chosen-single abbr:hover {
        background-position: -42px -10px;
    }

.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
}

.chosen-container-single .chosen-single div {
    display: none;
}

.chosen-container-single .chosen-search {
    padding: 10px 10px 5px;
    position: relative;
    white-space: nowrap;
    z-index: 1;
}

    .chosen-container-single .chosen-search input[type=text] {
        background-image: url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20baseProfile=%22full%22%20xmlns=%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox=%220%200%2026%2028%22%20width=%2226%22%20height=%2228%22%20preserveAspectRatio=%22xMidYMid%20meet%22%20fill=%22%23ccc%22%3E%3Cpath%20d=%22M18%2013q0-2.9-2.06-4.95T11%206%206.05%208.06%204%2013t2.05%204.95T11%2020t4.95-2.06T18%2013zm8%2013q0%20.8-.6%201.4T24%2028q-.84%200-1.4-.6l-5.37-5.34Q14.43%2024%2011%2024q-2.23%200-4.27-.87T3.2%2020.8.88%2017.26%200%2013t.87-4.27T3.2%205.2t3.53-2.33T11%202t4.27.87T18.8%205.2t2.33%203.53T22%2013q0%203.44-1.94%206.23l5.36%205.36q.58.57.58%201.4z%22%2F%3E%3C%2Fsvg%3E);
        background-position: 96% 50%;
        background-position: right 10px 50%;
        background-repeat: no-repeat;
        -webkit-background-size: 18px auto;
        background-size: 18px auto;
        border: 1px solid #ccc;
        outline: 0;
        padding: 8px 24px 8px 10px;
        width: 100%;
    }

.no-svg .chosen-container-single .chosen-search input[type=text] {
    background-image: url(../images/icons/png/search.png);
}

.chosen-container-single .chosen-drop {
    border: 1px solid #ccc;
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    left: -9999px;
    position: absolute;
}

.chosen-container .chosen-results {
    height: 70vh;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

    .chosen-container .chosen-results li {
        display: none;
        list-style: none;
        margin: 0;
        padding: 5px 10px;
        word-wrap: break-word;
        -webkit-touch-callout: none;
    }

        .chosen-container .chosen-results li.active-result {
            display: list-item;
            cursor: pointer;
        }

        .chosen-container .chosen-results li.disabled-result {
            display: list-item;
            color: #ccc;
            cursor: default;
        }

        .chosen-container .chosen-results li.highlighted {
            background-color: #08a7e7;
            color: #fff;
        }

        .chosen-container .chosen-results li.no-results {
            color: #777;
            display: list-item;
            background-color: #f4f4f4;
        }

        .chosen-container .chosen-results li.group-result {
            display: list-item;
            font-weight: 700;
            cursor: default;
        }

        .chosen-container .chosen-results li.group-option {
            padding-left: 15px;
        }

        .chosen-container .chosen-results li em {
            font-style: normal;
            text-decoration: underline;
        }

.chosen-container-multi {
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(1%,#eee),color-stop(15%,#fff));
    background-image: -webkit-linear-gradient(top,#eee 1%,#fff 15%);
    background-image: linear-gradient(to bottom,#eee 1%,#fff 15%);
    background-color: #fff;
    border: 1px solid #aaa;
    cursor: text;
    height: 1%;
    height: auto !important;
    margin: 0;
    overflow: hidden;
    padding: 0 5px;
    position: relative;
    width: 100%;
}

    .chosen-container-multi .chosen-choices li {
        float: left;
        list-style: none;
    }

        .chosen-container-multi .chosen-choices li.search-field {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

            .chosen-container-multi .chosen-choices li.search-field input[type=text] {
                background-color: transparent !important;
                -webkit-border-radius: 0;
                border-radius: 0;
                border: 0 !important;
                -webkit-box-shadow: none;
                box-shadow: none;
                color: #999;
                font-family: sans-serif;
                font-size: 100%;
                height: 25px;
                line-height: normal;
                margin: 1px 0;
                outline: 0;
                padding: 0;
            }

        .chosen-container-multi .chosen-choices li.search-choice {
            background-image: -webkit-gradient(linear,left top,left bottom,color-stop(20%,#f4f4f4),color-stop(50%,#f0f0f0),color-stop(52%,#e8e8e8),to(#eee));
            background-image: -webkit-linear-gradient(top,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);
            background-image: linear-gradient(to bottom,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            background-color: #eee;
            background-repeat: repeat-x;
            -webkit-background-size: 100% 19px;
            background-size: 100% 19px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            border: 1px solid #aaa;
            -webkit-box-shadow: 0 0 2px #fff inset,0 1px 0 rgba(0,0,0,.05);
            box-shadow: 0 0 2px #fff inset,0 1px 0 rgba(0,0,0,.05);
            color: #333;
            cursor: default;
            line-height: 13px;
            margin: 3px 5px 3px 0;
            max-width: 100%;
            padding: 3px 20px 3px 5px;
            position: relative;
        }

            .chosen-container-multi .chosen-choices li.search-choice span {
                word-wrap: break-word;
            }

            .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
                display: block;
                font-size: 1px;
                height: 12px;
                position: absolute;
                right: 3px;
                top: 4px;
                width: 12px;
            }

                .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
                    background-position: -42px -10px;
                }

        .chosen-container-multi .chosen-choices li.search-choice-disabled {
            background-image: -webkit-gradient(linear,left bottom,left top,color-stop(20%,#f4f4f4),color-stop(50%,#f0f0f0),color-stop(52%,#e8e8e8),to(#eee));
            background-image: -webkit-linear-gradient(bottom,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);
            background-image: linear-gradient(to top,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);
            background-color: #e4e4e4;
            border: 1px solid #ccc;
            color: #666;
            padding-right: 5px;
        }

        .chosen-container-multi .chosen-choices li.search-choice-focus {
            background-color: #d4d4d4;
        }

            .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
                background-position: -42px -10px;
            }

    .chosen-container-multi .chosen-results {
        margin: 0;
        padding: 0;
    }

    .chosen-container-multi .chosen-drop .result-selected {
        color: #ccc;
        cursor: default;
        display: list-item;
    }

.chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: none;
    background-color: transparent;
}

    .chosen-container-active.chosen-with-drop .chosen-single div b {
        background-position: -18px 2px;
    }

.chosen-container-active .chosen-choices {
    border: 1px solid #5897fb;
}

    .chosen-container-active .chosen-choices li.search-field input[type=text] {
        color: #222 !important;
    }

.chosen-disabled {
    cursor: default;
    opacity: .5 !important;
}

    .chosen-disabled .chosen-single {
        cursor: default;
    }

    .chosen-disabled .chosen-choices .search-choice .search-choice-close {
        cursor: default;
    }

@media print {
    * {
        background: 0 0 !important;
        color: #000 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        -webkit-filter: none !important;
        filter: none !important;
        -ms-filter: none !important;
    }

    blockquote, img, pre, tr {
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    img {
        max-width: 100% !important;
    }

    h2, h3, p {
        orphans: 2;
        widows: 2;
    }

    h2, h3 {
        page-break-after: avoid;
    }

    @page {
        margin: .5in;
    }
}
