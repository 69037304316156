﻿
/*
 *  Styles overriding bootstrap's _type.scss
*/


// Body text
// -------------------------

p {
  margin: 0 0 0 0;
}