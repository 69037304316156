﻿.tooltip-disclaimer {
    color: grey;
    margin-top: 15px;
    font-style: italic;
}

a[data-toggle="popover"] {
    color: #696969;
}
a[data-toggle="popover"]:hover {
    color: #000;
}