
/**
 * Utilities
 */

@import "_mixins";


/**
 * BOOTSTRAP
 */

// customized bootstrap variables
@import "bootstrap/custom-variables";
// full bootstrap library
//@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
// customized bootstrap master file (to exclude unneeded components)
@import "bootstrap/bootstrap";
// Bootstrap customizations
@import "bootstrap/type";





/**
 * cff.org Styles
 *
 * _main.scss and _cff.scss are the same as their .css counterparts
 * the names were just changed so they could be imported into this file
 * in order to limit server calls and guarantee placement
 */

@import "main";
@import "cff";
@import "mega-menu/style";


// wrap overrides in app id to increase specificity
// because we don't want to rely on load order
#ccd-app {

    // Datatables    
    @import "datatables/pagination";


    // Custom care center data app styles
    @import "cardbox";
    @import "tooltip";
    @import "sharelinks";
    @import "breadcrumbs";
    @import "print";
    //@import "ChapterList";

}