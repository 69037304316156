body {
    margin: 0;
}

.header {
    position: relative;
    font-family: Arial, sans-serif;
    padding-bottom: 16px;
}

.container, .primaryNav__container .subMenu__inner {
    max-width: 1300px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;
    box-sizing: border-box;
}

.primaryNav__container {
    color: #000;
    font-family: "Open Sans", sans-serif;
}

@media screen and (max-width: 60em) {
    .primaryNav__container > ul > li:last-of-type {
        border-bottom: 1px solid #ccc;
        padding: 12px 0 12px 0;
    }
}

.primaryNav__container .primaryNav__list {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__list {
        display: block;
    }
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__item {
        display: block;
        text-align: left;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .primaryNav__container .primaryNav__item {
        font-size: 14px;
        line-height: 17px;
    }
}

@media screen and (max-width: 1000px) {
    .primaryNav__container .primaryNav__item {
        font-size: 13px;
        line-height: 16px;
    }
}

@media screen and (max-width: 910px) {
    .primaryNav__container .primaryNav__item {
        width: 33%;
        display: inline-block;
        text-align: center;
        padding-right: 2%;
    }
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__item {
        display: block;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 0 0 0;
        border-top: 1px solid #ccc;
        text-align: left !important;
    }
}

.primaryNav__container .primaryNav__item.subMenu__active .subMenu__container {
    display: block;
}

.primaryNav__container .primaryNav__item.subMenu__active .primaryNav__item-link {
    color: #ebbe2d;
    text-decoration: underline;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__item.subMenu__active .primaryNav__item-link {
        text-decoration: none;
        border-bottom: 1px solid #ebbe2d;
        padding-bottom: 5px;
        margin-bottom: 8px;
    }

    .primaryNav__container .primaryNav__item.subMenu__active .primaryNav__item-link .fa-angle-down {
        display: none;
    }

    .primaryNav__container .primaryNav__item.subMenu__active .primaryNav__item-link .fa-angle-up {
        display: inline;
    }
}

.primaryNav__container .primaryNav__item-link {
    color: #026795;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__item-link {
        display: block;
    }
}

.primaryNav__container .primaryNav__item-link:hover {
    color: #ebbe2d;
    text-decoration: underline;
}

.primaryNav__container .primaryNav__item-link .fa-angle-up,
.primaryNav__container .primaryNav__item-link .fa-angle-down {
    float: right;
    padding-right: 2px;
    font-size: 25px;
    display: none;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__item-link .fa-angle-down {
        display: inline;
    }
}

.primaryNav__container .subMenu__container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    text-align: left;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__container {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        background-color: transparent;
    }
}

.primaryNav__container .subMenu__container.sectionAligned--center {
    text-align: left;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__container.sectionAligned--center {
        text-align: left;
    }
}

.primaryNav__container .subMenu__container.sectionAligned--left {
    text-align: left;
}

.primaryNav__container .subMenu__container.sectionAligned--left .subMenu__image {
    margin-left: 0;
}

.primaryNav__container .subMenu__inner {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__inner {
        padding: 0 0 0 10px;
    }
}

.primaryNav__container .subMenu__close {
    color: #848484;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
}

.primaryNav__container .subMenu__close:hover {
    color: #ebbe2d;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__close {
        display: none;
    }
}

.primaryNav__container .primaryNav__submenu-title {
    text-transform: uppercase;
    color: #00a1de;
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 20px;
    font-weight: 600;
    text-decoration: none;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__submenu-title {
        margin-bottom: 0;
    }
}

.primaryNav__container .primaryNav__submenu-title a {
    color: #00a1de;
    text-decoration: none;
}

.primaryNav__container .primaryNav__submenu-title--secondary {
    color: #00a1de;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 20px;
    font-weight: 700;
    text-decoration: none;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__submenu-title--secondary {
        margin-bottom: 0;
    }
}

.primaryNav__container .primaryNav__submenu-title--secondary a {
    color: #00a1de;
    text-decoration: none;
}

.primaryNav__container .primaryNav__submenu-title--alternate {
    color: #026795;
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 20px;
    font-weight: 700;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__submenu-title--alternate {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 18px;
    }
}

.primaryNav__container .primaryNav__submenu-description {
    color: #848484;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 20px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .primaryNav__submenu-description {
        display: none;
    }
}

.primaryNav__container .subMenu__image {
    display: block;
    width: 100%;
    max-width: 139px;
    height: auto;
    margin: 0 auto 20px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__image {
        display: none;
    }
}

.primaryNav__container .subMenu__image + .primaryNav__submenu-title,
.primaryNav__container .subMenu__image + .primaryNav__submenu-title--secondary {
    text-align: center;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__image + .primaryNav__submenu-title,
    .primaryNav__container .subMenu__image + .primaryNav__submenu-title--secondary {
        text-align: left;
    }
}

.primaryNav__container .subMenu__column-container {
    display: flex;
    margin: 0 -20px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__column-container {
        display: block;
        margin: 0;
    }
}

.primaryNav__container .subMenu__column-container.subMenu__column-alternate .subMenu__column:first-child {
    padding-right: 60px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__column-container.subMenu__column-alternate .subMenu__column:first-child {
        padding-right: 0;
    }
}

.primaryNav__container .subMenu__column-container.subMenu__column-alternate .subMenu__column:last-child {
    padding-left: 60px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__column-container.subMenu__column-alternate .subMenu__column:last-child {
        padding-left: 0;
    }
}

.primaryNav__container .subMenu__column {
    padding: 10px 20px;
    flex-basis: 100%;
    border-left: 1px solid #eaeae9;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__column {
        border-left: 0;
        padding: 0;
        margin-bottom: 10px;
    }
}

.primaryNav__container .subMenu__column:first-child {
    border-left: 0;
}

.primaryNav__container .subMenu__alternate-container {
    display: table;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__alternate-container {
        margin-bottom: 10px;
    }
}

.primaryNav__container .subMenu__alternate-imageContainer,
.primaryNav__container .subMenu__alternate-contentContainer {
    display: table-cell;
    vertical-align: middle;
}

.primaryNav__container .subMenu__alternate-imageContainer {
    width: 139px;
    padding-right: 20px;
}

@media (max-width: 900px) {
    .primaryNav__container .subMenu__alternate-imageContainer {
        width: 100px;
    }
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__alternate-imageContainer {
        display: none;
    }
}

.primaryNav__container .alternateMenu__outer {
    font-size: 0;
}

.primaryNav__container .alternateMenu__outer.sectionAligned--center {
    text-align: left;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .alternateMenu__outer.sectionAligned--center {
        text-align: left;
    }
}

.primaryNav__container .alternateMenu__outer.sectionAligned--left {
    text-align: left;
}

.primaryNav__container .alternateMenu__inner {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-right: 10px;
    box-sizing: border-box;
}

@media (max-width: 900px) {
    .primaryNav__container .alternateMenu__inner {
        display: block;
        width: auto;
        padding-right: 0;
        margin-bottom: 5px;
        padding-left: 10px;
    }
}

.primaryNav__container .alternateMenu__hasLInks .alternateMenu__open-link {
    position: relative;
    padding-right: 10px;
}

.primaryNav__container .alternateMenu__hasLInks .alternateMenu__open-link:after {
    content: '+';
    position: absolute;
    right: -7px;
    cursor: pointer;
}

.primaryNav__container .alternateMenu__hasLInks .alternateMenu__container {
    display: none;
}

.primaryNav__container .alternateMenu__hasLInks.alternateMenu__active .alternateMenu__open-link:after {
    right: -11px;
    content: "\2014";
    top: -3px;
}

.primaryNav__container .alternateMenu__hasLInks.alternateMenu__active .alternateMenu__container {
    display: block;
}

.primaryNav__container .alternateMenu__open-link {
    text-decoration: none;
}

.primaryNav__container .alternateMenu__link {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    color: #08a7e7;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
}

@media screen and (max-width: 60em) {
    .primaryNav__container .alternateMenu__link {
        padding-left: 10px;
    }
}

.primaryNav__container .alternateMenu__link:hover {
    color: #ebbe2d;
}

.alternateMenu__hasLInks .alternateMenu__container a {
    color: #4c4c4c;
}

.Header-navAction {
    background-color: #00a1de;
}

.donateNav {
    padding: 23px 0 21px 0;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #00a1de;
}

@media screen and (max-width: 62em) {
    .donateNav {
        padding: 15px 0 17px 0;
    }
}

@media screen and (max-width: 22.25em) {
    .donateNav {
        padding: 29px 0 29px 0;
    }
}

.donateNav .donateNav__donateHeart {
    width: 29px;
    display: block;
    margin: 0 auto 15px auto;
}

@media screen and (max-width: 62em) {
    .donateNav .donateNav__donateHeart {
        display: inline-block;
        vertical-align: top;
        margin: 0 6px 0px 0;
    }
}

.donateNav a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.donateNav a:hover {
    color: #fff;
}
.getInvolvedNav {
    padding: 23px 0 21px 0;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #026795;
}


@media screen and (max-width: 945px) {
    .getInvolvedNav {
        padding: 15px 0 5px 0;
    }
}

@media screen and (max-width: 62em) {
    .getInvolvedNav {
        padding: 15px 0 17px 0;
    }
}

.getInvolvedNav .getInvolvedNav__getInvolvedIcon {
    width: 29px;
    display: block;
    margin: 0 auto 15px auto;
}

@media screen and (max-width: 62em) {
    .getInvolvedNav .getInvolvedNav__getInvolvedIcon {
        display: inline-block;
        vertical-align: top;
        margin: 0 6px 0px 0;
    }
}

.getInvolvedNav a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.getInvolvedNav a:hover {
    color: #fff;
}


@media screen and (min-width: 66.75em) {
    .Header-nav .Nav-primary-wrap {
        bottom: 0;
    }
}
.Header-navAction-getinvolved {
    left: 0;
    position: absolute;
    top: 100%;
    width: 50%
}

.Header-navAction-donate {
    left: 50%;
    position: absolute;
    top: 100%;
    width: 50%
}
.Header-navAction {
    padding-bottom: 16px;
}

.ActionNav-wrap {
    position: relative;
}

nav > .primaryNav__list li:first-of-type {
    text-align: left;
}

nav > .primaryNav__list li:last-of-type {
    text-align: right;
}

@media screen and (min-width: 48em) {
    .Header-navAction {
        width: 13% !important;
    }
}

@media screen and (max-width: 910px) {
    .Header-nav .Nav-primary-wrap {
        bottom: -10px;
    }

    .primaryNav__container .subMenu__container {
        margin-top: -10px;
    }
}

@media screen and (max-width: 60em) {
    .primaryNav__container .subMenu__container {
        margin-top: 0;
    }
}

.Nav-toggle[aria-expanded=true] .fa-times {
    display: inline;
}

.Nav-toggle[aria-expanded=true] .fa-bars {
    display: none;
}

.Nav-toggle[aria-expanded=false] .fa-times {
    display: none;
}

.Nav-toggle[aria-expanded=false] .fa-bars {
    display: inline;
}

.alternateMenu__hasLInks .alternateMenu__link {
    margin-left: 10px;
    text-transform: none;
}

.alternateMenu__hasLInks .primaryNav__submenu-title {
    margin-bottom: 5px;
}

.alternateMenu__hasLInks {
    margin-bottom: 10px;
}

.primaryNav__container .subMenu__image {
    border-radius: 100%;
}

/*# sourceMappingURL=style.css.map */
