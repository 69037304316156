﻿.open-facet {
    font-size: 0.823529em;
}

@media (max-width: 767px) {
    .u-wrapper.u-mt9 > table,
    #q3-div-q > table,
    .u-wrapper.u-mt9 > table > tbody,
    #q3-div-q > table > tbody,
    .u-wrapper.u-mt9 > table > tbody > tr,
    #q3-div-q > table > tbody > tr,
    .u-wrapper.u-mt9 > table > tbody > tr > td,
    #q3-div-q > table > tbody > tr > td {
        display: block;
        width: 100%;
    }
}
/*******************************************/
/*  screening tool styles  */
/*******************************************/

.u-wrapper.u-mt9 > h1 {
    color: #006694;
    font-family: "Avenir Next W01","Helvetica Neue","HelveticaNeue",Arial,Helvetica,"Roboto",sans-serif;
    font-size: 1.64706em;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 15px;
}


#q1-div td, #q2-div td, #q3-div td {
    padding: 0 .25em;
}

#q1-div input, #q2-div input, #q3-div input {
    margin: 0 0 0 1.25em;
}

#status h3 {
    float: left;
    padding: 0 0 0.5em 0.75em;
}



.Breadcrumbs {
    display:block !important;
    /*margin-top: 10px !important;*/
}

/*.Breadcrumbs-list {
    margin-top: 10px !important;
}*/

.callout-text {

    margin:8px;
    color:white;

}

.ButtonGroup--divider2 {  margin-top: 12px; padding-top: 30px; }


.backgroundtop { background-position:top}
.backgroundbottom { background-position:bottom}
.backgroundright 

{ background-position:right}
.backgroundleft { background-position:left}


.img-left { float:left}
.img-right { float:right}



.filters-reset-fix {
    font-size: 14px !important;
    font-family: "Open Sans", "Helvetica Neue", "HelveticaNeue", Arial, Helvetica, "Roboto", sans-serif;
    font-weight: 600;
    
    text-decoration:underline !important;
    color: #08a7e7;
}

.ContentList-footer
{
    padding-top: 30px !important;
}


/*.ContentList-media a {
margin-top: 30px !important;
margin-bottom: 30px !important;
}*/
.GetInTouch input[type="submit"] {
    background-color: #04405b;
    color: #fff;
    text-shadow: 1px 1px 1px #021e2a;
}
/*.ContentList-content {
padding-top: 30px !important;
}*/

.TopicList-featContent2 {
    clear: both;
    padding-top: 10px;
    text-align: left;

}

.topic-link-margin {
    clear: both;
    margin-bottom:50px;

}

.sponsor-wrap {
  width: 24%;
  height: 100px;
  float: left;
  margin-right: 1%;
  margin-bottom: 10px;
  border: 1px solid #000;
  text-align: center; 
}

.sponsor-wrap2 {
  width: 49%;
  height: 100px;
  float: left;
  margin-right: 1%;
  margin-bottom: 10px;
  border: 1px solid #000;
  text-align: center; 
}

.sponsor-wrap img {
    vertical-align: middle;
    max-width:90%;
    max-height: 100px;
}

.sponsor-wrap2 img {
    vertical-align: middle;
    max-width:90%;
    max-height: 100px;
}


.supporter-section {
    clear:both;
    padding-top: 10px;

}

.supporter-section div {
    margin-top: 0px;
}

.sponsor-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.Nav-promo-media img {
        max-width: 104px;
      }
	  
	 /* .InfoBar {
    padding: 15px 0;
}
.InfoBar {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: 0.941176em;
    line-height: 1.25;
    margin-bottom: 30px;
}
.ContentList-featured, .ContentList-featured ~ .ContentList-label {
    display: inline-block;
    
}*/

            



